import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLoader, fetchAddressDetails, setOrderItems } from '../../../store/action/Auth';
import { Formik } from "formik";
import { orderPageEnum } from '../models/globalUtilities';
import * as Yup from "yup";
import ProgressBar from './ProgressBar';
import { UpdatePageId } from './utils';

const schema = Yup.object().shape({
    NoOfItems: Yup.number().required("No of items are required."),
  });

class OrderItem extends Component {

    constructor(props){
        super(props);
    }

    async componentDidMount() {
        this.props.setLoader(true);
        await this.props.fetchAddressDetails();
        this.props.setLoader(false);
    }

    render(){
        return(
            <> 
            <Formik
            initialValues={{
              NoOfItems: this.props.viewModel == null ? 1 : this.props.viewModel.OrderItem.NoOfItems,
              specialInstruction: this.props.viewModel == null ? null : this.props.viewModel.OrderItem.specialInstruction,
            }}
            validationSchema={schema}
            // tell the formik to validate onBlur
            validateOnBlur
            onSubmit={values => {
              console.log("I'm here inside post request");
              let viewModel = {
                OrderItem: {
                  NoOfItems: values.NoOfItems,
                  specialInstruction: values.specialInstruction,
                },
              };
              this.props.setOrderItems(viewModel);
              UpdatePageId(orderPageEnum.Items, this.props.isLogin);
            }}
          >
            {props => {
              const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;
              return (
                <>
                  <div className='row heading_bar'>
                    <div className='wrapper'>
                      <h2>Enter your items</h2>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='wrapper progress_container'>
                      {/* <OrderProgressBar pageId={1} orderPageEnum={orderPageEnum} /> */}
                      <ProgressBar/>
                    </div>
                  </div>

                  <div className='wrapper item_insertion clearfix'>
                    <form noValidate onSubmit={handleSubmit}>
                      <div className='col-sm-6 paddin_right field'>
                        <label htmlfor='numberofitem' className='show-hide'>
                          No. of Items
                        </label>
                        <input
                          type='number'
                          name='NoOfItems'
                          value={values.NoOfItems}
                          className='form-control'
                          onChange={e => {
                            if (e.currentTarget.value >= 0 && e.currentTarget.value <= 1000) handleChange(e);
                          }}
                          onBlur={handleBlur}
                          noValidate
                          required
                        />
                        {errors.NoOfItems && touched.NoOfItems && <div className='errorMessage'>{errors.NoOfItems}</div>}
                      </div>

                      <div className='col-sm-12 '>
                        <div className='row field'>
                          <label className='control-label show-hide' htmlfor='specialinstruction'>
                            Special Instructions
                          </label>
                          <textarea
                            type='text'
                            name='specialInstruction'
                            value={values.specialInstruction}
                            onChange={e => {
                              if (e.currentTarget.value.length <= 500) handleChange(e);
                            }}
                            onBlur={handleBlur}
                            className='form-control margin-bottom-30'
                            noValidate
                          ></textarea>
                        </div>
                      </div>

                      <div className='col-sm-12'>
                        <button
                          type='submit'
                          className='btn btn-primary pull-right'
                          disabled={values.NoOfItems === 0 || values.NoOfItems === ""}
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              );
            }}
          </Formik>
          </>
        )
    }
}

const mapDispatchToProps = { setLoader, fetchAddressDetails, setOrderItems };
const mapStateToProps = ({ Auth }) => {
    let { pageId, isLogin } = Auth;
    return { pageId, isLogin };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);