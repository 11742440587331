import React, { Component } from "react";
import { OrderProgressBar } from "./OrderProgressBar";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import SignUp from "../Account/SignUp";
import OrderTime from "./OrderTime";
import OrderItem from "./OrderItem";
import OrderDetail from "./OrderDetail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { orderPageEnum, updateState } from "./models/globalUtilities";
import {
  fetchAddressDetails,
  setCompleteAddress,
  setLoader,
  setLoginDetails,
  addCustomerAddress,
  setDefaultAddressId,
  setDefaultAddress,
  fetchOrderScheduleByAddressId,
  setSelectedPickUpSchedule,
  setPath,
  editCustomerAddress,
} from "../../store/action/Auth";
import { connect } from "react-redux";

const schema = Yup.object().shape({
  address1: Yup.string()
    .required("Address is required.")
    .min(3, "Minimum 3 character are required")
    .max(250, "Maximum 250 charactered are allowed"),

  area: Yup.string().required("Area is required."),

  city: Yup.string().required("City is required"),
});

const schema2 = Yup.object().shape({
  address1: Yup.string().required("Address is required."),
});

class OrderAddress extends Component {
  city = [];
  area = [];
  orderPageEnum = orderPageEnum;
  constructor(props) {
    super(props);
    // var today = new Date();
    // var currentDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    this.state = {
      timeSlotType: "Pickup",
      date: new Date().toLocaleString(),
      path: "OrderAddress",
      isModalOpen: false,
      isEditModalOpen: false,
      addressToEdit: null,
      selectedAddress: null,
    };
  }
  async componentDidMount() {
    this.setState({ path: "OrderAddress" });
    await this.props.fetchAddressDetails();
    console.log(this.props.address);
  }
  back = () => {
    //if(user not logged in then sign up otherwise order Item)
    if (this.props.isLogin) {
      this.setState({ path: "Order" });
    } else {
      this.setState({ path: "Order" });
    }
  };

  searchSelectedCity = async Id => {
    for (var i = 0; i < this.props.address?.Cities?.length; i++) {
      if (this.props.address?.Cities[i]?.Id === Id) {
        return this.props.address?.Cities[i]?.Description;
      }
    }
  };

  searchSelectedArea = async Id => {
    for (var i = 0; i < this.props.address?.CityAreas?.length; i++) {
      if (this.props.address?.CityAreas[i]?.Id === Id) {
        return this.props.address?.CityAreas[i]?.Name;
      }
    }
  };

  formatDate(dateToFormat) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateToFormat).toLocaleDateString([], options);
  }

  componentCallback = async (isCallable) => {
    this.props.setLoader(true);

    await this.props.fetchOrderScheduleByAddressId(
      this.state.timeSlotType,
      this.state.date,
      this.props.completeAddress?.AreaId,
      localStorage.getItem("Token")
    );
    this.props.setLoader(false);
    let pickUpSchedule;
    console.log("selected pickup schedule:", this.props.selectedPickUpSchedule);

    pickUpSchedule = {
      PickupSelectedDate: this.props.pickUpSchedule?.WeekSchedules[0]?.Date,
      PickupSelectedTime:
        this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.StartTime +
        " " +
        this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.EndTime,
      PickupSelectedSlotId:
        this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.UniqSlotId,
      PickupDay: this.props.pickUpSchedule?.WeekSchedules[0]?.Day,
      // };
    };
    // }

    console.log("pickup schedule:", pickUpSchedule);
    this.props.setSelectedPickUpSchedule(pickUpSchedule);
  };

  render() {
    console.log("state:", this.state);

    return (
      <>
        {this.state.path == "OrderAddress" ? (
          <>
            <Formik
              initialValues={{
                address1: this.props.completeAddress == null ? "" : this.props.completeAddress?.Id,
                city: "",
                area: "",
                // this.state.customer.
              }}
              validationSchema={this.props.customerDetails?.Addresses?.length > 0 ? schema2 : schema}
              // tell the formik to validate onBlur
              validateOnBlur
              onSubmit={async (values) => {
                console.log("I'm here inside post request ***");
                if (this.props.customerDetails?.Addresses?.length == 0) {
                  let viewModel = {
                    Address: {
                      AddressLine1: values.address1,
                      AddressLine2: "",
                      Altitude: null,
                      Area: await this.searchSelectedArea(parseInt(values.area, 10)),
                      AreaId: values.area,
                      City: "Islamabad",
                      CityId: values.city,
                      CompleteAddress: "",
                      Country: "Canada",
                      CountryId: 1,
                      CreatedBy: null,
                      CreatedById: this.props.customerDetails?.Customer?.Id,
                      EntityId: this.props.customerDetails?.Customer?.Id,
                      EntityTypeId: 10,
                      HasErrors: false,
                      Id: null,
                      InsertDate: new Date(),
                      IsActive: true,
                      IsDefault: false,
                      LastUpdatedBy: null,
                      LastUpdatedById: null,
                      Latitude: "D",
                      Longitude: "D",
                      ModifiedDate: null,
                      ResultMessages: [],
                      SortOrder: 1,
                      State: "Ontario",
                      StateId: 1,
                      ZipCode: "D",
                    },
                    Cities: this.props.address?.Cities,
                    CityAreas: this.props.address?.CityAreas,
                    CreatedBy: null,
                    CreatedById: this.props.customerDetails?.Customer?.Id,
                    HasErrors: false,
                    Id: 0,
                    InsertDate: "2021-08-26T14:15:06.8410833Z",
                    IsActive: true,
                    LastUpdatedBy: null,
                    LastUpdatedById: null,
                    ModifiedDate: null,
                  };

                  viewModel.Address.CompleteAddress =
                    viewModel.Address.AddressLine1 + ", " + viewModel.Address.Area + ", " + viewModel.Address.City;
                  console.log("complete Address : ", viewModel.Address.CompleteAddress);
                  this.props.setLoader(true);
                  await this.props.addCustomerAddress(viewModel, localStorage.getItem("Token"));
                  this.props.setLoader(false);
                  console.log("add address try response: ", this.props.addAddressResponse);

                  if (
                    this.props.addAddressResponse.ResultMessages[0]
                      ?.MessageType === "success"
                  ) {
                    toast.success(
                      this.props.addAddressResponse.ResultMessages[0]?.Message
                    );
                  } else {
                    toast.error(
                      this.props.addAddressResponse.ResultMessages[0]?.Message
                    );
                  }
                  this.props.setLoader(true);
                  await this.props.setLoginDetails(localStorage.getItem("Id"), localStorage.getItem("Token"));
                  if (values.isDefaultAddress) {
                    this.props.addAddressResponse.Address.IsDefault = true;
                    this.props.setLoader(true);
                    this.props.setDefaultAddressId(this.props.addAddressResponse.Address.Id);
                    await this.props.setDefaultAddress(this.props.addAddressResponse.Address, localStorage.getItem("Token"));
                    this.props.setLoader(false);
                    if (
                      this.props.defaultAddressIsUpdatedResponse.Address
                        .EntityId !== 0
                    ) {
                      toast.success("Your Default Address has been Updated.");
                    }
                    if (this.props.defaultAddressIsUpdatedResponse.Address.EntityId === 0) {
                      toast.error("Unable to update the Default Address.");
                    }
                  }
                  this.props.setLoader(false);
                  // let viewModel = {
                  //   AddressLine1: values.address1,
                  //   AddressLine2: '',
                  //   Altitude: null,
                  //   Area: await this.searchSelectedArea(
                  //     parseInt(values.area, 10)
                  //   ),
                  //   AreaId: parseInt(values.area, 10),
                  //   City: await this.searchSelectedCity(
                  //     parseInt(values.city, 10)
                  //   ),
                  //   CityId: parseInt(values.city, 10),
                  //   CompleteAddress: "",
                  //   Country: null,
                  //   CountryId: 0,
                  //   CreatedBy: null,
                  //   CreatedById: localStorage.getItem("Id"),
                  //   EntityId: 0,
                  //   EntityTypeId: 0,
                  //   HasErrors: false,
                  //   Id: 0,
                  //   InsertDate: new Date(),
                  //   IsActive: true,
                  //   IsDefault: false,
                  //   LastUpdatedBy: null,
                  //   LastUpdatedById: null,
                  //   Latitude: null,
                  //   Longitude: null,
                  //   ModifiedDate: null,
                  //   ResultMessages: [],
                  //   SortOrder: 1,
                  //   State: null,
                  //   StateId: 0,
                  //   ZipCode: null,
                  // };
                  // viewModel.CompleteAddress =
                  //   viewModel.AddressLine1 +
                  //   ", " +
                  //   viewModel.Area +
                  //   ", " +
                  //   viewModel.City;
                  //   this.props.setLoader(true);
                  //   await this.props.addCustomerAddress(
                  //     viewModel,
                  //     localStorage.getItem("Token")
                  //   );
                  //   this.props.setLoader(false);
                  //   console.log(
                  //     "add address try response: ",
                  //     this.props.addAddressResponse
                  //   );

                  //   if (
                  //     this.props.addAddressResponse.ResultMessages[0]
                  //       ?.MessageType === "success"
                  //   ) {
                  //     toast.success(
                  //       this.props.addAddressResponse.ResultMessages[0]
                  //         ?.Message
                  //     );
                  //   } else {
                  //     toast.error(
                  //       this.props.addAddressResponse.ResultMessages[0]
                  //         ?.Message
                  //     );
                  //   }
                  //   this.props.setLoader(true);
                  //   await this.props.setLoginDetails(
                  //     localStorage.getItem("Id"),
                  //     localStorage.getItem("Token")
                  //   );
                  //   if (values.isDefaultAddress) {
                  //     this.props.addAddressResponse.Address.IsDefault = true;
                  //     this.props.setLoader(true);
                  //     this.props.setDefaultAddressId(
                  //       this.props.addAddressResponse.Address.Id
                  //     );
                  //     await this.props.setDefaultAddress(
                  //       this.props.addAddressResponse.Address,
                  //       localStorage.getItem("Token")
                  //     );
                  //     this.props.setLoader(false);
                  //     if (
                  //       this.props.defaultAddressIsUpdatedResponse.Address
                  //         .EntityId !== 0
                  //     ) {
                  //       toast.success(
                  //         "Your Default Address has been Updated."
                  //       );
                  //     }
                  //     if (
                  //       this.props.defaultAddressIsUpdatedResponse.Address
                  //         .EntityId === 0
                  //     ) {
                  //       toast.error("Unable to update the Default Address.");
                  //     }
                  //   }
                  //   this.props.setLoader(false);
                  // for (
                  //   var i = 0;
                  //   i < this.props.customerDetails.Addresses.length;
                  //   i++
                  // ) {
                  //   // debugger;
                  //   if (
                  //     this.props.customerDetails.Addresses[i].Id ==
                  //     parseInt(values.address1, 10)
                  //   ) {
                  this.props.setCompleteAddress(
                    this.props.customerDetails.Addresses[i]
                  );
                  // }
                  // }
                  // this.props.setCompleteAddress(viewModel);
                } else {
                  for (var i = 0; i < this.props.customerDetails.Addresses.length; i++) {
                    // debugger;
                    if (this.props.customerDetails.Addresses[i].Id == parseInt(values.address1, 10)) {
                      this.props.setCompleteAddress(this.props.customerDetails.Addresses[i]);
                    }
                  }
                }
                await this.componentCallback(true);
                var updatedPath = updateState(this.orderPageEnum.Summary, this.props.isLogin);
                updatedPath == "Order"
                  ? this.setState({ path: updatedPath })
                  : updatedPath == "OrderAddress"
                  ? this.setState({ path: updatedPath })
                  : updatedPath == "TimeSlot"
                  ? this.setState({ path: updatedPath })
                  : updatedPath == "SignUp"
                  ? this.setState({ path: updatedPath })
                  : updatedPath == "OrderDetail"
                  ? this.setState({ path: updatedPath })
                  : this.setState({ path: "" });
              }}
            >
              {props => {
                const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

                return (
                  <>
                    <div className='row heading_bar'>
                      <div className='wrapper'>
                        <h2>Choose your address</h2>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='wrapper progress_container'>
                        <OrderProgressBar pageId={3} orderPageEnum={orderPageEnum} />
                      </div>
                      {/* <h2 className='clearfix'>
                        {this.props.customerDetails?.Addresses?.length > 0 ? (
                          <button
                            className='btn edit_button'
                            onClick={() => {
                              this.setState({ isModalOpen: true });
                            }}
                            style={{ float: "right", margin: "0 40px 0 0" }}
                          >
                            Add Address
                          </button>
                        ) : (
                          ""
                        )}
                      </h2> */}

                      <div className='wrapper item_insertion clearfix'>
                        <form name='placeOrderAddressfrm' autoComplete='off' noValidate onSubmit={handleSubmit}>
                          <div className='row'>
                            <h2 className='clearfix'>
                              {/* Pickup & Delivery Address */}
                              {this.props.customerDetails?.Addresses?.length > 0 ? (
                                <>
                                  <button
                                    className="btn edit_button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        isModalOpen: true,
                                        path: "OrderAddress",
                                      });
                                    }}
                                    style={{
                                      float: "right",
                                      margin: "0 13px 0 0",
                                    }}
                                  >
                                    Add Address
                                  </button>
                                  {values.address1 != "" && (
                                    <button
                                      className="btn edit_button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          isEditModalOpen: true,
                                          addressToEdit: values.address1,
                                          path: "OrderAddress",
                                          selectedAddress:
                                            this.props.customerDetails?.Addresses?.find(
                                              (item) =>
                                                item.Id == values.address1
                                            ),
                                        });

                                        console.log("done");
                                      }}
                                      style={{
                                        float: "right",
                                        margin: "0 13px 0 0",
                                      }}
                                    >
                                      Edit Address
                                    </button>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </h2>
                          </div>
                          <div className='row'>
                            {this.props.customerDetails?.Addresses?.length == 0 ? (
                              <>
                                <div className='col-sm-12 paddin_right field'>
                                  <label htmlFor='address1' className='show-hide'>
                                    Address Line 1
                                  </label>
                                  <input
                                    type='text'
                                    id='address1'
                                    name='address1'
                                    value={values.address1}
                                    className='form-control'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autofocus
                                    required
                                  />
                                  {errors.address1 && touched.address1 && <div className='errorMessage'>{errors.address1}</div>}
                                </div>
                              </>
                            ) : (
                              <div className='col-sm-12 paddin_right field'>
                                <label htmlFor='address' className='show-hide' style={{ marginLeft: "15px" }}>
                                  Address
                                  <code style={{ backgroundColor: "#ffffff" }}>
                                    <b>*</b>
                                  </code>
                                </label>
                                <select
                                  id='address1'
                                  name='address1'
                                  value={values.address1}
                                  className='form-control  required-field'
                                  onChange={
                                    // console.log("address:", e.currentTarget.value);
                                    handleChange
                                  }
                                  onBlur={handleBlur}
                                  autofocus
                                  required
                                >
                                  <option value=''>Select Address</option>
                                  {this.props.customerDetails?.Addresses?.map((ci, index) => {
                                    return (
                                      <option key={index} value={ci?.Id}>
                                        {ci?.CompleteAddress}{" "}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            )}
                          </div>
                          {this.props.customerDetails?.Addresses?.length == 0 && (
                            <div className='row'>
                              <div className='col-sm-6 paddin_right field'>
                                <label htmlFor='city' className='show-hide'>
                                  City
                                </label>
                                <select
                                  id='city'
                                  name='city'
                                  value={values.city}
                                  className='form-control required-field'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                >
                                  <option value=''>Select City</option>
                                  {this.props.address?.Cities?.map((ci, index) => {
                                    return (
                                      <option key={index} value={ci.Id}>
                                        {ci.Description}{" "}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className='col-sm-6 paddin_left field'>
                                <label htmlFor='area' className='show-hide'>
                                  Area
                                </label>
                                <select
                                  className='form-control required-field'
                                  id='area'
                                  name='area'
                                  value={values.area}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                >
                                  <option value=''>Select Area</option>
                                  {this.props.address?.CityAreas?.map((area, index) => {
                                    return (
                                      <option key={index} value={area.Id}>
                                        {area.Name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )}
                          <div className='row'>
                            <button type='button' className='btn' onClick={this.back}>
                              Back
                            </button>

                            {this.props.customerDetails?.Addresses?.length > 0 ? (
                              <button
                                type='submit'
                                style={{ marginRight: "12px" }}
                                className='btn btn-primary pull-right'
                                disabled={values.address1 === ""}
                              >
                                Continue
                              </button>
                            ) : (
                              <button
                                type='submit'
                                style={{ marginRight: "12px" }}
                                className='btn btn-primary pull-right'
                                disabled={values.address1 === "" || values.city === "" || values.area === ""}
                              >
                                Continue
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </>
        ) : this.state.path == "SignUp" ? (
          <SignUp />
        ) : this.state.path == "TimeSlot" ? (
          <OrderTime />
        ) : this.state.path == "Order" ? (
          <OrderItem />
        ) : this.state.path == "OrderDetail" ? (
          <OrderDetail />
        ) : (
          ""
        )}

        <Modal
          isOpen={this.state.isModalOpen}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          id='ngdialog15'
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document' style={{ zIndex: 3 }}>
            <div className='modal-header'>
              <h3 className='modal-title' id='modal-picking-address-label'>
                Pickup & Delivery Address
              </h3>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='wrapper item_insertion clearfix'>
                  <Formik
                    initialValues={{
                      address1: "",
                      city: 0,
                      area: 0,
                      isDefaultAddress: false,
                    }}
                    validationSchema={schema}
                    validateOnBlur
                    onSubmit={async (values) => {
                      this.setState({ path: "OrderAddress" });
                      let viewModel = {
                        Address: {
                          AddressLine1: values.address1,
                          AddressLine2: "",
                          Altitude: null,
                          Area: await this.searchSelectedArea(parseInt(values.area, 10)),
                          AreaId: values.area,
                          City: "Islamabad",
                          CityId: values.city,
                          CompleteAddress: "",
                          Country: "Canada",
                          CountryId: 1,
                          CreatedBy: null,
                          CreatedById: this.props.customerDetails?.Customer?.Id,
                          EntityId: this.props.customerDetails?.Customer?.Id,
                          EntityTypeId: 10,
                          HasErrors: false,
                          Id: null,
                          InsertDate: new Date(),
                          IsActive: true,
                          IsDefault: false,
                          LastUpdatedBy: null,
                          LastUpdatedById: null,
                          Latitude: "D",
                          Longitude: "D",
                          ModifiedDate: null,
                          ResultMessages: [],
                          SortOrder: 1,
                          State: "Ontario",
                          StateId: 1,
                          ZipCode: "D",
                        },
                        Cities: this.props.address?.Cities,
                        CityAreas: this.props.address?.CityAreas,
                        CreatedBy: null,
                        CreatedById: this.props.customerDetails?.Customer?.Id,
                        HasErrors: false,
                        Id: 0,
                        InsertDate: "2021-08-26T14:15:06.8410833Z",
                        IsActive: true,
                        LastUpdatedBy: null,
                        LastUpdatedById: null,
                        ModifiedDate: null,
                      };

                      viewModel.Address.CompleteAddress =
                        viewModel.Address.AddressLine1 + ", " + viewModel.Address.Area + ", " + viewModel.Address.City;
                      console.log("complete Address : ", viewModel.Address.CompleteAddress);
                      this.props.setLoader(true);
                      await this.props.addCustomerAddress(viewModel, localStorage.getItem("Token"));
                      this.props.setLoader(false);
                      console.log("add address try response: ", this.props.addAddressResponse);

                      if (this.props.addAddressResponse.ResultMessages[0]?.MessageType === "success") {
                        toast.success(this.props.addAddressResponse.ResultMessages[0]?.Message);
                      } else {
                        toast.error(this.props.addAddressResponse.ResultMessages[0]?.Message);
                      }
                      this.props.setLoader(true);
                      await this.props.setLoginDetails(localStorage.getItem("Id"), localStorage.getItem("Token"));
                      if (values.isDefaultAddress) {
                        this.props.addAddressResponse.Address.IsDefault = true;
                        this.props.setLoader(true);
                        this.props.setDefaultAddressId(this.props.addAddressResponse.Address.Id);
                        await this.props.setDefaultAddress(this.props.addAddressResponse.Address, localStorage.getItem("Token"));
                        this.props.setLoader(false);
                        if (this.props.defaultAddressIsUpdatedResponse.Address.EntityId !== 0) {
                          toast.success("Your Default Address has been Updated.");
                        }
                        if (this.props.defaultAddressIsUpdatedResponse.Address.EntityId === 0) {
                          toast.error("Unable to update the Default Address.");
                        }
                      }
                      this.props.setLoader(false);
                      this.setState({ isModalOpen: !this.state.isModalOpen });
                    }}
                  >
                    {props => {
                      const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

                      return (
                        <form name='placeOrderAddressfrm' noValidate autoComplete='off' onSubmit={handleSubmit}>
                          <div className='col-sm-12 paddin_right field'>
                            {/* {values.address1 == "" ? null : (
                              <label htmlFor='address1' className='show-hide ng-hide'>
                                Address Line 1
                              </label>
                            )} */}
                            <input
                              type='text'
                              className='form-control'
                              name='address1'
                              id='address1'
                              placeholder='Address'
                              autoFocus
                              value={values.address1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              maxLength={60}
                            />
                            {errors.address1 && touched.address1 && <div className='errorMessage'>{errors.address1}</div>}
                          </div>
                          <div className='col-sm-6 paddin_right field'>
                            {/* {values.city == 0 ? null : (
                              <label htmlFor='city' className='show-hide'>
                                City
                              </label>
                            )} */}
                            <select
                              name='city'
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className='form-control required-field'
                            >
                              <option value={0} disabled>
                                Select City{" "}
                              </option>
                              {this.props.address?.Cities?.map((ci, index) => {
                                return (
                                  <option key={index} value={ci.Id}>
                                    {ci.Description}{" "}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.city && touched.city && <div className='errorMessage'>{errors.city}</div>}
                          </div>
                          <div className='col-sm-6 paddin_left field'>
                            {/* <label htmlFor='area' className='show-hide'>
                              Area
                            </label> */}
                            <select
                              name='area'
                              value={values.area}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className='form-control required-field'
                            >
                              <option value={0} disabled>
                                Select Area{" "}
                              </option>
                              {this.props.address?.CityAreas?.map((area, index) => {
                                return (
                                  <option key={index} value={area.Id}>
                                    {area.Name}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.area && touched.area && <div className='errorMessage'>{errors.area}</div>}
                          </div>
                          <div className='col-sm-6 paddin_left col-sm-offset-6'>
                            <label>
                              <input
                                style={{ margin: "0px 5px 2px; !important" }}
                                type='checkbox'
                                name='isDefaultAddress'
                                className='checkbox-inline'
                                onChange={handleChange}
                              />
                              Default Address
                            </label>
                          </div>
                          <div className='col-sm-6 paddin_left col-sm-offset-6'></div>
                          <div className='row'>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={
                                errors.address1 || values.address1 === ""
                                  ? true
                                  : errors.city || values.city === ""
                                  ? true
                                  : errors.area || values.area === ""
                                  ? true
                                  : false
                              }
                            >
                              Save
                            </button>
                            <button type='button' id='btnClose' hidden>
                              Close
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div
              className='ngdialog-close'
              onClick={() => {
                this.setState({ isModalOpen: false });
              }}
            ></div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isEditModalOpen}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          id='ngdialog15'
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document'>
            <div className='modal-header'>
              <h3 className='modal-title' id='modal-picking-address-label'>
                Pickup & Delivery Address
              </h3>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='wrapper item_insertion clearfix'>
                  <Formik
                    initialValues={{
                      address1: this.props.customerDetails?.Addresses?.find(
                        (item) => item.Id == this.state.addressToEdit
                      )?.AddressLine1,
                      city: this.props.customerDetails?.Addresses?.find(
                        (item) => item.Id == this.state.addressToEdit
                      )?.CityId,
                      area: this.props.customerDetails?.Addresses?.find(
                        (item) => item.Id == this.state.addressToEdit
                      )?.AreaId,
                      isDefaultAddress:
                        this.props.customerDetails?.Addresses?.find(
                          (item) => item.Id == this.state.addressToEdit
                        )?.IsDefault,
                    }}
                    validationSchema={schema}
                    validateOnBlur
                    onSubmit={async (values) => {
                      // actions.setSubmitting(false);
                      let viewModel = {
                        Address: {
                          AddressLine1: values.address1,
                          AddressLine2: "",
                          Altitude: null,
                          Area: await this.searchSelectedArea(parseInt(values.area, 10)),
                          AreaId: values.area,
                          City: "Lahore",
                          CityId: values.city,
                          CompleteAddress: "",
                          Country: "Pakistan",
                          CountryId: 1,
                          CreatedBy: null,
                          CreatedById: this.props.customerDetails?.Customer?.Id,
                          EntityId: this.props.customerDetails?.Customer?.Id,
                          EntityTypeId: 10,
                          HasErrors: false,
                          Id: parseInt(this.state.addressToEdit),
                          InsertDate: new Date(),
                          IsActive: true,
                          IsDefault: false,
                          LastUpdatedBy: null,
                          LastUpdatedById: null,
                          Latitude: "D",
                          Longitude: "D",
                          ModifiedDate: null,
                          ResultMessages: [],
                          SortOrder: 1,
                          State: "Ontario",
                          StateId: 1,
                          ZipCode: "D",
                        },
                        Cities: this.props.address?.Cities,
                        CityAreas: this.props.address?.CityAreas,
                        CreatedBy: null,
                        CreatedById: this.props.customerDetails?.Customer?.Id,
                        HasErrors: false,
                        Id: 0,
                        InsertDate: "2021-08-26T14:15:06.8410833Z",
                        IsActive: true,
                        LastUpdatedBy: null,
                        LastUpdatedById: null,
                        ModifiedDate: null,
                      };

                      viewModel.Address.CompleteAddress =
                        viewModel.Address.AddressLine1 +
                        ", " +
                        viewModel.Address.Area +
                        ", " +
                        viewModel.Address.City;
                      console.log(
                        "complete Address : ",
                        viewModel.Address.CompleteAddress
                      );
                      this.props.setLoader(true);
                      await this.props.editCustomerAddress(
                        viewModel,
                        localStorage.getItem("Token")
                      );
                      this.props.setLoader(false);
                      
                      console.log(
                        "add edit address try response : ",
                        this.props.editCustomerAddressResponse
                      );

                      if (this.props.editCustomerAddressResponse?.ResultMessages[0]?.MessageType === "success") {
                        toast.success("Your address has been updated successfully.");
                      } else {
                        toast.error(this.props.editCustomerAddressResponse?.ResultMessages[0]?.Message);
                      }

                      this.props.setLoader(true);
                      await this.props.setLoginDetails(localStorage.getItem("Id"), localStorage.getItem("Token"));
                      this.props.setLoader(false);
                      this.setState({
                        isEditModalOpen: false,
                        addressToEdit: null,
                        selectedAddress: null,
                      });
                      // actions.setSubmitting(false);
                      //   debugger;
                      //   if (values.isDefaultAddress) {
                      //     this.props.selectedAddress.Address.IsDefault = true;
                      //     this.props.setLoader(true);
                      //     this.props.setDefaultAddressId(
                      //       this.props.selectedAddress.Address.Id
                      //     );
                      //     await this.props.setDefaultAddress(
                      //       this.props.selectedAddress.Address,
                      //       localStorage.getItem("Token")
                      //     );
                      //     this.props.setLoader(false);
                      //     if (
                      //       this.props?.defaultAddressIsUpdatedResponse?.Address?.EntityId !== 0
                      //     ) {
                      //       toast.success(
                      //         "Your Default Address has been Updated."
                      //       );
                      //     }
                      //     if (
                      //       this.props?.defaultAddressIsUpdatedResponse?.Address?.EntityId === 0
                      //     ) {
                      //       toast.error("Unable to update the Default Address.");
                      //     }
                      //   }
                      //   console.log("state values:", this.state.selectedAddress);
                      //   let addressObj = this.state.selectedAddress;
                      //   addressObj.AddressLine1 = values.address1;
                      //   addressObj.Area = await this.searchSelectedArea(
                      //     parseInt(values.area, 10)
                      //   );
                      //   addressObj.AreaId = values.area;
                      //   addressObj.CreatedById =
                      //     this.props.customerDetails?.Customer?.Id;

                      //   let viewModel = {
                      //     Address: {
                      //       AddressLine1: values.address1,
                      //       AddressLine2: "",
                      //       Altitude: null,
                      //       Area: await this.searchSelectedArea(
                      //         parseInt(values.area, 10)
                      //       ),
                      //       AreaId: values.area,
                      //       City: "Lahore",
                      //       CityId: 1,
                      //       CompleteAddress: "",
                      //       Country: "Pakistan",
                      //       CountryId: 1,
                      //       CreatedBy: null,
                      //       CreatedById: this.props.customerDetails?.Customer?.Id,
                      //       EntityId: this.props.customerDetails?.Customer?.Id,
                      //       EntityTypeId: 10,
                      //       HasErrors: false,
                      //       Id: 0,
                      //       InsertDate: "2021-08-26T11:10:00.2001816Z",
                      //       IsActive: true,
                      //       IsDefault: values.isDefaultAddress,
                      //       LastUpdatedBy: null,
                      //       LastUpdatedById: null,
                      //       Latitude: "D",
                      //       Longitude: "D",
                      //       ModifiedDate: "2021-08-26T10:26:37.06",
                      //       ResultMessages: [],
                      //       SortOrder: 1,
                      //       State: "Ontario",
                      //       StateId: 1,
                      //       ZipCode: "D",
                      //     },
                      //     Cities: this.props.address?.Cities,
                      //     CityAreas: this.props.address?.CityAreas,
                      //     CreatedBy: null,
                      //     CreatedById: this.props.customerDetails?.Customer?.Id,
                      //     HasErrors: false,
                      //     Id: this.state.editAddressId,
                      //     InsertDate: "2021-08-26T14:15:06.8410833Z",
                      //     IsActive: true,
                      //     LastUpdatedBy: null,
                      //     LastUpdatedById: null,
                      //     ModifiedDate: null,
                      //   };
                      //   viewModel.Address.CompleteAddress =
                      //     viewModel.Address.AddressLine1 +
                      //     ", " +
                      //     viewModel.Address.Area +
                      //     ", " +
                      //     viewModel.Address.City;
                      //   console.log(
                      //     "complete Address : ",
                      //     viewModel.Address.CompleteAddress
                      //   );

                      //   addressObj.Address.CompleteAddress =
                      //     addressObj.Address?.AddressLine1 +
                      //     ", " +
                      //     addressObj.Address?.Area +
                      //     ", " +
                      //     addressObj.Address?.City;

                      //   this.props.setLoader(true);
                      //   await this.props.editCustomerAddress(
                      //     addressObj,
                      //     localStorage.getItem("Token")
                      //   );
                      //   this.props.setLoader(false);
                      //   console.log(
                      //     "add edit address try response : ",
                      //     this.props.editCustomerAddressResponse
                      //   );

                      //   if (
                      //     this.props.editCustomerAddressResponse
                      //       ?.ResultMessages[0]?.MessageType === "success"
                      //   ) {
                      //     toast.success(
                      //       this.props.editCustomerAddressResponse
                      //         ?.ResultMessages[0]?.Message
                      //     );
                      //   } else {
                      //     toast.error(
                      //       this.props.editCustomerAddressResponse
                      //         ?.ResultMessages[0]?.Message
                      //     );
                      //   }

                      //   this.props.setLoader(true);
                      //   await this.props.setLoginDetails(
                      //     localStorage.getItem("Id"),
                      //     localStorage.getItem("Token")
                      //   );
                      //   this.props.setLoader(false);
                      //   this.setState({
                      //     isEditModalOpen: !this.state.isEditModalOpen,
                      //   });
                    }}
                  >
                    {(props) => {
                      const {
                        touched,
                        errors,
                        handleSubmit,
                        values,
                        handleChange,
                        handleBlur,
                      } = props;
                      let editAddress =
                        this.props.customerDetails?.Addresses.find(
                          (item) => item.Id == this.state.addressToEdit
                        );
                      console.log({ editAddress });
                      console.log("address1:", values.address1);
                      return (
                        <form
                          name="placeOrderEditAddressfrm"
                          noValidate
                          autoComplete="off"
                          onSubmit={handleSubmit}
                        >
                          <div className="col-sm-12 paddin_right field">
                            {/* {values.address1 === "" ? null : (
                              <label htmlFor="address1" className="show-hide">
                                Address Line 1
                              </label>
                            )} */}
                            <input
                              type='text'
                              className='form-control'
                              name='address1'
                              id='address1'
                              placeholder='Address'
                              value={values.address1}
                              onChange={handleChange}
                              maxLength={60}
                            />
                            {errors.address1 && touched.address1 && (
                              <div className="errorMessage">
                                {errors.address1}
                              </div>
                            )}
                          </div>

                          <div className="col-sm-6 paddin_right field">
                            {/* <label htmlFor="city" className="show-hide">
                              City
                            </label> */}
                            <select
                              name='city'
                              id='city'
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className='form-control required-field'
                            >
                              <option value={0} disabled>
                                Select City{" "}
                              </option>
                              {this.props.address?.Cities?.map((ci, index) => {
                                return (
                                  <option key={index} selected='selected' value={ci.Id}>
                                    {ci.Description}{" "}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.city && touched.city && (
                              <div className="errorMessage">{errors.city}</div>
                            )}
                          </div>

                          <div className="col-sm-6 paddin_left field">
                            {/* <label htmlFor="area" className="show-hide">
                              Area
                            </label> */}
                            <select
                              name='area'
                              id='area'
                              value={values.area}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className='form-control required-field'
                            >
                              <option value={0} disabled>
                                Select Area{" "}
                              </option>
                              {this.props.address?.CityAreas?.map((area, index) => {
                                return (
                                  <option key={index} value={area.Id}>
                                    {area.Name}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.area && touched.area && (
                              <div className="errorMessage">{errors.area}</div>
                            )}
                          </div>
                          <div className='col-sm-6 paddin_left col-sm-offset-6'>
                            <label>
                              <input
                                type='checkbox'
                                name='isDefaultAddress'
                                className='checkbox-inline'
                                checked={
                                  // this.props.selectedAddress?.Address?.IsDefault
                                  //   ? true
                                  //   :
                                  values.isDefaultAddress ? true : false
                                }
                                value={values.isDefaultAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={values.isDefaultAddress ? true : false}
                              />
                              Default Address
                            </label>
                          </div>
                          <div className='col-sm-6 paddin_left col-sm-offset-6'></div>
                          <div className='row'>
                            <button type='submit' className='btn btn-primary'>
                              Update
                            </button>
                            <button type='button' id='btnClose' hidden>
                              Close
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div
              className='ngdialog-close'
              onClick={() => {
                this.setState({ isEditModalOpen: false });
              }}
            ></div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchAddressDetails,
  setCompleteAddress,
  setLoader,
  setLoginDetails,
  addCustomerAddress,
  setDefaultAddressId,
  setDefaultAddress,
  fetchOrderScheduleByAddressId,
  setSelectedPickUpSchedule,
  setPath,
  editCustomerAddress,
};

const mapStateToProps = ({ Auth }) => {
  let {
    address,
    isLogin,
    customerDetails,
    pickUpSchedule,
    selectedPickUpSchedule,
    addAddressResponse,
    defaultAddressIsUpdatedResponse,
    completeAddress,
    editCustomerAddressResponse,
    path,
  } = Auth;
  return {
    address,
    isLogin,
    customerDetails,
    addAddressResponse,
    defaultAddressIsUpdatedResponse,
    completeAddress,
    selectedPickUpSchedule,
    pickUpSchedule,
    path,
    editCustomerAddressResponse
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderAddress);
