import React, { Component } from "react";
import { orderPageEnum } from "../models/globalUtilities";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';


export class OrderProgress extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const { pageId, isLogin } = this.props;

        return(
            <div id='progress_section'>
        <ul id='progressbar'>
          <li
            className={
              pageId== orderPageEnum.Items
                ? "active"
                : "" + pageId > orderPageEnum.Items
                ? " completed"
                : ""
            }
          >
            <a onClick={()=> console.log("link to items")}>
              {" "}
              {/* <Link to={"/Order"}>items</Link> */}
              {" "}
              Items
            </a>
          </li>
          {isLogin === false && (
            <li
              className={
                pageId == orderPageEnum.Signup
                  ? "active"
                  : "" + pageId > orderPageEnum.Signup
                  ? " completed"
                  : ""
              }
            >
              {pageId < orderPageEnum.Signup ? (
                <span>Contact</span>
              ) : (
                <a onClick={()=> console.log("linked to contact sign up")}>
                  {" "}
                  {/* <Link to={"/Account/Sign"}>Contact</Link> */}
                  Contact
                  {" "}
                </a>
              )}
            </li>
          )}
          {
            <li
              className={
                pageId == orderPageEnum.Address
                  ? "active"
                  : "" + pageId > orderPageEnum.Address
                  ? " completed"
                  : ""
              }
            >
              {pageId < orderPageEnum.Address ? (
                <span>Address</span>
              ) : (
                <a onClick={()=> console.log("link to address")}>
                  {" "}
                  {/* <Link to={"/Order/Address"}> Address </Link> */}
                  Address
                </a>
              )}
            </li>
          }
          {
            <li
              className={
                pageId === orderPageEnum.Time
                  ? "active"
                  : "" + pageId > orderPageEnum.Time
                  ? " completed"
                  : ""
              }
            >
              {pageId < orderPageEnum.Time ? (
                <span>Time</span>
              ) : (
                <a onClick={()=> console.log("link to time slots")}>
                  {" "}
                  {/* <Link to={"/Order/TimeSlot"}> Time </Link> */}
                  Time
                </a>
              )}
            </li>
          }
          {isLogin === true && (
            <li
              className={
                pageId === orderPageEnum.Summary
                  ? "active"
                  : "" + pageId > orderPageEnum.Summary
                  ? " completed"
                  : ""
              }
            >
              {pageId < orderPageEnum.Summary ? (
                <span>Summary</span>
              ) : (
                <a onClick={()=> console.log("link to summary")}>
                  {" "}
                  {/* <Link to={"/OrderDetail"}> Summary </Link> */}
                  Summary
                </a>
              )}
            </li>
          )}
        </ul>
      </div>
        )
    }
}

const mapDispatchToProps = {};
const mapStateToProps = ({ Auth }) => {
    let { pageId, isLogin } = Auth;
    return { pageId, isLogin };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderProgress);