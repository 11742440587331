import React, { Component } from "react";
import "../../css/style.css";
import WOW from "wowjs";

export class AboutSection extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
  
    return (
      <div className="row about_section">
        <div className="wrapper">
          <div className="col-sm-6">
            <img
              src={require("../../images/about-img.png")}
              alt=""
              width="100%"
              className="wow bounceInUp"
            />
          </div>
          <div className="col-sm-6 about_detail wow bounceInRight">
            <h1>
              About Dry Cleaning & <br />
              Laundry Online
            </h1>
            <p>
              lavanderia.com is founded on the belief that time should not be
              wasted in doing laundry. We are about creating a quality service
              that is adaptive to your lifestyle, right at your fingertips.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
