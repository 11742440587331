import { orderPageEnum } from "../models/globalUtilities"

export const UpdatePageId = (pageId, isLogin) => {
    var nextPageId ;

    switch(pageId){

        case orderPageEnum.Items :
            if(isLogin){
                nextPageId = orderPageEnum.Address
            }
            else {
                nextPageId = orderPageEnum.Signup
            }
            break;

        case orderPageEnum.Address :
            nextPageId = orderPageEnum.Time
            break;
        
        case orderPageEnum.Time :
            nextPageId = orderPageEnum.Summary
            break;
        
        default:
            break;
    }
    
    return nextPageId;
}