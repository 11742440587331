import React, { Component } from "react";
import ContactForm from "./Forms/ContactForm";

export class ContactUs extends Component {
  static displayName = ContactUs.name;

  render() {
    return (
      <>
        <div className="row heading_banner">
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13277.806065558285!2d72.9750465!3d33.6972637!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x670687d7bb161ecf!2sDhobi+Online!5e0!3m2!1sen!2s!4v1538222060214"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: "0px" }}
            allowFullScreen={true}
          ></iframe> */}
        </div>

        <div className="row">
          <div className="wrapper clearfix contact_wrap faq_wrap">
            <h3>Contact Us</h3>
            <div className="col-md-6 left_zero clearfix">
              <h5>Company Address</h5>
              <h6>Lavanderia</h6>
              <ul>
                <li>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  H/O, TEAM HOUSE  217  Lalazar Commercial, Raiwind Road, Lahore.
                </li>
                <li>
                  <i className="fa fa-mobile" aria-hidden="true"></i>{" "}
                  0311-4199500{" "}
                </li>
                <li>
                  <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                  +92-42-35957999{" "}
                </li>
                <li>
                  <i className="fa fa-at" aria-hidden="true"></i>{" "}
                  <a href="mailto:services@lavanderia.pk ">
                  services@lavanderia.pk
                  </a>
                </li>
              </ul>
              <div className="social_icons">
                <a
                  href="https://www.facebook.com/"
                  title="Facebook"
                  target="_blank"
                >
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a
                  href="https://twitter.com/?lang=en"
                  title="Twitter"
                  target="_blank"
                >
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a
                  href="https://www.linkedin.com/uas/login"
                  title="Linkedin"
                  target="_blank"
                >
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 right_zero">
              <ContactForm />
            </div>
          </div>
        </div>
      </>
    );
  }
}
