import React, { Component } from "react";
/*import '../css/style.css';*/
import WOW from "wowjs";

export class Testimonial extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="row customers_testimonials">
        <div className="wrapper">
          <h3>Our Customers Say</h3>

          <div
            className="col-sm-4 wow bounceInLeft"
            style={{ visibility: "visible" }}
          >
            <div className="customer-section">
              <div className="thumbnail-cst">
                <img src="pic1.jpg" alt="" />
              </div>
              <div className="cst-info">
                <p>
                  ”Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nulla eu libero aliquet nisi posuere sollicitudin. Vestibulum
                  aliquam.”
                </p>
              </div>
            </div>
            <h4>Name will here</h4>
            <p>
              <em>Designation will be place here</em>
            </p>
          </div>

          <div
            className="col-sm-4 wow bounceInLeft"
            style={{ visibility: "visible" }}
          >
            <div className="customer-section">
              <div className="thumbnail-cst">
                <img src="pic2.jpg" alt="" />
              </div>
              <div className="cst-info">
                <p>
                  ”Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nulla eu libero aliquet nisi posuere sollicitudin. Vestibulum
                  aliquam.”
                </p>
              </div>
            </div>
            <h4>Name will here</h4>
            <p>
              <em>Designation will be place here</em>
            </p>
          </div>
          <div
            className="col-sm-4 wow bounceInLeft"
            style={{ visibility: "visible" }}
          >
            <div className="customer-section">
              <div className="thumbnail-cst">
                <img src="pic3.jpg" alt="" />
              </div>
              <div className="cst-info">
                <p>
                  ”Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nulla eu libero aliquet nisi posuere sollicitudin. Vestibulum
                  aliquam.”
                </p>
              </div>
            </div>
            <h4>Name will here</h4>
            <p>
              <em>Designation will be place here</em>
            </p>
          </div> 
        </div>
      </div>
    );
  }
}
