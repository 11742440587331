import React, { Component } from "react";
import "../../css/style.css";
import WOW from "wowjs";

export class CustomerSection extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="row customers_section">
        <div className="wrapper">
          <h3>Why Customers Choose Us</h3>
          <div className="col-sm-4 customer_colum  wow bounceInLeft">
            <i className="opening_time"></i>
            <h4>Opening Time</h4>
            <p>We're open from 9am to 9pm, every day</p>
          </div>
          <div className="col-sm-4 customer_colum wow bounceIn">
            <i className="ico_trustworthy"></i>
            <h4>Trustworthy</h4>
            <p>Let us Perform Dirty Work for You</p>
          </div>
          <div className="col-sm-4 customer_colum wow bounceInRight">
            <i className="ico_guaranteed"></i>
            <h4>Hassle Free</h4>
            <p>Save Time, Money and Avoid Hassle
                Enjoy Free Time in Making Fun</p>
          </div>
        </div>
      </div>
    );
  }
}
