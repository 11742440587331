import React, { Component } from "react";

export class Terms extends Component {
  static displayName = Terms.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="row heading_banner">
          <img src="faq_banner.jpg" alt="" />
        </div>
        <div className="sections_group">
          <div className="entry-content" itemprop="mainContentOfPage">
            <div className="section the_content has_content">
              <div className="section_wrapper">
                <div className="the_content_wrapper">
                  <h1 style={{ marginLeft: "15px", color: "black" }}>
                    Terms and Conditions
                  </h1>
                  <ul
                    style={{
                      marginLeft: "50px",
                      paddingTop: "8px",
                      fontSize: "medium",
                      listStyleType: "disc",
                    }}
                  >
                    <li style={{ paddingTop: "8px" }}>
                      We accept payment in Cash upon Delivery of garments back
                      to the Customer.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      You can estimate the bill amount at the time of handing
                      over your garments to the Pickup Rider.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      The Invoice will be handed over to the customer at the
                      time of pickup.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      All advance payment must be recorded in the Pickup Slip
                      and signed by our Pickup Rider.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      &nbsp;We operate from 9 AM to 9 PM, 7 days a Week except
                      Govt. holidays.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      &nbsp;You can schedule a pickup by calling us or by
                      contacting on our website or Facebook page.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      You need to provide your Name, Mobile Number, Address and
                      Pickup Date &amp; Time Slot.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      Our Rider will be at your doorstep at the mentioned time
                      slot. However, we do not guarantee it.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      You need to count your clothes at the time of pickup and
                      verify it on the pickup slip you will get from our Rider.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      The garments will be delivered on the date &amp; time
                      mentioned on the Pickup Slip. However, we do not take any
                      guarantee of it.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      We examine each garment after we receive them at our
                      warehouse and notify if any damage is observed. However,
                      we don’t guarantee that every damage will be identified
                      before processing.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      Lavanderia will call you before delivery.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      Any special instruction has to be provided to our Pickup
                      Rider and must be mentioned on the Pickup Slip provided to
                      you.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      You need to count your garments during delivery. No claims
                      for garment loss will be entertained if any mismatch is
                      reported after delivery.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      For any loss or damage of garment (which were not there
                      when the Laundry was picked up), the compensation amount
                      shall not be greater than 10 times of cost of processing
                      that garment.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      We take no guarantee of color bleed in laundry. You are
                      advised not to give clothes that may bleed color in
                      laundry. However you may give it for dry-clean.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      The rates are subject to change without prior notice.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      Any issue with respect to Quality has to be reported to us
                      within 24 hours of Delivery of garments.
                    </li>
                    <li style={{ paddingTop: "8px" }}>
                      As our business changes, we may want to modify some of
                      terms in this agreement. Therefore, we encourage you to
                      check back frequently to familiarize yourself with any
                      changes.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
