import React, { Component } from "react";
import { OrderProgressBar } from "../Order/OrderProgressBar";
import OrderItem from "../Order/OrderItem";
import OrderTime from "../Order/OrderTime";
import OrderDetail from "../Order/OrderDetail";
import OrderAddress from "../Order/OrderAddress";
import { Formik } from "formik";
import * as Yup from "yup";
import { signup } from "../../models/signupModel";
import { addressModel } from "../../models/address";
import { orderPageEnum, updateState } from "../Order/models/globalUtilities";
import {
  setSignUpData,
  saveSignUp,
  fetchLoginDetails,
  setLoginDetails,
} from "../../store/action/Auth";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
//import './formStyles.css';

const schema = Yup.object().shape({
  fname: Yup.string()
    .required("First Name is required.")
    .min(3, "Minimum 2 character are required")
    .max(30, "Maximum 30 charactered are allowed"),

  lname: Yup.string()
    .required("Last Name is required.")
    .min(3, "Minimum 2 character are required")
    .max(30, "Maximum 30 charactered are allowed"),

  mobile: Yup.string()
    .required("Phone No is required.")
    .min(11, "Invalid phone number")
    .max(11, "Maximum 11 characters are allowed."),

  email: Yup.string()
    .email("Invalid Email")
    .required("Email is required.")
    .min(0, "")
    .max(100, ""),

  // password: Yup.string()
  //     .required("Password is required.")
  //     .min(8, "Password must be at least 8 characters long")
  //     .max(15, "Password not be greater than to 15 characters"),

  // cpassword: Yup.string()
  //     .required("Password is required.")
  //     .min(2, "2 character are required")
  //     .max(500, "Maximum 500 character are allowed")
});

const validate = (values) => {
  let errors = {};
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be at least 8 characters long";
  } else if (values.password.length > 16) {
    errors.password = "Password not be greater than to 16 characters";
  } else if (!values.password.match(/[A-Z]/)) {
    errors.password = "Password require at least one capital letter";
  } else if (!values.password.match(/[0-9]/)) {
    errors.password = "Password must have at least one number";
  }
  if (values.cpassword) {
    if (values.cpassword !== values.password) {
      errors.cpassword = "Password do not match!";
    }
  }
  // if(values.mobile){
  //     //values.mobile = '+92' + values.mobile;
  //     fetch(`https://dcpapi.ssasoft.com/api/SignUp/Get?mobilePhone=` + values.mobile)
  //     .then(res => res.json())
  //     .then(
  //       (result) => {
  //         console.log(result);
  //         if(result.ResultMessages.length > 0){
  //             result.ResultMessages.forEach(response => {
  //                errors.mobile = response.Message;
  //             });
  //         }
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {
  //         console.log(error);
  //       }
  //     )
  // }
  return errors;
};

class SignUp extends Component {
  orderPageEnum = orderPageEnum;
  constructor(props) {
    super(props);
    this.state = {
      path: "SignUp",
      isRegistered: false,
    };
  }

  back = () => {
    this.setState({ path: "Order" });
  };

  render() {
    const postData = async (url = "", data = {}) => {
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    };

    return (
      <>
        {this.state.path === "SignUp" ? (
          <>
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                mobile: "",
                email: "",
                password: "",
                cpassword: "",
              }}
              validationSchema={schema}
              validate={validate}
              // tell the formik to validate onBlur
              validateOnBlur
              onSubmit={async (values) => {

                // let viewModel = {
                //   Signup: {
                //     Password: values.password,
                //     FirstName: "string",
                //     LastName: "string",
                //     SendEmail: true,
                //     SendSMS: true,
                //     Unsubscribe: true,
                //     ImagePath: "string",
                //     Email:values.email,
                //     //   values.mobile,
                //     Phone: values.mobile,
                //     TermandCondition: true,
                //     FCMToken: "string",
                //     DeviceType: 0,
                //     CustomerOTPId: 0,
                //     VerificationCode: "string",
                //     HasErrors: true,
                //     ResultMessages: [
                //       {
                //         MessageType: "string",
                //         Message: "string"
                //       }
                //     ],
                //     Id: 0,
                //     CreatedById: 0,
                //     CreatedBy: "string",
                //     LastUpdatedById: 0,
                //     LastUpdatedBy: "string",
                //     InsertDate: "2022-10-31T07:26:24.439Z",
                //     ModifiedDate: "2022-10-31T07:26:24.439Z",
                //     IsActive: true
                //   },
                //   Address: {
                //     EntityId: 0,
                //     EntityTypeId: 0,
                //     AddressLine1: "string",
                //     AddressLine2: "string",
                //     CountryId: 0,
                //     Country: "string",
                //     StateId: 0,
                //     State: "string",
                //     CityId: 0,
                //     City: "string",
                //     AreaId: 0,
                //     Area: "string",
                //     Longitude: "string",
                //     Latitude: "string",
                //     Altitude: "string",
                //     IsDefault: true,
                //     SortOrder: 0,
                //     CompleteAddress: "string",
                //     HasErrors: true,
                //     ResultMessages: [
                //       {
                //         MessageType: "string",
                //         Message: "string"
                //       }
                //     ],
                //     Id: 0,
                //     CreatedById: 0,
                //     CreatedBy: "string",
                //     LastUpdatedById: 0,
                //     LastUpdatedBy: "string",
                //     InsertDate: "2022-10-31T07:26:24.439Z",
                //     ModifiedDate: "2022-10-31T07:26:24.439Z",
                //     IsActive: true
                //   },
                //   States: [
                //     {
                //       Name: "string",
                //       Abbreviation: "string",
                //       HasErrors: true,
                //       ResultMessages: [
                //         {
                //           MessageType: "string",
                //           Message: "string"
                //         }
                //       ],
                //       Id: 0,
                //       CreatedById: 0,
                //       CreatedBy: "string",
                //       LastUpdatedById: 0,
                //       LastUpdatedBy: "string",
                //       InsertDate: "2022-10-31T07:26:24.439Z",
                //       ModifiedDate: "2022-10-31T07:26:24.439Z",
                //       IsActive: true
                //     }
                //   ],
                //   Username: "string",
                //   Password: "string",
                //   Email: "string",
                //   Token: "string",
                //   MobilePhone: "string",
                //   FCMToken: "string",
                //   DeviceType: 0,
                //   HasErrors: true,
                //   ResultMessages: [
                //     {
                //       MessageType: "string",
                //       Message: "string"
                //     }
                //   ],
                //   Id: 0,
                //   CreatedById: 0,
                //   CreatedBy: "string",
                //   LastUpdatedById: 0,
                //   LastUpdatedBy: "string",
                //   InsertDate: "2022-10-31T07:26:24.439Z",
                //   ModifiedDate: "2022-10-31T07:26:24.439Z",
                //   IsActive: true
                // }
                // here you have the access to the form data
                // values.first_name, values_last_name, values_email, values_password

                // console.log("I'm here inside post request");
                // let customer = new signup(
                //   values.fname,
                //   values.lname,
                //   false,
                //   false,
                //   values.password,
                //   false,
                //   "",
                //   values.email,
                //   values.mobile,
                //   false
                // );
                // let address = new addressModel();
                // let viewModel = {
                //   SignUp: customer,
                //   Address: address,
                // };
                // await this.props.saveSignUp(viewModel);
                // if (this.props.customer.ResultMessages.length > 0) {
                //   this.props.customer.ResultMessages.forEach((response) => {
                //     if (response.MessageType === "success") {
                //       this.state.isRegistered = true;
                //     } else if (response.MessageType === "danger") {
                //       this.state.isRegistered = false;
                //       toast.warning(response.Message);
                //       //return;
                //     }
                //   });
                // }
                // if (this.state.isRegistered) {
                //   if (
                //     this.props.customer.Password &&
                //     this.props.customer.MobilePhone
                //   ) {
                //     const signInModel = {
                //       UserName: "",
                //       Password: this.props.customer.Password,
                //       Email: "",
                //       Token: "",
                //       MobilePhone: this.props.customer.MobilePhone.substring(
                //         3,
                //         14
                //       ),
                //     };
                //     await this.props.fetchLoginDetails(signInModel);
                //     if (this.props.loginData.Id) {
                //       localStorage.setItem("Id", this.props.loginData.Id);
                //       localStorage.setItem("Token", this.props.loginData.Token);
                //       await this.props.setLoginDetails(
                //         localStorage.getItem("Id"),
                //         localStorage.getItem("Token")
                //       );
                //     } else {
                //       toast.error(`Phone number or password is Incorrect`);
                //       return;
                //     }

                //     if (this.props.isLogin) {
                //       toast.success(
                //         `You have been successfully logged in ${this.props.customerDetails.Customer.FirstName}`
                //       );
                //       console.log("login Status : ", this.props.isLogin);
                //       console.log("login data** ", this.props.loginData);

                //       var updatedPath = updateState(
                //         this.orderPageEnum.Address,
                //         this.props.isLogin
                //       );
                //       updatedPath == "Order"
                //         ? this.setState({ path: updatedPath })
                //         : updatedPath == "OrderAddress"
                //         ? this.setState({ path: updatedPath })
                //         : updatedPath == "TimeSlot"
                //         ? this.setState({ path: updatedPath })
                //         : updatedPath == "SignUp"
                //         ? this.setState({ path: updatedPath })
                //         : updatedPath == "OrderDetail"
                //         ? this.setState({ path: updatedPath })
                //         : this.setState({ path: "" });
                //     }
                //   }
                // }
                // console.log("back");


                // postData('https://dcpapi.ssasoft.com/api/SignUp/PostSignUp', viewModel)
                //     .then(data => {
                //         console.log(data); // JSON data parsed by `data.json()` call
                //         if(data.ResultMessages.length > 0)
                //         data.ResultMessages.forEach(response => {
                //             alert(response.Message);
                //             return;
                //         });

                //     });
              }}
            >
              {(props) => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                } = props;

                return (
                  <>
                  <div className='row heading_bar'>
                    <div className='wrapper'>
                      <h2>Enter your items</h2>
                    </div>
                  </div>
                    <div className="row">
                      <div className="wrapper progress_container">
                        <OrderProgressBar pageId={2} />
                      </div>
                    </div>
                    <div className="wrapper item_insertion clearfix">
                      <form
                        className="example"
                        name="signupfrm"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-sm-6 paddin_right field">
                            <label
                              id="name"
                              htmlfor="fname"
                              className="show-hide"
                            >
                              First Name<code style={{background:"#ffffff"}}>*</code>
                            </label>
                            <input
                              type="text"
                              name="fname"
                              value={values.fname}
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={20}
                              required
                            />
                            {errors.fname && touched.fname && (
                              <div className="errorMessage">{errors.fname}</div>
                            )}
                          </div>
                          <div className="col-sm-6 paddin_left field">
                            <label htmlfor="lname"
                            className="show-hide">
                              Last Name<code style={{background:"#ffffff"}}>*</code>
                            </label>
                            <input
                              type="text"
                              name="lname"
                              value={values.lname}
                              className="form-control"
                            
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={20}
                              required
                            />
                            {errors.lname && touched.lname && (
                              <div className="errorMessage">{errors.lname}</div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6 paddin_right field">
                            <label htmlfor="mobile" className="show-hide">
                              Mobile No.<code style={{background:"#ffffff"}}>*</code>
                            </label>
                            
                            <input
                              // value="+92"
                              type="tel"
                              name="mobile"
                              id="mobile"
                             
                              className="form-control dhobi_input"
                              value={values.mobile}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={11}
                              required
                            />
                            {errors.mobile && touched.mobile && (
                              <div className="errorMessage">
                                {errors.mobile}
                              </div>
                            )}
                          </div>
                          <div className="col-sm-6 paddin_left field">
                            <label htmlfor="email" className="show-hide">
                              Email<code style={{background:"#ffffff"}}>*</code>
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={30}
                              required
                            />
                            {errors.email && touched.email && (
                              <div className="errorMessage">{errors.email}</div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 paddin_right field">
                            <label htmlfor="password" className="show-hide">
                              Password<code style={{background:"#ffffff"}}>*</code>
                            </label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={16}
                              required
                            />
                            {errors.password && touched.password && (
                              <div className="errorMessage">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="col-sm-6 paddin_left field">
                            <label
                              ng-show="order.confirmpassword"
                              htmlfor="cpassword"
                              className="show-hide"
                            >
                              Confirm Password<code style={{background:"#ffffff"}}>*</code>
                            </label>
                            <input
                              type="password"
                              name="cpassword"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={16}
                              required
                            />
                            {errors.cpassword && touched.cpassword && (
                              <div className="errorMessage">
                                {errors.cpassword}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="row">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.back}
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary pull-right"
                              disabled={
                                errors.fname || values.fname === ""
                                  ? true
                                  : errors.lname || values.lname === ""
                                  ? true
                                  : errors.mobile || values.mobile === ""
                                  ? true
                                  : errors.email || values.email === ""
                                  ? true
                                  : errors.password || values.password === ""
                                  ? true
                                  : errors.cpassword || values.cpassword === ""
                                  ? true
                                  : false
                              }
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                );
              }}
            </Formik>
          </>
        ) : this.state.path == "OrderAddress" ? (
          <OrderAddress />
        ) : this.state.path == "TimeSlot" ? (
          <OrderTime />
        ) : this.state.path == "Order" ? (
          <OrderItem />
        ) : this.state.path == "OrderDetail" ? (
          <OrderDetail />
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapDispatchToProps = {
  saveSignUp,
  setSignUpData,
  fetchLoginDetails,
  setLoginDetails,
};

const mapStateToProps = ({ Auth }) => {
  let { loginData, isLogin, viewModel, saveSignUp, customer, customerDetails } =
    Auth;
  return {
    loginData,
    isLogin,
    viewModel,
    saveSignUp,
    customer,
    customerDetails,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
