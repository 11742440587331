import React, { Component } from 'react';
import { orderPageEnum } from '../models/globalUtilities';
import { connect } from 'react-redux';
import OrderItem from './OrderItem';
import ProgressBar from './ProgressBar';

class Order extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const { pageId } = this.props;
        return(
            <>
            {pageId === orderPageEnum.Items && <OrderItem/>}
            </>
        )
    }
}

const mapDispatchToProps = {};
const mapStateToProps = ({ Auth }) => {
    let { pageId } = Auth;
    return { pageId };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
