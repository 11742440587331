import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "./formStyles.css";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { setLoader } from "../../store/action/Auth";
import { baseUrl } from "../../Api";

const schema = Yup.object().shape({
  // name: Yup.string()
  //   .required("Name is Required.")
  //   .min(3, "Minimum 3 character are required"),

  // email: Yup.string()
  //   .email("Enter a valid a Email address")
  //   .required("Email is Required."),

  inquiry: Yup.string().required("Inquiry is required!"),

  comment: Yup.string()
    .required("Comment is required.")
    .min(2, "Please enter minimum 2 characters")
    .max(200, "Maximum 500 character are allowed"),
});

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      isSubmitted: false,
      formRequest: undefined,
    };
  }

  validateForm = (values, errors) => {
    var isValid = false;
    const { FirstName, LastName, Email } = this.props.customerDetails?.Customer;
    if ((FirstName && LastName && Email && values.inquiry != "" && !errors.inquiry, values.comment != "" && !errors.comment)) {
      isValid = false;
    } else isValid = true;

    return isValid;
  };

  render() {
    const postData = async (url = "", data = {}) => {
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data),
      });
      return response.json(); // parses JSON response into native JavaScript objects
    };

    return (
      <Formik
        initialValues={{
          name:
            //  this.props.customerDetails.Customer
            //   ? this.props.customerDetails?.Customer?.FirstName +
            //     " " +
            //     this.props.customerDetails?.Customer?.LastName
            //   :
            "",
          email: "",
          //this.props.customerDetails?.Customer?.Email,
          inquiry: "",
          comment: "",
        }}
        validationSchema={schema}
        // tell the formik to validate onBlur
        validateOnBlur
        onSubmit={async (values, actions) => {
          console.log("values**:", values);
          var viewModel = {
            ContactUs: {
              Name: this.props.customerDetails?.Customer.FirstName + " " + this.props.customerDetails?.Customer.LastName,
              Email: this.props.customerDetails?.Customer.Email,
              InquiryTypeId: parseInt(values.inquiry, 10),
              Comments: values.comment,
              InquriryTypeStr: null,
              HasErrors: false,
              ResultMessages: [],
              Id: 0,
              CreatedById: this.props.customerDetails?.Customer.Id,
              CreatedBy: null,
              LastUpdatedById: null,
              LastUpdatedBy: null,
              InsertDate: "2021-08-30T09:42:04.2838085Z",
              ModifiedDate: null,
              IsActive: true,
            },
            ContentManagement: {
              PageTitle: "Contact Information",
              PageName: "ContactUs",
              PageContents:
                "\r\n<p>We want to hear from you! At Lahore DryCleans, we welcome comments and feedback from our customers to ensure we continue to provide the high standard of quality service that our customers have come to expect from Lahore Drycleans. So let us know how we are doing. We'd love to hear from you!.</p>",
              HasErrors: false,
              ResultMessages: [],
              Id: 6,
              CreatedById: 0,
              CreatedBy: null,
              LastUpdatedById: null,
              LastUpdatedBy: null,
              InsertDate: "2021-08-30T09:42:04.2838085Z",
              ModifiedDate: "2015-09-23T06:46:11.457",
              IsActive: true,
            },
            InquiryTypes: [
              {
                Id: 1,
                Description: "Inquiry",
              },
              {
                Id: 2,
                Description: "Suggestion",
              },
              {
                Id: 3,
                Description: "Complaint",
              },
            ],
            Franchise: {
              FeedBackId: 1,
              Name: "Lahore Dry Cleaner",
              Description: "Lahore branch",
              Email: "LahoreDryClean@gmail.com",
              PhoneNo: "(316) 999-9999",
              Fax: "99999999999",
              CreatedBy: null,
              CreatedById: 1,
              Id: 1,
              InsertDate: "2021-08-27T15:52:35.0649987Z",
              IsActive: true,
              LastUpdatedBy: null,
              LastUpdatedById: null,
              ModifiedDate: "2016-11-10T10:22:14.057",
              ResultMessages: [],
              Address: {
                EntityId: 0,
                EntityTypeId: 0,
                AddressLine1: null,
                AddressLine2: null,
                CountryId: 0,
                Country: null,
                StateId: 0,
                State: null,
                CityId: 0,
                City: null,
                AreaId: 0,
                Area: null,
                Longitude: null,
                Latitude: null,
                Altitude: null,
                IsDefault: false,
                ZipCode: null,
                SortOrder: 1,
                CompleteAddress: "No default address available.",
                HasErrors: false,
                ResultMessages: [],
                Id: 0,
                CreatedById: 0,
                CreatedBy: null,
                LastUpdatedById: null,
                LastUpdatedBy: null,
                InsertDate: "2021-08-30T09:42:04.2838085Z",
                ModifiedDate: null,
                IsActive: true,
              },
            },
            FranchiseAddress: {
              EntityId: 1,
              EntityTypeId: 40,
              AddressLine1: "Model Town",
              AddressLine2: "H#52, G Block ",
              CountryId: 1,
              Country: "Canada",
              StateId: 1,
              State: "Ontario",
              CityId: 1,
              City: "Islamabad",
              AreaId: 1,
              Area: "Islamabad",
              Longitude: "74.32648689999996",
              Latitude: "31.485722",
              Altitude: "",
              IsDefault: true,
              ZipCode: "L5C 4B2",
              SortOrder: 0,
              CompleteAddress: "Model Town, H#52, G Block  ,Islamabad, Islamabad",
              HasErrors: false,
              ResultMessages: [],
              Id: 10041,
              CreatedById: 0,
              CreatedBy: null,
              LastUpdatedById: null,
              LastUpdatedBy: null,
              InsertDate: "2021-08-30T09:42:04.2993774Z",
              ModifiedDate: null,
              IsActive: true,
            },
            HasErrors: false,
            ResultMessages: [],
            Id: 0,
            CreatedById: this.props.customerDetails?.Customer.Id,
            CreatedBy: null,
            LastUpdatedById: null,
            LastUpdatedBy: null,
            InsertDate: "2021-08-30T09:42:04.2838085Z",
            ModifiedDate: null,
            IsActive: true,
          };
          this.props.setLoader(true);
          await postData(`https://${baseUrl}/api/ContactUs`, viewModel)
            .then(data => {
              this.props.setLoader(false);

              console.log("response**: ", data);
              if (data.ResultMessages[0].MessageType === "success") {
                switch (values.inquiry) {
                  case "1":
                    toast.success("Your Inquiry has been posted. ", data);
                    break;
                  case "2":
                    toast.success("Your Suggestion has been posted. ", data);
                    break;
                  case "3":
                    toast.success("Your Complaint has been posted. ", data);
                    break;
                  default:
                    toast("Unknown contact post");
                    break;
                }
              }
              values.comment = "";
              values.inquiry = "";

              //resetting form
              actions.resetForm();

              if (data.ResultMessages[0].MessageType === "danger") {
                toast.warning("Unable to Post your Inquiry.");
              }
              // JSON data parsed by `data.json()` call
            })
            .catch(error => {
              console.log("error**: ", error);
              // if(error!==undefined)
              actions.resetForm();
              toast.error("Error in sending your request. ", error);
            });
        }}
      >
        {props => {
          const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <input
                type='text'
                className='form-control'
                name='name'
                placeholder='Name'
                // value={values.name}
                value={
                  this.props.customerDetails !== null
                    ? this.props.customerDetails?.Customer?.FirstName + " " + this.props.customerDetails?.Customer?.LastName
                    : ""
                }
                // onChange={handleChange}
                // onBlur={handleBlur}
                // readOnly
                disabled
              />
              {errors.name && touched.name && <div className='errorMessage'>{errors.name}</div>}
              <input
                type='email'
                className='form-control'
                name='email'
                placeholder='Email'
                //value={values.email}
                value={this.props.customerDetails !== null ? this.props.customerDetails?.Customer?.Email : ""}
                // onChange={handleChange}
                // onBlur={handleBlur}
                // readOnly
                disabled
              />
              {errors.email && touched.email && <div className='errorMessage'>{errors.email}</div>}

              <select name='inquiry' value={values.inquiry} onChange={handleChange} onBlur={handleBlur} className='form-control'>
                <option value={""} disabled>
                  Select Inquiry Type{" "}
                </option>
                <option value={"1"}> Inquiry</option>
                <option value={"2"}> Suggestion</option>
                <option value={"3"}> Complaint</option>
              </select>
              {errors.inquiry && touched.inquiry && <div className='errorMessage'>{errors.inquiry}</div>}

              <textarea
                type='text'
                className='form-control'
                name='comment'
                placeholder='Message'
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={200}
              />
              {errors.comment && touched.comment && <div className='errorMessage'>{errors.comment}</div>}

              <button
                type='submit'
                className='btn blue-btn'
                disabled={
                  // errors.name || values.name === ""
                  this.props.customerDetails === null
                    ? true
                    : // : errors.email || values.email === ""
                    //:this.props.customerDetails?.Customer?.Email.length < 1
                    // ? true
                    errors.inquiry || values.inquiry === ""
                    ? true
                    : errors.comment || values.comment === ""
                    ? true
                    : false
                }
                // disabled={()=> this.validateForm(values, errors)}
              >
                Submit
              </button>
            </form>
          );
        }}
      </Formik>
    );
  }
}
const mapDispatchToProps = {
  setLoader,
};
const mapStateToProps = ({ Auth }) => {
  let { customerDetails } = Auth;
  return {
    customerDetails,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
