import React, { Component } from "react";
import Modal from "react-modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setTermsConditionsPopUp } from "../../store/action/Auth";
import "./TermsConditions.css";

const schema = Yup.object().shape({
  number: Yup.string()
    .required("Phone No is required.")
    .min(10, "Invalid Phone No.")
    .max(10, "Maximum 10 characters are allowed."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Minimum 8 character are required.")
    .max(15, "Maximum 16 characrter are allowed."),
});

class TermsConditions extends Component {
  constructor(props) {
    super(props);
  }

  closeTermsConditionModal = () => {
    this.props.setTermsConditionsPopUp(false);
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.termsConditionsPopup}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          //   id="ngdialog12"
          style={{}}
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document' style={{ width: "55%" }}>
            <div className='modal-header'>
              <h4 className='modal-title popup-title'>Terms & Conditions</h4>
            </div>
            <div className='modal-body'>
              <ul
                style={{
                  //   marginLeft: "50px",
                  paddingTop: "8px",
                  fontSize: "1rem",
                  //   listStyleType: "none",
                  textAlign: "left",
                }}
              >
                <li className='t_listItem '>The user may cancel an order before it is picked up, for any reason. Lavanderia reserves the right to cancel any orders for reasons they see fit.</li>
                <li className='t_listItem '>
                The customer assumes all risk for the cleaning of their items and promises to hold Lavanderia harmless from any and all damages that could occur in the normal cleaning process. The user's agreement to these terms is also agreement to Lavanderia's Risk Policy. Lavanderia is not responsible for any unclaimed items. Lavanderia utilizes the utmost care in processing items entrusted to them and use processes which Lavanderia believes to be the best suited to the nature and condition of each individual item. Nonetheless, Lavanderia cannot assume responsibility for inherent weakness or defects in materials that are not readily apparent prior to processing. This applies particularly, but not exclusively, to items made in part or in whole from suede, leather, silk, satin, double-faced fabrics, vinyl, polyurethane, or any other types of issues with items. Responsibility also is disclaimed for zippers, trimmings, buckles, beads, buttons, bells, and sequins. In laundering, Lavanderia cannot guarantee against color loss and/or shrinkage or against damage to weak and tender fabrics.
                </li>
                <li className='t_listItem '>Differences in item count must be reported, and the customer's invoice presented within 48 hours. Unless a list accompanied the bundle, Lavanderia's count is final. Lavanderia is not responsible for orders or items that are missing once they are delivered.</li>
                <li className='t_listItem '>
                Lavanderia offers a onetime complimentary do over for all customers that are dissatisfied with the outcome of their garment(s). Do overs will be accepted free of charge if the garment(s) has been processed within 10 days of the original order date. The original tag is required to still be attached to within 10 days of the original order date and to qualify for a redo. Lavanderia reserves the right to reject any garment(s) to be redone. Garments that have been worn after cleaning are not eligible for a redo.
                </li>
                <li className='t_listItem '>&nbsp;Lavanderia and customers respects the privacy of users of the Lavanderia website and application. This privacy policy is an agreement between Lavanderia and the user, as the user of Lavanderia's website or application. This agreement tells the user when the policy is effective, what information is collected from the user, how this information is used, how the user can review or change the information given, how this policy may change, and the steps taken to ensure that the users information is protected.</li>
                <li className='t_listItem '>
                  &nbsp;All activities in which the user may engage on the Lavanderia website or application are voluntary. The user is not required to provide any information to Lavanderia unless the user chooses to access features on the Lavanderia website or application that requires this information. If the user does not agree with the terms of this policy or other terms of the Lavanderia website or application, the user should immediately exit the Lavanderia website or application and discontinue use. By using the Lavanderia website or application, the user signifies that they agree to the terms of this privacy policy as well as Lavanderia's terms of use.
                </li>
                <li className='t_listItem '>
                This privacy policy if effective as of January 1, 2022, and will remain in effect except with respect to any of its provisions that are changed in the future, in which case the changes will become effective on the date they are posted on Lavanderia's website and application or the user is otherwise notified of the changes. Lavanderia reserves the right to change this privacy policy at any time. Changes, modifications, additions, or deletions will be effective immediately when posted to the Lavanderia website and application. The user should check this policy periodically as its terms are subject to change. The users continued use of the Lavanderia website or application after any such modifications are posted will constitute the user's acknowledgement of the modified policy and their agreement to abide and be bound by the modified policy. The “last updated" date found at the beginning of this privacy policy will be updated when changes are posted.
                </li>
                <li className='t_listItem '>
                The privacy policy does not extend to anything that is inherent in the operation of the Internet, and therefore beyond Lavanderia's control, and is not to be applied in any manner contrary to applicable law or governmental regulation. This online privacy policy does not apply to any information collected from the user in any place other than the Lavanderia website or application.
                </li>
                <li className='t_listItem '>
                The user may be asked to provide certain information on the Lavanderia website or application known as “personal information / details.” This includes information that pertains to the user's identity. Such information may include, but is not limited to, items such as the users name, address, date of birth, age, gender, telephone number, e-mail address, and/or a credit or debit card number. The user may be required to provide this information to access or use certain parts or features of the Lavanderia website or application. If the user does not provide the requested information, the user may not be able to access or use the features of the Lavanderia website or application.
                </li>
                <li className='t_listItem '>
                There may be other instances in which information is collected from the user that would be considered “general information." Such information may include the users Internet Protocol (IP) address, which enables Lavanderia to identify the user's computer or device on a Transmission Control Protocol/Internet Protocol (TCP/IP) network, which includes the World Wide Web. The user's computer has an IP address which is required in order for the user to communicate with others via the Internet. Such information, including the click stream data cookies, as mentioned below, may be collected from the user whenever the Lavanderia website or application is accessed, whether or not any "personal information” is given.
                </li>
                <li className='t_listItem '>
                When the user visits the Lavanderia website or application, “click stream data" (server address, domain name) may be collected. This information can be combined with information the user has provided which enables Lavanderia to analyze and customize the user's visits in a better manner. This data may be used for traffic analysis or e-commerce analysis for Lavanderia's website and application to determine which features are most and least effective or useful.
                </li>
                <li className='t_listItem '>Cookies may be used to gather information about the user's visits to Lavanderia's website or application to enhance the user's experience. More information about the use of cookies can be found in Section 14. C. “DO NOT TRACK” DISCLOSURES. Lavanderia is strongly committed to not sharing nonpublic information with third parties. Under the Privacy Policy, Lavanderia may share the user's information only under particular situations, as described in this policy. Since Lavanderia protects all users in this manner, Lavanderia does not change their behavior in response to a web browser's “do not track" signal.</li>
                <li className='t_listItem '>
                Lavanderia will primarily use the provided personal and general information to accommodate products and services to the user, including, but not limited to, the following:
                </li>
                <li className='t_listItem '>
                A. To administer a content promotion survey or other site and to provide the user with the relevant products or services.
                </li>
                <li className='t_listItem '>
                B. To send the user information or materials via e-mail or text message about products, offers, and news that may be of interest to the user. This information may relate to the user. This information may relate to products, offers, and news of Lavanderia or its licensees or selected commercial partners. If the user no longer wishes to receive e-mails or text messages from Lavanderia, or if the user wants to modify or delete their personal data on the Lavanderia database, see section 15.
                </li>
                <li className='t_listItem '>
                C. To analyze visits to the Lavanderia website or application and learn about the interests of our users in aggregate and also on a personal level to better understand the user's interests and needs, so Lavanderia can improve their products and services and deliver to the user the type of content, features, and promotions that the user is most interested in.
                </li>
                <li className='t_listItem '>Lavanderia will primarily use the provided personal and general information to accommodate products and services to the user, including, but not limited to, the following:</li>
                <li className='t_listItem '>
                A. SECURITY MEASURES In order to protect both the personal and general information that is received from the user through our website or application, various security measures have been implemented.
                </li>
                <li className='t_listItem '>
                B. RISK OF INTERCEPTION
                </li>
                <li className='t_listItem '>
                Please keep in mind, however, that whenever the user gives out information online there is a risk that third parties may intercept and use this information. While Lavanderia strives to protect the user's personal information and privacy, it cannot be guaranteed the security of any information disclosed online. By using Lavanderia's website or application, the user expressly acknowledges and agrees that Lavanderia does not guarantee the security of any data provided to or received by the user through the Lavanderia website or application and that any of this information is provided at the user's own risk, which you expressly assume.
                </li>
                <li className='t_listItem '>
                A. Registration Declined
                </li>
                <li className='t_listItem '>
                Lavanderia intends that only persons who are 18 years or older may use the Lavanderia website or application. Personal registration information submitted by a person under the age of 18 will not be accepted. Any information provided by a person under the age of 18 and gathered during said persons visit may be used as indicated in this policy found in Section 9. Lavanderia is committed to protecting the privacy of children and has no intentions indicated in this policy found in Section 9 and has no intentions of collecting data from persons under the age of 18.
                </li>
                <li className='t_listItem '>
                B. Notice Concerning Minors
                </li>
                <li className='t_listItem '>
                PLEASE NOTE: Lavanderia is a general audience website and application and do not direct any of their content specifically to persons under the age of 13 years old. Lavanderia understands and is committed to respecting the sensitive nature of persons privacy online. If Lavanderia learns or has reason to suspect that a user is under the age of 13, Lavanderia will promptly delete any information provided in that user's account.
                </li>
                <li className='t_listItem '>
                THIRD PARTIES
                </li>
                <li className='t_listItem '>
                A. Sharing Information with Persons
                </li>
                <li className='t_listItem '>
                OR ENTITIES WHO MANAGE LAVANDERIA'S WEBSITE AND APPLICATION Lavanderia's global and local websites and applications and the information collected are managed and controlled by Browser SSASOFT in Lahore. If the user is located outside of Lahore, please note that the information provided will be transferred to Browser SSASOFT, and by submitting this information the user authorizes this transfer. Browser SSASOFT has entered into an agreement with Lavanderia in location to provide safeguards for the protection of the user's data that are consistent with this policy. 
                </li>
                <li className='t_listItem '>
                B. Disclosing Information to Third Parties Other than to the companies belonging to Lavanderia for the purposes set out above, and except as described in this policy, information is not sold, traded, or otherwise transferred to third parties without informing the user in advance. Lavanderia provides some of their services through contractual arrangements with affiliates, service providers, partners, and other third parties. Lavanderia's service partners may use any information given by the user to operate Lavanderia's website and application and to deliver their services. This data may be transferred to website and application hosting partners and other parties who assist Lavanderia in designing and operating the website and application, executing services, or helping Lavanderia analyze collected data. These parties will only use data for the purpose of carrying out the work as agreed with Lavanderia and will be required to keep the information confidential. Lavanderia will encourage their service partners to adopt and post privacy policies. However, the use of information by Lavanderia's service partners is governed by the privacy policies of those service partners and is not subject to Lavanderia's control. Please see section 9.C on how Lavanderia will respond to “do not track” signals.
                </li>
                <li className='t_listItem '>
                C. Release of Information Lavanderia will release information without the user's consent for security purposes, when required by law, or to prevent imminent harm to any person or entity. Lavanderia will disclose information upon receipt of a court order or subpoena, or to cooperate with a law enforcement investigation which may include responding to requests and court orders from jurisdictions outside the Pakistan. Lavanderia will fully cooperate with law enforcement agencies in identifying the users of Lavanderia's services for illegal activities. Lavanderia reserves the right to report to law enforcement agencies any activities that Lavanderia, in good faith, believe to be unlawful, as determined in this agreement to any person or entity under any circumstances shall be based on a determination made solely by Lavanderia, exercising their own discretion, permission for which is expressly granted by the user to Lavanderia in accordance with this policy
                </li>
                <li className='t_listItem '>
                D. Links to Third Parties For the convenience of the user and to improve the usage of the website and application Lavanderia may insert links to third parties on the website and application. This privacy policy does not apply to such third-party websites or applications. These links take the user outside Lavanderia's service and off the website or application and are beyond Lavanderia's control. This includes links from partners that may use Lavanderia’s website or application and are beyond Lavanderia's control. This includes links from partners that may use Lavanderia's logos as part of a co-branding agreement. The sites those links can take the user to have their own separate privacy policy and although Lavanderia seeks to protect the integrity of their website and application, they are not liable for the content and activities of those sites. The users visit and access to such websites is at the user's risk. Please note that those other sites may send their own cookies to users, collect data, or solicit information.
                </li>
                <li className='t_listItem '>
                AUTOMATIC COLLECTION
                </li>
                <li className='t_listItem '>
                A cookie is a small string of information that the website or application the user visits transfers to their computer for identification purposes. Cookies can be used to follow the user's activity on the website or application and that information helps Lavanderia to understand the user's preferences and improve the user's website or application experience. Cookies are also used for such activities as remembering the user's username and password, if the user uses such a feature on the website or application. Cookies can be turned off in case the user prefers not to receive them. The user's computer can also warn the user whenever cookies are being used. For both options, the user has to adjust their browser settings. There are also software products available that can manage cookies for the user. Please be aware, however, that when it is chosen to reject cookies, this may limit the functionality of the website or application and the user may lose access to some features.
                </li>
                <li className='t_listItem '>
                DELETING OR MODIFYING INFORMATION
                </li>
                <li className='t_listItem '>
                The user may cancel their registration at any time, and they may opt out of receiving any emails or text messages from Lavanderia. Additionally, the user can choose to have all their information deleted from Lavanderia's database. The user may opt out, delete, or modify their information on the Lavanderia registration page on the website or application and following the instructions provided. It is encouraged that the user promptly updates any information when it changes.
                </li>
                <li className='t_listItem '>
                QUESTIONS OR COMMENTS
                </li>
                <li className='t_listItem '>
                If the user has any questions or comments relating to the Lavanderia website or application or their privacy policy, send an email to services@lavanderia.pk.
                </li>
              </ul>
            </div>
            <div className='ngdialog-close' onClick={this.closeTermsConditionModal}></div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  setTermsConditionsPopUp,
};

const mapStateToProps = ({ Auth }) => {
  let { termsConditionsPopup } = Auth;
  return {
    termsConditionsPopup,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
