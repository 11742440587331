import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAboutUsDetails, setLoader } from "../store/action/Auth";

class AboutUs extends Component {
  static displayName = AboutUs.name;

  constructor(props) {
    super(props);
  }
  async componentDidMount() {
    this.props.setLoader(true);
    await this.props.fetchAboutUsDetails();
    this.props.setLoader(false);
  }

  render() {
    return (
      <>
        {/* {this.props.aboutUsData.Page ? ( */}
          <>
            <div className="row heading_banner">
              <img src="faq_banner.jpg" alt="" />
            </div>
            <div className="row">
              <div className="wrapper clearfix items_wrap faq_wrap">
                {/* {this.props.aboutUsData ? ( */}
                  {/* <h3> {this.props.aboutUsData.Page.PageTitle} </h3> */}
                  <h3> About Us </h3>
                {/* ) : ( */}
                  {/* <h3></h3> */}
                {/* )} */}
                {/* {this.props.aboutUsData ? ( */}
                  <div style={{ fontSize: "medium" }}>
                    <div
                      // dangerouslySetInnerHTML={{
                      //   __html: this.props.aboutUsData.Page.PageContents,
                      // }}
                    >
                      <h4 style={{fontWeight: '600'}}>Reinventing the Future of Laundry and Dry Cleaning</h4>
                      <p>Lavanderia.com delivers the quickest, easiest to use, and most reliable proficient laundry and dry cleaning service that completely adjusts to your needs.
                          We collect, clean, and deliver your laundry to your doorstep. When and where you need us, we will be there. 
                          Our machines are clean, modern and efficient. We are equipped with state of the art steam press system. You won’t get that kind of white-glove treatment from any other laundry service in the area. If you want the best, you want Lavanderia.
                      </p>
                      <h4 style={{fontWeight: '600'}}>Who we are?</h4>
                      <p>Lavanderia.com Private Limited founded in 2019, after registered with Securities & Exchange Commission 
                        of Pakistan (SECP), Corporate Unique Identification No. 0142988. Lavanderia.com is the next generation 
                        laundry & dry cleaning Company. We offer proficient laundry and dry cleaning delivered to your doorstep as per schedule.
                      </p>
                      <h4 style={{fontWeight: '600'}}>We Protect Our Environment</h4>
                      <p>Social and environmental sustainability 
                        is at the heart of what we do. We are using eco-friendly detergent and efficient water use.
                      </p>
                      <h4 style={{fontWeight: '600'}}>Committed to Making Things Easy for You</h4>
                      <p>Managing your laundry and dry cleaning in the 21st century should be simple and accessible 
                        from anywhere. We created the app that allows you to schedule an order in less than 2 minutes,
                         whether at home, at the office or on the go. No need to speak to any representative, 
                         you can now manage all your orders with our easy-to-use website or mobile app.
                      </p>
                      <h4 style={{fontWeight: '600'}}>Quality without Compromise</h4>
                      <p>We never compromise when it comes to quality. Our staff is well trained and certified from 
                        recognized institute. Quality is core of our heart. We believe that <span style={{fontWeight: '600'}}>Growth, Sustainability 
                        and Expansion are integral to strong customer relationships.</span>
                      </p>
                      </div>

                  </div>
                {/* ) : ( */}
                  {/* <div style={{ fontSize: "medium" }}>
                    <div> </div>
                  </div> */}
                {/* )} */}
              </div>
            </div>
          </>
        {/* ) : (
          <>
            <div className="row heading_banner">
              <img src="faq_banner.jpg" alt="" />
            </div>
          </>
        )} */}
      </>
    );
  }
}

const mapDispatchToProps = { fetchAboutUsDetails, setLoader };
const mapStateToProps = ({ Auth }) => {
  let { data, aboutUsData } = Auth;
  return { data, aboutUsData };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
