import React, { Component } from "react";
import { connect } from "react-redux";

class OrderSummary extends Component {
  render() {
    return (
      <>
        <>
          <div className="row heading_bar">
            <div className="wrapper">
              <h2>Order Summary</h2>
            </div>
          </div>

          <div className="row order_summary">
            <div className="wrapper">
              <div className="col-lg-4 col-sm-6 order_s_colum">
                <div className="order_colum">
                  <h4>
                    <i className="ico_pickup"></i> Pick-up{" "}
                    <span>
                      {" "}
                      {new Date(this.props.orderSummary?.Order?.PickupDateTime).toDateString()
                      // .substr(
                      //   0,
                      //   this.props.orderSummary?.Order?.PickupDateTime?.indexOf(
                      //     "T"
                      //   )
                      }{" "}
                      <br />{" "}
                      <small className="selectedTime">
                        {/* {this.props.orderSummary?.Order?.PickupSelectedTime} */}
                        04:00 PM - 09:00 PM
                      </small>
                    </span>
                  </h4>
                </div>
              </div>

              <div className="col-lg-4 col-sm-4 order_s_colum">
                <div className="order_colum qty_col">
                  <h4>
                    {" "}
                    <i className="ico_items"></i> Clothes{" "}
                  </h4>
                  <span>{this.props.orderSummary?.Order?.NoOfItems}</span>
                </div>
              </div>

              <div class="col-lg-4 col-sm-4 order_s_colum">
                <div class="order_colum qty_col">
                  <h4>
                    {" "}
                    <i class="ico_items"></i> Delivery Time{" "}
                  </h4>
                  <span>
                  {new Date(this.props.orderSummary?.Order?.DeliveryDateTime).toDateString()}
                        <br />
                          <small className="selectedTime">
                            {/* {
                              this.props?.selectedPickUpSchedule?.PickupSelectedTime
                            } */}
                            04:00 PM - 09:00 PM
                          </small>
                    {/* {this.props.orderSummary?.Order?.DeliveryTimeId === 10
                      ? "Same Day"
                      : this.props.orderSummary?.Order?.DeliveryTimeId == 20
                      ? "Next Day"
                      : "Regular"} */}
                  </span>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="order_colum">
                    <h4>
                      {" "}
                      <i className="ico_instruc"></i>Special Instructions{" "}
                    </h4>
                    {this.props.orderSummary?.Order?.Instructions ? (
                      <p>{this.props.orderSummary?.Order?.Instructions}</p>
                    ) : (
                      <p>No Special Instructions</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="order_colum">
                    <h4>
                      {" "}
                      <i className="ico_address"></i>Pickup & Delivery Address
                    </h4>
                    <h6>
                      {this.props.orderSummary?.Order?.Address?.CompleteAddress}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      this.props.history.push("/CustomerOrders");
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { orderSummary } = Auth;

  return {
    orderSummary,
  };
};

export default connect(mapStateToProps)(OrderSummary);
