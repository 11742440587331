import React, { Component } from "react";
import { Route } from "react-router";
import { connect } from "react-redux";
import Layout from "./components/Layout";
import { Home } from "./components/Home/Home.js";
import Pricing from "./components/Pricing";
import { ContactUs } from "./components/ContactUs";
import { CoverageArea } from "./components/CoverageArea";
import AboutUs from "./components/AboutUs";
import { Terms } from "./components/Terms";
import OrderItem from "./components/Order/OrderItem";
import OrderAddress from "./components/Order/OrderAddress";
import SignUp from "./components/Account/SignUp";
import OrderTime from "./components/Order/OrderTime";
import OrderDetail from "./components/Order/OrderDetail";
import Profile from "./components/Profile";
import EditProfile from "./components/Account/EditProfile";
import ChangePassword from "./components/Account/ChangePassword";
import CustomerOrders from "./components/CustomerOrders";
import OrderSummary from "./components/OrderSummary";
import NOrder from "./components/Order/OrderSequence";
import { fetchPricingDetails, fetchAboutUsDetails, setLoginDetails, setOrderDetails, setLoader } from "./store/action/Auth";
import ResetPassword from "./components/ResetPassword";

class App extends Component {
  static displayName = App.name;

  async componentDidMount() {
    const windowLocation = window.location.href;
    let passwordPath = windowLocation.substr(windowLocation.lastIndexOf("#") + 1);

    if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 14) == "/ResetPassword") {
      this.props.history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 10) == "/ContactUs") {
      this.props.history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 6) == "/Terms") {
      this.props.history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 8) == "/AboutUs") {
      this.props.history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 13) == "/CoverageArea") {
      this.props.history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 8) == "/Pricing") {
      this.props.history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 6) == "/Order") {
      this.props.history.push(passwordPath);
    } else {
      this.props.history.push("/");
    }

    this.props.setLoader(true);
    await this.props.fetchPricingDetails();
    console.log("in App**: ", this.props.data);
    if (localStorage.getItem("Id") && localStorage.getItem("Token")) {
      await this.props.setLoginDetails(localStorage.getItem("Id"), localStorage.getItem("Token"));
      await this.props.setOrderDetails(localStorage.getItem("Token"));
      console.log("customerDetails in App.js : ", this.props.customerDetails);
    }
    this.props.setLoader(false);
  }

  render() {
    return (
      <Layout history={this.props.history}>
        <Route exact path='/' component={OrderItem} />
        {/* <Route path='/ContactUs' component={ContactUs} />
        <Route path='/CoverageArea' component={CoverageArea} />
        <Route path='/Terms' component={Terms} />
        <Route path='/AboutUs' component={AboutUs} />
        <Route path='/Pricing' component={Pricing} /> */}
        <Route path='/Order' component={OrderItem} />
        <Route path='/Order/Address' component={OrderAddress} />
        <Route path='/Account/Sign' component={SignUp} />
        <Route path='/Order/TimeSlot' component={OrderTime} />
        <Route path='/OrderDetail' component={OrderDetail} />
        <Route path='/Profile' component={Profile} />
        <Route path='/EditProfile' component={EditProfile} />
        <Route path='/ChangePassword' component={ChangePassword} />
        <Route path='/CustomerOrders' component={CustomerOrders} />
        <Route path='/OrderSummary' component={OrderSummary} />
        <Route path='/ResetPassword' component={ResetPassword} />
        {/* <Route path='*' component={OrderItem} /> */}

        {/* <Route path="/N-Order" component={NOrder} /> */}
      </Layout>
    );
  }
}

const mapDispatchToProps = {
  fetchPricingDetails,
  fetchAboutUsDetails,
  setLoginDetails,
  setOrderDetails,
  setLoader,
};

const mapStateToProps = ({ Auth }) => {
  let { data, aboutUsData, customerDetails } = Auth;
  return { data, aboutUsData, customerDetails };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
