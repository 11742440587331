import React, { Component } from "react";
import WOW from "wowjs";

export class OrderProcess extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="row order_process">
        <div className="wrapper">
          <div className="colum_row">
            <div className="col-lg-3 col-md-6 col-sm-6 order_process_colum ">
              <div className="process_circle">
                <div className="wow bounceIn">
                  <i className="order_ico"></i>
                </div>
              </div>
              <br />
              <br />
              <h2>Your Order</h2>
              <p>
              lavanderia.com is founded on the belief that time should not be
                wasted in doing laundry.{" "}
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 order_process_colum ">
              <div className="process_circle">
                <div className="wow bounceIn">
                  <i className="pick_ico"></i>
                </div>
              </div>
              <br />
              <br />
              <h2>We Pick</h2>
              <p>
              lavanderia.com is founded on the belief that time should not be
                wasted in doing laundry.{" "}
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 order_process_colum">
              <div className="process_circle">
                <div className="wow bounceIn">
                  <i className="wash_ico"></i>
                </div>
              </div>
              <br />
              <br />
              <h2>We Wash</h2>
              <p>
              lavanderia.com is founded on the belief that time should not be
                wasted in doing laundry.{" "}
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 order_process_colum">
              <div className="process_circle">
                <div className="wow bounceIn">
                  <i className="delvi_ico"></i>
                </div>
              </div>
              <br />
              <br />
              <h2>We Deliver</h2>
              <p>
              lavanderia.com is founded on the belief that time should not be
                wasted in doing laundry.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
