import React, { Component } from "react";
import { orderPageEnum, updateState } from "./models/globalUtilities";
import { OrderProgressBar } from "./OrderProgressBar";
import { toast } from "react-toastify";
import OrderAddress from "./OrderAddress";
import OrderTime from "./OrderTime";
import SignUp from "../Account/SignUp";
import OrderDetail from "./OrderDetail";
import { connect } from "react-redux";
import { setOrderItems, fetchAddressDetails, setLoader } from "../../store/action/Auth";
import { Formik } from "formik";
import * as Yup from "yup";

const numberRegex = RegExp(/^-?[1-9]+$/);
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && val != "" && (valid = false));
  return valid;
};

const schema = Yup.object().shape({
  NoOfItems: Yup.string().required("No of items are required."),
});
class OrderItem extends Component {
  orderPageEnum = orderPageEnum;
  constructor(props) {
    super(props);
    this.state = {
      path: "Order",
      errors: null,
    };
  }
  async componentDidMount() {
    this.props.setLoader(true);
    await this.props.fetchAddressDetails();
    this.props.setLoader(false);
    console.log("orderObject : ", this.props.orderObject);
  }
  updateCart = event => {
    event.preventDefault();
    console.log(this.props.viewModel.OrderItem.NoOfItems, this.props.specialInstruction);
    if (validateForm(this.state.errors)) {
      console.info("Valid Form");
      var updatedPath = updateState(this.orderPageEnum.Signup);
      updatedPath == "Order"
        ? this.setState({ path: updatedPath })
        : updatedPath == "OrderAddress"
        ? this.setState({ path: updatedPath })
        : updatedPath == "TimeSlot"
        ? this.setState({ path: updatedPath })
        : updatedPath == "SignUp"
        ? this.setState({ path: updatedPath })
        : updatedPath == "OrderDetail"
        ? this.setState({ path: updatedPath })
        : this.setState({ path: "" });
    } else {
      console.error("Invalid Form");
    }
  };
  formChangeHandler = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "NoOfItems":
        this.props.setOrderItems(value);
        errors.NoOfItems = numberRegex.test(value)
          ? value < 0 || value > 9999
            ? " Value should be between 1 and 9999."
            : ""
          : "decimal value not allowed";
        break;
      case "specialInstruction":
        errors.specialInstruction = value.length > 500 ? "Instruction limit is 0 to 500" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };
  render() {
    return (
      <>
        {this.state.path == "Order" ? (
          <>
            <Formik
              initialValues={{
                NoOfItems: this.props.viewModel == null ? 1 : this.props.viewModel.OrderItem.NoOfItems,
                specialInstruction: this.props.viewModel == null ? null : this.props.viewModel.OrderItem.specialInstruction,
              }}
              validationSchema={schema}
              // tell the formik to validate onBlur
              validateOnBlur
              onSubmit={values => {
                console.log("I'm here inside post request");
                let viewModel = {
                  OrderItem: {
                    NoOfItems: values.NoOfItems,
                    specialInstruction: values.specialInstruction,
                  },
                };
                this.props.setOrderItems(viewModel);

                console.log(this.props.viewModel.OrderItem.NoOfItems, this.props.viewModel.OrderItem.specialInstruction);
                var updatedPath = updateState(this.orderPageEnum.Signup, this.props.isLogin);
                updatedPath == "Order"
                  ? this.setState({ path: updatedPath })
                  : updatedPath == "OrderAddress"
                  ? this.setState({ path: updatedPath })
                  : updatedPath == "TimeSlot"
                  ? this.setState({ path: updatedPath })
                  : updatedPath == "SignUp"
                  ? (toast.info("please signup or login to continue"))
                  : updatedPath == "OrderDetail"
                  ? this.setState({ path: updatedPath })
                  : this.setState({ path: "" });
              }}
            >
              {props => {
                const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;
                return (
                  <>
                    <div className='row heading_bar'>
                      <div className='wrapper'>
                        <h2>Enter your Clothes</h2>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='wrapper progress_container'>
                        <OrderProgressBar pageId={1} orderPageEnum={orderPageEnum} />
                      </div>
                    </div>

                    <div className='wrapper item_insertion clearfix'>
                      <form noValidate onSubmit={handleSubmit}>
                        <div className='col-sm-6 paddin_right field'>
                          <label htmlfor='numberofitem' className='show-hide'>
                            No. of Clothes<code style={{backgroundColor:"#ffffff"}}><b>*</b></code>
                          </label>
                          <input
                            type='number'
                            name='NoOfItems'
                            value={values.NoOfItems}
                            className='form-control'
                            onChange={e => {
                              if (e.currentTarget.value >= 1 && e.currentTarget.value <= 1000) handleChange(e);
                            }}
                            onBlur={handleBlur}
                            noValidate
                            required
                          />
                          {errors.NoOfItems && touched.NoOfItems && <div className='errorMessage'>{errors.NoOfItems}</div>}
                        </div>

                        <div className='col-sm-12 '>
                          <div className='row field'>
                            <label className='control-label show-hide' htmlfor='specialinstruction'>
                              Special Instructions<code style={{backgroundColor:"#ffffff"}}><b>*</b></code>
                            </label>
                            <textarea
                              type='text'
                              name='specialInstruction'
                              value={values.specialInstruction}
                              onChange={e => {
                                if (e.currentTarget.value.length <= 100) handleChange(e);
                              }}
                              onBlur={handleBlur}
                              className='form-control margin-bottom-30'
                              noValidate
                            ></textarea>
                          </div>
                        </div>

                        <div className='col-sm-12'>
                          <button
                            type='submit'
                            className='btn btn-primary pull-right'
                            disabled={values.NoOfItems === 0 || values.NoOfItems === ""}
                          >
                            Continue
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                );
              }}
            </Formik>
          </>
        ) : this.state.path == "OrderAddress" ? (
          <OrderAddress />
        ) : this.state.path == "TimeSlot" ? (
          <OrderTime />
        ) : this.state.path == "SignUp" ? (
          <SignUp />
        ) : this.state.path == "OrderDetail" ? (
          <OrderDetail />
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = { setOrderItems, fetchAddressDetails, setLoader };

const mapStateToProps = ({ Auth }) => {
  //let { customerDetails, profileUploaderLength, profileUploader, profileItemUploading, profileUploaderProgress } = Auth;
  //return { customerDetails , profileUploaderLength, profileUploader, profileItemUploading, profileUploaderProgress };
  let { viewModel, isLogin, orderObject } = Auth;
  return { viewModel, isLogin, orderObject };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
