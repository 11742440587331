import React, { Component } from "react";
import { OrderProgressBar } from "./OrderProgressBar";
import "../../css/deliveryTime.css";
import OrderAddress from "./OrderAddress";
import SignUp from "../Account/SignUp";
import OrderItem from "./OrderItem";
import OrderDetail from "./OrderDetail";
import { orderPageEnum, updateState } from "./models/globalUtilities";

import { connect } from "react-redux";
import {
  fetchPickUpSchedule,
  fetchDropSchedule,
  setDeliveryTimeId,
  setSelectedPickUpSchedule,
  setLoader,
  fetchPickUpSchedule_proto,
  setSelectedPickupDate_proto,
  setSelectedPickupTimeSlotId_proto,
  fetchOrderScheduleByAddressId,
} from "../../store/action/Auth";
class OrderTime extends Component {
  // timeSlotType = "Pickup";
  // date = "2021-08-16";
  constructor(props) {
    super(props);
    var today = new Date();
    var currentDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

    this.state = {
      path: "TimeSlot",
      timeSlotType: "Pickup",
      date: currentDate,
      viewModel: {
        WeekSchedules: [],
        FirstAvailableDate: "",
        FirstAvailableDay: 0,
        FirstAvailableTimeSlotId: 0,
        FirstAvailableTimeSlotLabel: "",
        ServerDateTime: "",
        StartDate: "",
        TimeSlotType: null,
      },
      timeSlot: {
        PickUpDay: 0,
        PickupSlotId: 0,
        PickupUniqSlotId: 0, //PickTimeSlotId
        DropDay: 0,
        DropSlotId: 0,
        PickupSelectedTime: "",
        PickupSelectedDate: "", //PickupDateTime
        DropSelectedTime: "",
        DeliveryDateTime: "",
      },
      IsCartEmpty: true,
    };
  }

  componentCallback = async(isCallable) => {
    if (this.props.pickUpSchedule == null) {
      this.props.setLoader(true);
      // await this.props.fetchPickUpSchedule(this.state.timeSlotType, this.state.date);
      // await this.props.fetchPickUpSchedule_proto(16, "Pickup");
      await this.props.fetchOrderScheduleByAddressId(
        this.state.timeSlotType,
        this.state.date,
        this.props.completeAddress?.AreaId,
        localStorage.getItem("Token")
      );
      this.props.setLoader(false);
    }

    if (this.props.pickUpSchedule != null) {
      if(isCallable){
        this.props.setLoader(true);
        // await this.props.fetchPickUpSchedule(this.state.timeSlotType, this.state.date);
        // await this.props.fetchPickUpSchedule_proto(16, "Pickup");
        await this.props.fetchOrderScheduleByAddressId(
          this.state.timeSlotType,
          this.state.date,
          this.props.completeAddress?.AreaId,
          localStorage.getItem("Token")
        );
        this.props.setLoader(false);
      }
      let pickUpSchedule;
      if (this.props.selectedPickUpSchedule == null) {
        pickUpSchedule = {
          PickupSelectedDate: this.formatDate(this.props.pickUpSchedule?.WeekSchedules[0]?.Date),
          PickupSelectedTime:
            this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.StartTime +
            " " +
            this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.EndTime,
          PickupSelectedSlotId: this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.UniqSlotId,
          PickupDay: this.props.pickUpSchedule?.WeekSchedules[0]?.Day,
        };
      } else {
        // debugger;
        if(this.props.selectedPickUpSchedule?.PickupSelectedDate != "Invalid Date"){
          pickUpSchedule = {
            PickupSelectedDate: this.props.selectedPickUpSchedule?.PickupSelectedDate,
            PickupSelectedTime: this.props.selectedPickUpSchedule?.PickupSelectedTime,
            PickupSelectedSlotId: this.props.selectedPickUpSchedule?.PickupSelectedSlotId,
            PickupDay: this.props.selectedPickUpSchedule?.PickupDay,
          };
        }
        else {
          pickUpSchedule = {
            PickupSelectedDate: this.formatDate(this.props.pickUpSchedule?.WeekSchedules[0]?.Date),
            PickupSelectedTime:
            this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.StartTime +
            " " +
            this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.EndTime,
            PickupSelectedSlotId: this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.UniqSlotId,
            PickupDay: this.props.pickUpSchedule?.WeekSchedules[0]?.Day,
          };
        }
        
      }

      //setting pickup schedule
      this.props.setSelectedPickUpSchedule(pickUpSchedule);
      // debugger;
      // this.setState({
      //   ...(this.state.timeSlot.PickupSelectedDate = this.formatDate(this.props.pickUpSchedule.FirstAvailableDate)),
      // });
      // this.setState({
      //   ...(this.state.timeSlot.PickUpDay = this.props.pickUpSchedule.FirstAvailableDay),
      // });
      // this.setState({
      //   ...(this.state.timeSlot.PickupSlotId = this.props.pickUpSchedule.FirstAvailableTimeSlotId),
      // });
      // this.setState({
      //   ...(this.state.timeSlot.PickupSelectedTime = this.props.pickUpSchedule.FirstAvailableTimeSlotLabel),
      // });
  }
}

  async componentDidMount() {
     await this.componentCallback(false);
  }

  async componentDidUpdate(prevProps){
    if (this.props.completeAddress?.AreaId != prevProps.completeAddress?.AreaId) {
      await this.componentCallback(true);
    }
  }

  formatDate(dateToFormat) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateToFormat).toLocaleDateString([], options);
  }

  updatePickupDate = schedule => {
    //this.state.timeSlot.PickUpDay = schedule.Day;
    // console.log("Param" , schedule);
    // this.props.setSelectedPickupTimeSlotId_proto(0);
    this.props.setSelectedPickupDate_proto(schedule?.Date);
    let pickUpSchedule = {
      PickupSelectedDate: this.formatDate(schedule?.Date),
      PickupSelectedTime: schedule.TimeSlots[0]?.StartTime + "-" + schedule.TimeSlots[0]?.EndTime,
      PickupSelectedSlotId: schedule.TimeSlots[0]?.UniqSlotId,
      PickupDay: schedule?.Day,
    };
    this.props.setSelectedPickUpSchedule(pickUpSchedule);
    this.setState({ ...(this.state.timeSlot.PickUpDay = schedule.Day) });
    this.setState({
      ...(this.state.timeSlot.PickupSlotId = schedule?.TimeSlots[0]?.UniqSlotId),
    });
    this.setState({
      ...(this.state.timeSlot.PickupSelectedDate = schedule?.Date),
    });
    this.setState({
      ...(this.state.timeSlot.PickupSelectedTime = schedule?.TimeSlots[0]?.StartTime + "-" + schedule?.TimeSlots[0]?.EndTime),
    });
    // fetch(`https://dcpapi.ssasoft.com/api/OrderScheduler/?timeSlotType=1&day=${schedule.Day}`)
    // .then(
    //     (result) => {

    //     }
    // );
  };
  updatePickupTimeSlot = async (scheduleDay, slot) => {
    // await this.props.fetchPickUpSchedule("Pickup", scheduleDay.Date);
    this.props.setSelectedPickupTimeSlotId_proto(slot?.SlotId);

    let pickUpSchedule = {
      PickupSelectedDate: this.formatDate(scheduleDay?.Date),
      PickupSelectedTime: slot?.StartTime + "-" + slot?.EndTime,
      PickupSelectedSlotId: slot?.UniqSlotId,
      PickupDay: scheduleDay?.Day,
    };
    this.props.setSelectedPickUpSchedule(pickUpSchedule);
    this.setState({ ...(this.state.timeSlot.PickupUniqSlotId = slot?.SlotId) });
    this.setState({ ...(this.state.timeSlot.PickupSlotId = slot?.UniqSlotId) });
    this.setState({
      ...(this.state.timeSlot.PickupSelectedTime = slot?.StartTime + "-" + slot?.EndTime),
    });
    this.setState({
      ...(this.state.timeSlot.PickupSelectedDate = scheduleDay?.Date),
    });
    this.props.setLoader(true);
    await this.props.fetchDropSchedule(2, scheduleDay?.Date);
    this.props.setLoader(false);
    if (this.props.dropSchedule) {
      this.setState({
        ...(this.state.timeSlot.DropDay = this.props.dropSchedule?.FirstAvailableDay),
      });
      this.setState({
        ...(this.state.timeSlot.DropSlotId = this.props.dropSchedule?.FirstAvailableTimeSlotId),
      });
      this.setState({
        ...(this.state.timeSlot.DropSelectedTime = this.props.dropSchedule?.FirstAvailableTimeSlotLabel),
      });
      this.setState({
        ...(this.state.timeSlot.DeliveryDateTime = this.props.dropSchedule?.FirstAvailableDate),
      });
    }
  };
  onDeliveryTimeIDChange = timeId => {
    this.props.setDeliveryTimeId(timeId);
    console.log(this.props.DeliveryTimeId);
  };
  back = () => {
    this.setState({ path: "OrderAddress" });
  };
  forward = () => {
    this.setState({ path: "OrderDetail" });
  };
  render() {
    // console.log("WeekSchedule", this.state.viewModel.WeekSchedules);
    console.log(this.props.DeliveryTimeId);
    return (
      <>
        {this.state.path === "TimeSlot" ? (
          <>
            <div className='row heading_bar'>
              <div className='wrapper'>
                <h2>Choose your time for order pickup and drop</h2>
              </div>
            </div>
            <div className='row'>
              <div className='wrapper progress_container'>
                <OrderProgressBar pageId={4} orderPageEnum={orderPageEnum} />
              </div>
              <div className='wrapper'>
                <div className='col-md-6 calender-r-padding schedule_box'>
                  <h2>
                    {/* Choose Collection Time */}
                    {/* <span className='selecttime'>
                      ({this.formatDate(this.state.timeSlot.PickupSelectedDate)} &nbsp; {this.state.timeSlot.PickupSelectedTime})
                    </span> */}
                  </h2>
                  <div id='collectiontime'>
                    <div className='schedule_days'>
                      <ul>
                        {/* {this.props.orderObject?.Order?.PickupSchedule?.WeekSchedules?.map((value, index) => {
                          return (
                            <li
                              className={
                                value.Day === this.props.selectedPickUpSchedule?.PickupDay //this.state.timeSlot.PickUpDay
                                  ? "active"
                                  : "" + value.IsBookingAvailable
                                  ? ""
                                  : "disable_date"
                              }
                              key={index}
                            >
                              <a onClick={() => this.updatePickupDate(value)}>
                                {value.DayOfWeek}
                                <span>{value.Day}</span>
                              </a>
                            </li>
                          );
                        })} */}
                        {
                        // this.props.pickUpSchedule?.WeekSchedules?.map((item, index) => {
                        //   return (
                        //     <li
                        //       className={
                        //         item.Day === this.props.selectedPickUpSchedule?.PickupDay //this.state.timeSlot.PickUpDay
                        //           ? "active"
                        //           : "" + item.IsBookingAvailable
                        //           ? ""
                        //           : "disable_date"
                        //       }
                        //       key={index}
                        //     >
                        //       <a onClick={() => this.updatePickupDate(item)}>
                        //         {item.DayOfWeek}
                        //         <span>{item.Day}</span>
                        //       </a>
                        //     </li>
                        //   );
                        // })
                        }
                      </ul>
                    </div>

                    {/* {
                    this.props.pickUpSchedule?.WeekSchedules?.map((value, index) => {
                      return (
                        <div className='schedule_time' key={index}>
                          {
                            value.Day === this.props.selectedPickUpSchedule?.PickupDay && (
                              <ul>
                                {value.TimeSlots?.map((tsValue, tsIndex) => {
                                  return (
                                    <li
                                      key={tsIndex}
                                      className={
                                        tsValue.UniqSlotId == this.props.selectedPickUpSchedule?.PickupSelectedSlotId
                                          ? "active"
                                          : "" + tsValue.IsDisabled
                                          ? ""
                                          : "disable_schedule"
                                      }
                                    >
                                      <a onClick={() => this.updatePickupTimeSlot(value, tsValue)}>
                                        {tsValue.StartTime}
                                        <i className='arrow_time'></i>
                                        {tsValue.EndTime}
                                      </a>
                                    </li>
                                  );
                                })
                                }
                              </ul>
                            )
                          }
                        </div>
                      );
                    })} */}
                  </div>
                </div>

                <div className='col-md-6 calender-l-padding schedule_box green_schedule' block-ui='droptimeBlockUI'>
                  <h2>Choose Delivery Time</h2>

                  {/* <label className='container'>
                    <input
                      type='radio'
                      name='delivery-time'
                      checked={this.props.DeliveryTimeId == 10 ? true : false}
                      value='10'
                      onChange={() => this.onDeliveryTimeIDChange(10)}
                    />
                    Same day
                    <span className='checkmark'></span>
                  </label> */}
                  <hr />
                  <label className='container'>
                    <input
                      type='radio'
                      checked={this.props.DeliveryTimeId == 20 ? true : false}
                      name='delivery-time'
                      value='20'
                      onChange={() => this.onDeliveryTimeIDChange(20)}
                    />
                    Next Day
                    <span className='checkmark'></span>
                  </label>
                  <hr />
                  <label className='container'>
                    <input
                      type='radio'
                      name='delivery-time'
                      value='30'
                      onChange={() => this.onDeliveryTimeIDChange(30)}
                      defaultChecked
                      checked={this.props.DeliveryTimeId == 30 ? true : false}
                    />
                    Regular
                    <span className='checkmark'></span>
                  </label>
                  <hr />
                </div>

                <div className='col-sm-12 develivery-collection'>
                  <div className='row'>
                    <button type='button' className='btn' onClick={this.back}>
                      Back
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary pull-right'
                      onClick={this.forward}
                      // disabled={this.props.selectedPickupTimeSlotIdProto === 0}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : this.state.path == "SignUp" ? (
          <SignUp />
        ) : this.state.path == "OrderAddress" ? (
          <OrderAddress />
        ) : this.state.path == "Order" ? (
          <OrderItem />
        ) : this.state.path == "OrderDetail" ? (
          <OrderDetail />
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchPickUpSchedule,
  fetchDropSchedule,
  setDeliveryTimeId,
  setSelectedPickUpSchedule,
  setLoader,
  fetchPickUpSchedule_proto,
  setSelectedPickupDate_proto,
  setSelectedPickupTimeSlotId_proto,
  fetchOrderScheduleByAddressId,
};

const mapStateToProps = ({ Auth }) => {
  let {
    pickUpSchedule,
    dropSchedule,
    DeliveryTimeId,
    isLogin,
    selectedPickUpSchedule,
    pickupScheduleProto,
    orderObject,
    selectedPickupDateProto,
    selectedPickupTimeSlotIdProto,
    completeAddress,
  } = Auth;
  return {
    pickUpSchedule,
    dropSchedule,
    DeliveryTimeId,
    isLogin,
    selectedPickUpSchedule,
    pickupScheduleProto,
    orderObject,
    selectedPickupDateProto,
    selectedPickupTimeSlotIdProto,
    completeAddress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTime);
