import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import {
  listsearchedareaspopup,
  listsearchedareas,
  setServiceAreaPopup,
} from "../../store/action/Auth";

export class SearchedAreas extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.serviceAreaPopup}
          contentLabel="Example Modal"
          className="ngdialog ngdialog-theme-default model-login ng-scope"
          id="ngdialog15"
        >
          <div className="ngdialog-overlay"></div>
          <div className="ngdialog-content" role="document">
            <div className="modal-header">
              <h4 className="modal-title" id="ngdialog15-aria-labelledby">
                List of searched areas
              </h4>
            </div>
            <div className="modal-body" style={{ overflow: "auto" }}>
              {this.props.serviceAreaData != null &&
                this.props.serviceAreaData != undefined &&
                this.props?.serviceAreaData?.map((item, index) => {
                  return <div key={index}>{item.Name}</div>;
                })}
            </div>
            <div
              className="ngdialog-close"
              onClick={() => {
                this.props.setServiceAreaPopup(false);
              }}
            ></div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  listsearchedareaspopup,
  listsearchedareas,
  setServiceAreaPopup,
};
const mapStateToProps = ({ Auth }) => {
  let {
    searchedareaPopup,
    listsearchedareaspopup,
    searchedareas,
    serviceAreaPopup,
    serviceAreaData,
  } = Auth;
  return {
    searchedareaPopup,
    listsearchedareaspopup,
    searchedareas,
    serviceAreaPopup,
    serviceAreaData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchedAreas);
