import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setDefaultAddressId,
  setDefaultAddress,
  deleteAddress,
  setLoginDetails,
  fetchAddressDetails,
  addCustomerAddress,
  editCustomerAddress,
  setLoader,
  fetchSelectedAddress,
} from "../store/action/Auth";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const schema2 = Yup.object().shape({
  address1: Yup.string()
    .min(3, "Minimum 8 characters Address is required")
    .max(60, "Maximum 60 characters are allowed.")
    .required("Address is Required."),
  city: Yup.number()
    .min(1, "Please Select a City!")
    .required("Please select a city."),
  area: Yup.number()
    .required("Please Select an Area!")
    .min(1, "Please Select a City!"),
  isDefaultAddress: Yup.bool(),
});

const schema = Yup.object().shape({
  address1: Yup.string()
    .min(3, "Minimum 8 characters Address is required")
    .max(60, "Maximum 60 characters are allowed.")
    .required("Address is Required."),
  city: Yup.number()
    .min(1, "Please select a City!")
    .required("Please select a city."),
  area: Yup.number()
    .required("Please select an Area!")
    .min(1, "Please select an Area!"),
  isDefaultAddress: Yup.bool(),
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseObj: {},
      error: {},
      isModalOpen: false,
      isEditModalOpen: false,
      addressLine1: "",
      city: "",
      area: "",
      default: false,
      editAddressId: null,
    };
  }

  async componentDidMount() {
    await this.props.fetchAddressDetails();
    console.log("city and area wise : ", this.props.address);
  }

  searchSelectedArea = async (Id) => {
    for (var i = 0; i < this.props.address.CityAreas.length; i++) {
      if (this.props.address.CityAreas[i].Id === Id) {
        return this.props.address.CityAreas[i].Name;
      }
    }
  };

  onConfirm = async () => {
    alert("You have confirmed your default address.");
  };

  handleClickConfirm = async (defaultAddr) => {
    defaultAddr.IsDefault = true;
    this.props.setLoader(true);
    this.props.setDefaultAddressId(defaultAddr.Id);
    await this.props.setDefaultAddress(
      defaultAddr,
      localStorage.getItem("Token")
    );
    this.props.setLoader(false);
    if (this.props?.defaultAddressIsUpdatedResponse?.Address?.EntityId !== 0) {
      toast.success("Your Default Address has been Updated.");
    }
    if (this.props?.defaultAddressIsUpdatedResponse?.Address?.EntityId === 0) {
      toast.error("Unable to update the Default Address.");
    }
  };

  handleClickConfirmDelete = async (addr) => {
    if (addr.IsDefault) {
      toast.error("Default Address cannot be deleted.");
    }
    if (addr.IsDefault === false) {
      addr.IsActive = false;
      let deletedAddressId = addr.Id;
      this.props.setLoader(true);
      await this.props.deleteAddress(addr, localStorage.getItem("Token"));
      await this.props.setLoginDetails(
        localStorage.getItem("Id"),
        localStorage.getItem("Token")
      );
      this.props.setLoader(false);
      this.props.customerDetails.Addresses.map((address, index) => {
        if (address.Id === deletedAddressId) {
          toast.error("Unable to delete the address.");
          return;
        }
      });
      toast.success("Your Address has been deleted.");
    }
  };

  submit = (addr) => {
    confirmAlert({
      title: 'stay one moment!',
      message: 'Are you sure do you want to Add this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.submitHandler();
          }
        },
        {
          label: 'No',
        }
      ]
      // customUI: ({ onClose }) => {
      //   return (
      //     <div style={{border:"1px solid blue !important"}}
      //     >
      //       <div >
      //       <div>
      //       <h5>From now onward, this will be your default address?</h5>
      //       <p>Sure you want to do this?</p>
      //       </div>
      //       <button
      //         onClick={onClose}
              
      //       >
      //         Cancel
      //       </button>
      //       <button
      //         onClick={() => {
      //           this.handleClickConfirm(addr);
      //           onClose();
      //         }}
              
      //       >
      //         Confirm
      //       </button>
      //     </div>
      //     </div>
      //   );
      // },
    });
  };

  submitDelete = (addr) => {
    confirmAlert({
      title: 'stay one moment!',
      message: 'Are you sure? You want to delete this address?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.handleClickConfirmDelete(addr);
          }
        },
        {
          label: 'No',
        }
      ]
      // customUI: ({ onClose }) => {
        
        // return (
        //   <div className="custom-ui">
        //     <h5>Are you sure? You want to delete this address?</h5>
        //     {/* <p>You want to do this?</p> */}
        //     <button
        //       onClick={() => onClose()}
        //       style={{
        //         padding: "8px 20px",
        //         backgroundColor: "#af0073",
        //         color: "white",
        //         borderRadius: "5px",
        //         fontSize: "17px",
        //       }}
        //     >
        //       Cancel
        //     </button>
        //     <button
        //       onClick={() => {
        //         this.handleClickConfirmDelete(addr);
        //         onClose();
        //       }}
        //       style={{
        //         marginLeft: "100px",
        //         padding: "8px 20px",
        //         backgroundColor: "#af0073",
        //         color: "white",
        //         borderRadius: "5px",
        //         fontSize: "17px",
        //       }}
        //     >
        //       Confirm
        //     </button>
        //   </div>
        // );
      // },
    });
  };

  render() {
   
    return (
      this.props.isLogin && 
      <>
        <div className="row heading_bar">
          <div className="wrapper">
            <h2>Profile</h2>
          </div>
        </div>
        <div className="row">
          <div className="wrapper item_insertion profile  clearfix">
            <div className="leftProfileContainer">
              {this.props.profileUploaderLength > 0 && (
                <div>
                  {this.props.profileUploader &&
                    this.props.profileItemUploading && (
                      <div className="progress">
                        <div
                          className="progress"
                          role="progressbar"
                          style={{
                            width: this.props.profileUploaderProgress + "%",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                    )}
                </div>
              )}

              <div>
                <NavLink
                  className="btn btn-primary"
                  to={{ pathname: "/ChangePassword" }}
                >
                  <i className="fa fa-lock"></i> Change Password
                </NavLink>
              </div>
            </div>

            <div className="rightProfileContainer">
              <div className="clearfix">
                <h2>Personal Profile</h2>
                <NavLink
                  className="btn edit_button"
                  to={{
                    pathname: "/EditProfile",
                    state: {
                      Customer: this.props.customerDetails?.Customer,
                    },
                  }}
                >
                  Edit
                </NavLink>
                <ul>
                  <li>
                    <h4>First Name</h4>
                    <span>
                      {this.props.customerDetails?.Customer
                        ? this.props.customerDetails?.Customer?.FirstName
                        : ""}
                    </span>
                  </li>
                  <li>
                    <h4>Last Name</h4>
                    <span>
                      {this.props.customerDetails?.Customer
                        ? this.props.customerDetails?.Customer?.LastName
                        : ""}
                    </span>
                  </li>
                  <li>
                    <h4>Email Address</h4>
                    <span>
                      <p className="blue" >
                        {this.props.customerDetails?.Customer
                          ? this.props.customerDetails?.Customer?.Email
                          : ""}
                      </p>
                    </span>
                  </li>
                  <li>
                    <h4>Phone</h4>
                    <span>
                      {this.props.customerDetails?.Customer
                        ? this.props.customerDetails?.Customer?.Phone
                        : ""}
                    </span>
                  </li>
                </ul>
              </div>
              <h2 className="clearfix">
                Pickup & Delivery Address
                <button
                  className="btn edit_button"
                  onClick={() => {
                    this.setState({ isModalOpen: true });
                  }}
                >
                  Add Address
                </button>
              </h2>

              {this.props.customerDetails?.Addresses
                ? this.props.customerDetails?.Addresses.map((address, index) => {
                      return (
                        <div className="clearfix address_bar" key={index}>
                          <h3 className="col-sm-8">
                            {address.CompleteAddress}
                          </h3>
                          <div className="">
                            <label style={{textAlign:"right"}}>
                              <input
                                type="radio"
                                value={true}
                                disabled={
                                  address.Id === this.props.isDefaultAddressId
                                }
                                checked={
                                  address.Id === this.props.isDefaultAddressId
                                }
                                onClick={() => this.submit(address)}
                              />{" "}
                              <span></span>Default
                            </label>
                            <a style={{textAlign:"right"}}>
                              <i
                                className="fa fa-pencil"
                                onClick={async () => {
                                  await this.props.fetchSelectedAddress(
                                    address.Id,
                                    address.EntityId
                                  );
                                  // console.log(
                                  //   "selected Address RESPONSE :",
                                  //   this.props.selectedAddress
                                  // );
                                  this.setState(
                                    {
                                      isEditModalOpen: true,
                                      addressLine1:
                                        this.props.selectedAddress?.Address?.AddressLine1,
                                      city: address.City,
                                      area: address.Area,
                                      default: address.IsDefault,
                                      editAddressId: address.Id,
                                    },
                                    () => {
                                      console.log(this.state.addressLine1);
                                    }
                                  );
                                }}
                              ></i>
                            </a>
                            {address.Id !== this.props.isDefaultAddressId &&
                            <a style={{textAlign:"right"}}>
                              <i
                                className="fa fa-close"
                                onClick={() => this.submitDelete(address)}
                              ></i>
                            </a>
                            }
                          </div>
                        </div>
                      );
                    })
                : ""}
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.isModalOpen}
          contentLabel="Example Modal"
          className="ngdialog ngdialog-theme-default model-login ng-scope"
          id="ngdialog15"
          ariaHideApp={false}
        >
          <div className="ngdialog-overlay"></div>
          <div className="ngdialog-content" role="document">
            <div className="modal-header">
              <h3 className="modal-title" id="modal-picking-address-label">
                Pickup & Delivery Address
              </h3>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="wrapper item_insertion clearfix">
                  <Formik
                    initialValues={{
                      address1: "",
                      city: 0,
                      area: 0,
                      isDefaultAddress: false,
                    }}
                    validationSchema={schema}
                    validateOnBlur
                    onSubmit={async (values) => {
                      let viewModel = {
                        Address: {
                          AddressLine1: values.address1,
                          AddressLine2: "",
                          Altitude: null,
                          Area: await this.searchSelectedArea(
                            parseInt(values.area, 10)
                          ),
                          AreaId: values.area,
                          City: "Islamabad",
                          CityId: values.city,
                          CompleteAddress: "",
                          Country: "Canada",
                          CountryId: 1,
                          CreatedBy: null,
                          CreatedById: this.props.customerDetails?.Customer?.Id,
                          EntityId: this.props.customerDetails?.Customer?.Id,
                          EntityTypeId: 10,
                          HasErrors: false,
                          Id: null,
                          InsertDate: "2021-08-26T11:10:00.2001816Z",
                          IsActive: true,
                          IsDefault: false,
                          LastUpdatedBy: null,
                          LastUpdatedById: null,
                          Latitude: "D",
                          Longitude: "D",
                          ModifiedDate: "2021-08-26T10:26:37.06",
                          ResultMessages: [],
                          SortOrder: 1,
                          State: "Ontario",
                          StateId: 1,
                          ZipCode: "D",
                        },
                        Cities: this.props.address?.Cities,
                        CityAreas: this.props.address?.CityAreas,
                        CreatedBy: null,
                        CreatedById: this.props.customerDetails?.Customer?.Id,
                        HasErrors: false,
                        Id: 0,
                        InsertDate: "2021-08-26T14:15:06.8410833Z",
                        IsActive: true,
                        LastUpdatedBy: null,
                        LastUpdatedById: null,
                        ModifiedDate: null,
                      };

                      viewModel.Address.CompleteAddress =
                        viewModel.Address.AddressLine1 +
                        ", " +
                        viewModel.Address.Area +
                        ", " +
                        viewModel.Address.City;
                      console.log(
                        "complete Address : ",
                        viewModel.Address.CompleteAddress
                      );
                      this.props.setLoader(true);
                      await this.props.addCustomerAddress(
                        viewModel,
                        localStorage.getItem("Token")
                      );
                      this.props.setLoader(false);
                      console.log(
                        "add address try response: ",
                        this.props.addAddressResponse
                      );

                      if (
                        this.props.addAddressResponse.ResultMessages[0]
                          ?.MessageType === "success"
                      ) {
                        toast.success(
                          this.props.addAddressResponse.ResultMessages[0]
                            ?.Message
                        );
                      } else {
                        toast.error(
                          this.props.addAddressResponse.ResultMessages[0]
                            ?.Message
                        );
                      }
                      this.props.setLoader(true);
                      await this.props.setLoginDetails(
                        localStorage.getItem("Id"),
                        localStorage.getItem("Token")
                      );
                      if (values.isDefaultAddress) {
                        this.props.addAddressResponse.Address.IsDefault = true;
                        this.props.setLoader(true);
                        this.props.setDefaultAddressId(
                          this.props.addAddressResponse.Address.Id
                        );
                        await this.props.setDefaultAddress(
                          this.props.addAddressResponse.Address,
                          localStorage.getItem("Token")
                        );
                        this.props.setLoader(false);
                        if (
                          this?.props?.defaultAddressIsUpdatedResponse?.Address
                            ?.EntityId !== 0
                        ) {
                          toast.success(
                            "Your Default Address has been Updated."
                          );
                        }
                        if (
                          this.props?.defaultAddressIsUpdatedResponse?.Address
                            ?.EntityId === 0
                        ) {
                          toast.error("Unable to update the Default Address.");
                        }
                      }
                      this.props.setLoader(false);
                      this.setState({ isModalOpen: !this.state.isModalOpen });
                    }}
                  >
                    {(props) => {
                      const {
                        touched,
                        errors,
                        handleSubmit,
                        values,
                        handleChange,
                        handleBlur,
                      } = props;

                      return (
                        <form
                          name="placeOrderAddressfrm"
                          noValidate
                          autoComplete="off"
                          onSubmit={handleSubmit}
                        >
                          {/* {errors.address1 && touched.address1 && (
                            <div className="errorMessage">
                              {errors.address1}
                            </div>
                          )} */}
                          <div className="col-sm-12 paddin_right field">
                          {errors.address1 && touched.address1 && (
                            <div className="errorMessage">
                              {errors.address1}
                            </div>
                          )}
                            {values.address1 == "" ? null : (
                              <label
                                htmlFor="address1"
                                className="show-hide ng-hide"
                              >
                                Address Line 1
                              </label>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              name="address1"
                              id="address1"
                              placeholder="Address"
                              autoFocus
                              value={values.address1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              maxLength={60}
                            />
                          </div>
                          {/* {errors.city && touched.city && (
                            <div className="errorMessage">{errors.city}</div>
                          )} */}
                          <div className="col-sm-6 paddin_right field">
                          {errors.city && touched.city && (
                            <div className="errorMessage">{errors.city}</div>
                          )}
                            {values.city == 0 ? null : (
                              <label htmlFor="city" className="show-hide">
                                City
                              </label>
                            )}
                            <select
                              name="city"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control required-field"
                            >
                              <option value={0} disabled>
                                Select City{" "}
                              </option>
                              {this.props.address?.Cities?.map((ci, index) => {
                                return (
                                  <option key={index} value={ci.Id}>
                                    {ci.Description}{" "}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {/* {errors.area && touched.area && (
                            <div className="errorMessage">{errors.area}</div>
                          )} */}
                          <div className="col-sm-6 paddin_left field">
                          {errors.area && touched.area && (
                            <div className="errorMessage">{errors.area}</div>
                          )}
                          {values.area == 0 ? null : (
                            <label htmlFor="area" className="show-hide">
                              Area
                            </label>
                            )}
                            <select
                              name="area"
                              value={values.area}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control required-field"
                            >
                              <option value={0} disabled>
                                Select Area{" "}
                              </option>
                              {this.props.address?.CityAreas?.map(
                                (area, index) => {
                                  return (
                                    <option key={index} value={area.Id}>
                                      {area.Name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          <div className="col-sm-6 paddin_left col-sm-offset-6">
                            <label>
                              <input
                                type="checkbox"
                                name="isDefaultAddress"
                                className="checkbox-inline"
                                onChange={handleChange}
                              />
                              Default Address
                            </label>
                          </div>
                          <div className="col-sm-6 paddin_left col-sm-offset-6"></div>
                          <div className="row">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={
                                errors.address1 || values.address1 === ""
                                  ? true
                                  : errors.city || values.city === ""
                                  ? true
                                  : errors.area || values.area === ""
                                  ? true
                                  : false
                              }
                            >
                              Save
                            </button>
                            <button type="button" id="btnClose" hidden>
                              Close
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div
              className="ngdialog-close"
              onClick={() => {
                this.setState({ isModalOpen: false });
              }}
            ></div>
          </div>
        </Modal>

        {/* this is Edit Modal */}
        <Modal
          isOpen={this.state.isEditModalOpen}
          contentLabel="Example Modal"
          className="ngdialog ngdialog-theme-default model-login ng-scope"
          id="ngdialog15"
          ariaHideApp={false}
        >
          <div className="ngdialog-overlay"></div>
          <div className="ngdialog-content" role="document">
            <div className="modal-header">
              <h3 className="modal-title" id="modal-picking-address-label">
                Pickup & Delivery Address
              </h3>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="wrapper item_insertion clearfix">
                  <Formik
                    initialValues={{
                      address1: this.state.addressLine1,
                      city: 1,
                      area: this.props.selectedAddress?.Address?.AreaId,
                      isDefaultAddress: this.state.default,
                    }}
                    validationSchema={schema2}
                    validateOnBlur
                    onSubmit={async (values) => {
                      // debugger;
                      if (values.isDefaultAddress) {
                        this.props.selectedAddress.Address.IsDefault = true;
                        this.props.setLoader(true);
                        this.props.setDefaultAddressId(
                          this.props.selectedAddress.Address.Id
                        );
                        // debugger;
                        await this.props.setDefaultAddress(
                          this.props.selectedAddress.Address,
                          localStorage.getItem("Token")
                        );
                        this.props.setLoader(false);
                        if (
                          this.props?.defaultAddressIsUpdatedResponse?.Address?.EntityId !== 0
                        ) {
                          toast.success(
                            "Your Default Address has been Updated."
                          );
                        }
                        if (
                          this.props?.defaultAddressIsUpdatedResponse?.Address?.EntityId === 0
                        ) {
                          toast.error("Unable to update the Default Address.");
                        }
                      }
                      console.log("form values:", values);
                      let addressObj = this.props.selectedAddress;
                      addressObj.Address.AddressLine1 = values.address1;
                      addressObj.Address.Area = await this.searchSelectedArea(
                        parseInt(values.area, 10)
                      );
                      addressObj.Address.AreaId = values.area;
                      addressObj.CreatedById =
                        this.props.customerDetails?.Customer?.Id;

                      let viewModel = {
                        Address: {
                          AddressLine1: values.address1,
                          AddressLine2: "",
                          Altitude: null,
                          Area: await this.searchSelectedArea(
                            parseInt(values.area, 10)
                          ),
                          AreaId: values.area,
                          City: "Islamabad",
                          CityId: 1,
                          CompleteAddress: "",
                          Country: "Canada",
                          CountryId: 1,
                          CreatedBy: null,
                          CreatedById: this.props.customerDetails?.Customer?.Id,
                          EntityId: this.props.customerDetails?.Customer?.Id,
                          EntityTypeId: 10,
                          HasErrors: false,
                          Id: 0,
                          InsertDate: "2021-08-26T11:10:00.2001816Z",
                          IsActive: true,
                          IsDefault: values.isDefaultAddress,
                          LastUpdatedBy: null,
                          LastUpdatedById: null,
                          Latitude: "D",
                          Longitude: "D",
                          ModifiedDate: "2021-08-26T10:26:37.06",
                          ResultMessages: [],
                          SortOrder: 1,
                          State: "Ontario",
                          StateId: 1,
                          ZipCode: "D",
                        },
                        Cities: this.props.address?.Cities,
                        CityAreas: this.props.address?.CityAreas,
                        CreatedBy: null,
                        CreatedById: this.props.customerDetails?.Customer?.Id,
                        HasErrors: false,
                        Id: this.state.editAddressId,
                        InsertDate: "2021-08-26T14:15:06.8410833Z",
                        IsActive: true,
                        LastUpdatedBy: null,
                        LastUpdatedById: null,
                        ModifiedDate: null,
                      };
                      viewModel.Address.CompleteAddress =
                        viewModel.Address.AddressLine1 +
                        ", " +
                        viewModel.Address.Area +
                        ", " +
                        viewModel.Address.City;
                      console.log(
                        "complete Address : ",
                        viewModel.Address.CompleteAddress
                      );

                      addressObj.Address.CompleteAddress =
                        addressObj.Address.AddressLine1 +
                        ", " +
                        addressObj.Address.Area +
                        ", " +
                        addressObj.Address.City;

                      this.props.setLoader(true);
                      debugger;
                      await this.props.editCustomerAddress(
                        addressObj,
                        localStorage.getItem("Token")
                      );
                      this.props.setLoader(false);
                      console.log(
                        "add edit address try response : ",
                        this.props.editCustomerAddressResponse
                      );

                      if (
                        this.props.editCustomerAddressResponse
                          ?.ResultMessages[0]?.MessageType === "success"
                      ) {
                        toast.success(
                          this.props.editCustomerAddressResponse
                            ?.ResultMessages[0]?.Message
                        );
                      } else {
                        toast.error(
                          this.props.editCustomerAddressResponse
                            ?.ResultMessages[0]?.Message
                        );
                      }

                      this.props.setLoader(true);
                      await this.props.setLoginDetails(
                        localStorage.getItem("Id"),
                        localStorage.getItem("Token")
                      );
                      this.props.setLoader(false);
                      this.setState({
                        isEditModalOpen: !this.state.isEditModalOpen,
                      });
                    }}
                  >
                    {(props) => {
                      const {
                        touched,
                        errors,
                        handleSubmit,
                        values,
                        handleChange,
                        handleBlur,
                      } = props;

                      return (
                        <form
                          name="placeOrderEditAddressfrm"
                          noValidate
                          autoComplete="off"
                          onSubmit={handleSubmit}
                        >
                          {errors.address1 && touched.address1 && (
                            <div className="errorMessage">
                              {errors.address1}
                            </div>
                          )}
                          <div className="col-sm-12 paddin_right field">
                            {values.address1 === "" ? null : (
                              <label htmlFor="address1" className="show-hide">
                                Address Line 1
                              </label>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              name="address1"
                              id="address1"
                              placeholder="Address"
                              value={values.address1}
                              onChange={handleChange}
                              maxLength={60}
                            />
                          </div>
                          {errors.city && touched.city && (
                            <div className="errorMessage">{errors.city}</div>
                          )}
                          <div className="col-sm-6 paddin_right field">
                            <label htmlFor="city" className="show-hide">
                              City
                            </label>
                            <select
                              name="city"
                              id="city"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control required-field"
                            >
                              <option value={0} disabled>
                                Select City{" "}
                              </option>
                              {this.props.address?.Cities?.map((ci, index) => {
                                return (
                                  <option
                                    key={index}
                                    selected="selected"
                                    value={ci.Id}
                                  >
                                    {ci.Description}{" "}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          {errors.area && touched.area && (
                            <div className="errorMessage">{errors.area}</div>
                          )}

                          <div className="col-sm-6 paddin_left field">
                            <label htmlFor="area" className="show-hide">
                              Area
                            </label>
                            <select
                              name="area"
                              id="area"
                              value={values.area}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control required-field"
                            >
                              <option value={0} disabled>
                                Select Area{" "}
                              </option>
                              {this.props.address?.CityAreas?.map(
                                (area, index) => {
                                  return (
                                    <option key={index} value={area.Id}>
                                      {area.Name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          <div className="col-sm-6 paddin_left col-sm-offset-6">
                            <label>
                              <input
                                type="checkbox"
                                name="isDefaultAddress"
                                className="checkbox-inline"
                                checked={
                                  this.props.selectedAddress?.Address?.IsDefault
                                    ? true
                                    : values.isDefaultAddress
                                }
                                value={values.isDefaultAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // disabled={
                                //   values.isDefaultAddress ? true : false
                                // }
                              />
                              Default Address
                            </label>
                          </div>
                          <div className="col-sm-6 paddin_left col-sm-offset-6"></div>
                          <div className="row">
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                            <button type="button" id="btnClose" hidden>
                              Close
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div
              className="ngdialog-close"
              onClick={() => {
                this.setState({ isEditModalOpen: false });
              }}
            ></div>
          </div>
        </Modal>
      </>
    );
            // } ///
            // {!this.props.isLogin &&  return (<></>)}
  }
}

const mapDispatchToProps = {
  setDefaultAddressId,
  setDefaultAddress,
  deleteAddress,
  setLoginDetails,
  fetchAddressDetails,
  addCustomerAddress,
  editCustomerAddress,
  setLoader,
  fetchSelectedAddress,
};

const mapStateToProps = ({ Auth }) => {
  let {
    customerDetails,
    profileUploaderLength,
    profileUploader,
    profileItemUploading,
    profileUploaderProgress,
    isDefaultAddressId,
    defaultAddressIsUpdatedResponse,
    address,
    addAddressResponse,
    editCustomerAddressResponse,
    selectedAddress,
    isLogin
  } = Auth;
  return {
    customerDetails,
    profileUploaderLength,
    profileUploader,
    profileItemUploading,
    profileUploaderProgress,
    isDefaultAddressId,
    defaultAddressIsUpdatedResponse,
    address,
    addAddressResponse,
    editCustomerAddressResponse,
    selectedAddress,
    isLogin
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
