import { PURGE } from "redux-persist";

var initialState = {
  // auth: localStorage.getItem('token'),
  data: {},
  timer: "",
  aboutUsData: {},
  isLogin: false,
  loginData: {},
  customerDetails: null,
  signinPopUp: false,
  currentPage: 1,
  forgetPasswordPopUp: false,
  refertofriendPopup: false,
  checkservicePopup: false,
  //NEW
  searchedareaPopup: false,
  termsConditionsPopup: false,
  navBar: false,

  //order
  orderData: {},

  viewModel: null,
  customer: {},
  address: {},
  changePasswordResponse: {},
  editCustomerProfileResponse: {},
  addAddressResponse: {},
  editCustomerAddressResponse: {},
  selectedAddress: {},
  listOrders: [],
  perPage: 5,
  page: 0,
  pages: 0,
  pageId: 1,
  dropSchedule: {},
  pickUpSchedule: null,
  DeliveryTimeId: 30,
  selectedPickUpSchedule: null,
  completeAddress: null,

  profileUploaderLength: 0,
  profileUploader: false,
  profileItemUploading: false,
  profileUploaderProgress: "",
  orderData: {},
  ordersArray: [],
  searchedareas: [],
  forgetPasswordResponse: {},
  isLoading: false,
  isDefaultAddressId: 0,
  defaultAddressIsUpdatedResponse: {},
  deleteAddressResponse: {},
  address: {},
  changePasswordResponse: {},
  editCustomerProfileResponse: {},
  addAddressResponse: {},
  editCustomerAddressResponse: {},
  orderObject: {},
  orderSummary: {},
  pickupScheduleProto: {},
  selectedPickupDateProto: "",
  selectedPickupTimeSlotIdProto: 0,

  signupModal: false,
  postForgetPasswordModal: false,
  serviceAreaPopup: false,
  serviceAreaData: null,
  path: ""
};
const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "SET_LOGIN_STATUS":
      return {
        ...state,
        isLogin: payload,
      };

    case "SET_PATH":
      return {
        ...state,
        path: payload,
      };

    case "SET_USER_SIGNOUT":
      return initialState;

    case "SET_SELECTED_PICKUP_DATE_PROTO":
      return {
        ...state,
        selectedPickupDateProto: payload,
      };

    case "SET_SIGNUP_MODAL":
      return {
        ...state,
        signupModal: payload,
      };

    case "SET_REFER_FRIEND_POPUP":
      return {
        ...state,
        refertofriendPopup: payload,
      };

    case "SET_POST_FORGETPASSWORD_POPUP":
      return {
        ...state,
        postForgetPasswordModal: payload,
      };

    case "SET_CHECK_SERVICE_POPUP":
      return {
        ...state,
        checkservicePopup: payload,
      };

    case "SET_SELECTED_PICKUP_TIME_SLOT_ID_PROTO":
      return {
        ...state,
        selectedPickupTimeSlotIdProto: payload,
      };

    case "SET_ORDER_OBJECT":
      return {
        ...state,
        orderObject: payload,
      };

    case "SET_PICKUP_SCHEDULE_PROTO":
      return {
        ...state,
        pickupScheduleProto: payload,
      };

    case "SET_NAVBAR":
      return {
        ...state,
        navBar: payload,
      };

    case "SET_ORDER_PAGE_ID":
      return {
        ...state,
        pageId: payload,
      };

    case "SET_LOGIN_DATA":
      return {
        ...state,
        loginData: payload,
      };

    case "SET_ORDER_SUMMARY":
      return {
        ...state,
        orderSummary: payload,
      };

    case "SET_LIST_ORDERS":
      return {
        ...state,
        listOrders: payload,
      };
    //new
    case "SET_LIST_SEARCHED_AREAS":
      return {
        ...state,
        searchedareas: payload,
      };

    case "SECOND_SEARCHED_AREA_POPUP":
      return {
        ...state,
        searchedareaPopup: payload,
      };

    case "SET_PAGE":
      return {
        ...state,
        page: payload,
      };

    case "SET_PAGES":
      return {
        ...state,
        pages: payload,
      };

    case "SET_CUSTOMER_DETAILS":
      return {
        ...state,
        customerDetails: payload,
      };
    case "SET_EDIT_CUSTOMER_PROFILE_RESPONSE":
      return {
        ...state,
        editCustomerProfileResponse: payload,
      };

    case "SET_SIGNIN_POPUP":
      return {
        ...state,
        signinPopUp: payload,
      };

    case "SET_LANDING_DATA":
      return {
        ...state,
        data: payload,
      };

    case "SET_ITEMS_DATA":
      return {
        ...state,
        data: payload,
      };

    case "SET_ABOUTUS_DATA":
      return {
        ...state,
        aboutUsData: payload,
      };

    case "SET_FORGETPASSWORD_POPUP":
      return {
        ...state,
        forgetPasswordPopUp: payload,
      };

    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: payload,
      };

    case "SET_ORDERS_DATA":
      return {
        ...state,
        orderData: payload,
      };

    case "SET_ORDERS_ARRAY":
      return {
        ...state,
        ordersArray: payload,
      };

    case "SET_FORGETPASSWORD_RESPONSE":
      return {
        ...state,
        forgetPasswordResponse: payload,
      };

    case "SET_LOADER":
      return {
        ...state,
        isLoading: payload,
      };

    case "SET_DEFAULT_ADDRESS_ID":
      return {
        ...state,
        isDefaultAddressId: payload,
      };

    case "SET_DEFAULT_ADDRESS_UPDATE_RESPONSE":
      return {
        ...state,
        defaultAddressIsUpdatedResponse: payload,
      };

    case "SET_CHANGEPASSWORD_RESPONSE":
      return {
        ...state,
        changePasswordResponse: payload,
      };
    case "SET_ADD_CUSTOMER_ADDRESS_RESPONSE":
      return {
        ...state,
        addAddressResponse: payload,
      };
    case "SET_EDIT_CUSTOMER_ADDRESS_RESPONSE":
      return {
        ...state,
        editCustomerAddressResponse: payload,
      };
    case "SET_SELECTED_ADDRESS_INFO":
      return {
        ...state,
        selectedAddress: payload,
      };

    case "SET_ORDERS_ITEM":
      return {
        ...state,
        viewModel: payload,
      };
    case "SET_SIGNUP_INFO":
      return {
        ...state,
        customer: payload,
      };
    case "SET_ADDRESS_INFO":
      return {
        ...state,
        address: payload,
      };
    case "SET_PICKUP_SCHEDULE":
      return {
        ...state,
        pickUpSchedule: payload,
      };
    case "SET_DROP_SCHEDULE":
      return {
        ...state,
        dropSchedule: payload,
      };
    case "SET_DELIVERYTIME_ID":
      return {
        ...state,
        DeliveryTimeId: payload,
      };
    case "SET_SELECTED_PICKUP_SCHEDULE":
      return {
        ...state,
        selectedPickUpSchedule: payload,
      };

    case "SET_COMPLETE_ADDRESS":
      return {
        ...state,
        completeAddress: payload,
      };

    case "RESET_CUSTOMER_DETAILS":
      return {
        ...state,
        customerDetails: {},
      };

    case "SET_SERVICE_AREA_POPUP":
      return {
        ...state,
        serviceAreaPopup: payload.status,
        serviceAreaData: payload.data
      };

    case "SET_TERMS_CONDITIONS_POPUP":
      return {
        ...state,
        termsConditionsPopup: payload,
      };

    default:
      return state;
  }
};

export default Auth;
