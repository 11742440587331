import React, { Component } from "react";

import { orderPageEnum } from "./models/globalUtilities";
import { Link } from "react-router-dom";
export class OrderProgressBar extends Component {
  orderPageEnum = orderPageEnum;
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.pageId,
      orderPageEnum: this.props.orderPageEnum,
      IsUserLogged: false,
    };
  }

  render() {
    return (
      <div id='progress_section'>
        <ul id='progressbar'>
          <li
            className={
              this.state.currentPage == this.orderPageEnum.Items
                ? "active"
                : "" + this.state.currentPage > this.orderPageEnum.Items
                ? " completed"
                : ""
            }
          >
            <a>
              {" "}
              {/* <Link to={"/Order"}>items</Link>{" "} */}
              Clothes
            </a>
          </li>
          {/* {!this.state.IsUserLogged && (
            <li
              className={
                this.state.currentPage == this.orderPageEnum.Signup
                  ? "active"
                  : "" + this.state.currentPage > this.orderPageEnum.Signup
                  ? " completed"
                  : ""
              }
            >
              {this.state.currentPage < this.orderPageEnum.Signup ? (
                <span>Contact</span>
              ) : (
                <a>
                  {" "}
                  <Link to={"/Account/Sign"}>Contact</Link>{" "}
                  Contact
                </a>
              )}
            </li>
          )} */}
          {
            <li
              className={
                this.state.currentPage == this.orderPageEnum.Address
                  ? "active"
                  : "" + this.state.currentPage > this.orderPageEnum.Address
                  ? " completed"
                  : ""
              }
            >
              {this.state.currentPage < this.orderPageEnum.Address ? (
                <span>Address</span>
              ) : (
                <a>
                  {" "}
                  {/* <Link to={"/Order/Address"}> Address </Link> */}
                  Address
                </a>
              )}
            </li>
          }
          {
            <li
              className={
                this.state.currentPage == this.orderPageEnum.Time
                  ? "active"
                  : "" + this.state.currentPage > this.orderPageEnum.Time
                  ? " completed"
                  : ""
              }
            >
              {this.state.currentPage < this.orderPageEnum.Time ? (
                <span>Summary</span>
              ) : (
                <a>
                  {" "}
                  {/* <Link to={"/Order/TimeSlot"}> Time </Link> */}
                  Summary
                </a>
              )}
            </li>
          }
          {this.state.IsUserLogged && (
            <li
              className={
                this.state.currentPage == this.orderPageEnum.Summary
                  ? "active"
                  : "" + this.state.currentPage > this.orderPageEnum.Summary
                  ? " completed"
                  : ""
              }
            >
              {this.state.currentPage < this.orderPageEnum.Summary ? (
                <span>Summary</span>
              ) : (
                <a>
                  {" "}
                  {/* <Link to={"/OrderDetail"}> Summary </Link> */}
                  Summary
                </a>
              )}
            </li>
          )}
        </ul>
      </div>
    );
  }
}
