import React, { Component } from "react";
import { connect } from "react-redux";
import { setListOrders, setPage, setPages, fetchOrderSummary, setLoader } from "../store/action/Auth";
import ReactPaginate from "react-paginate";

class CustomerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reverse: false,
      sortString: "",
      sortClass: "",
      search: "",
      orderArray: [],
    };
  }

  // sortOrder = (sortStr) => {
  //   this.setState({ sortString: sortStr }, () => {
  //     console.log("sortString : ", this.state.sortString);
  //   });
  //   this.setState({ reverse: !this.state.reverse }, () => {
  //     console.log("reverse : ", this.state.reverse);
  //   });
  //   if (this.state.reverse) {
  //     this.setState({ sortClass: "fa fa-caret-down pull-right" }, () => {
  //       console.log("sortClass**:", this.state.sortClass);
  //     });
  //   } else {
  //     this.setState({ sortClass: "fa fa-caret-up pull-right" }, () => {
  //       console.log("sortClass**:", this.state.sortClass);
  //     });
  //   }

  //   this.props.orderData.Orders.slice().reverse();
  // };

  async componentDidMount() {
    this.setState({ orderArray: this.props.orderData.Orders });
    await this.props.setListOrders(this.props.orderData?.Orders);
    await this.props.setPages(Math.ceil(this.props.listOrders?.length / this.props.perPage));

    //console.log("total pages: ", this.props.pages);
  }

  getSummary = async event => {
    this.props.setLoader(true);
    await this.props.fetchOrderSummary(event.Id, localStorage.getItem("Token"));
    this.props.setLoader(false);
    this.props.history.push("/OrderSummary");
  };

  handlePageClick = event => {
    let page = event.selected;
    this.props.setPage(page);
  };

  render() {
    const { page, perPage, pages, listOrders } = this.props;
    let items =
      listOrders
        ?.filter(ord => {
          if (this.state.search == "") {
            return true;
          }
          else if (
            ord?.OrderNumberStr?.includes(`${this.state.search}`) ||
            ord?.OrderDateStr?.includes(`${this.state.search}`) ||
            ord?.NoOfItems == parseInt(this.state.search, 10)
          ) {
            return true;
          }
        })
        ?.reverse()
        .slice(page * perPage, (page + 1) * perPage) || [];

    let filteredOrders =
      items.slice().map((order, index) => {
        return (
          <tr key={index} >
            <td>
              {order.ShowFeedBackNotification == false && (
                <a style={{cursor:"pointer"}}
                   onClick={() => this.getSummary(order)}>
                  {order.OrderNoStr}
                  {"    "}
                </a>
              )}

              {order.ShowFeedBackNotification ==  false && (
                <span className='label label-primary' style={{ color: "white" }}>
                  Pending
                </span>
              )}
              {order.ShowFeedBackNotification && (
                <a >
                  {order.OrderNoStr} {"    "}
                </a>
              )}

              {order.ShowFeedBackNotification && <span className='label label-primary'>Done</span>}
            </td>
            <td>{order.OrderDateStr}</td>
            <td>{order.NoOfItems}</td>
          </tr>
        );
      }) || [];

    // console.log("orderComponent :", this.props.orderData);
    return (
      <>
        <div className='row heading_bar'>
          <div className='wrapper'>
            <h2>Order History</h2>
          </div>
        </div>

        <div className='row order_history'>
          <div className='wrapper'>
            <form name='search_form' className='search_form'>
              <div className='form-group'>
                <input
                  type='text'
                  className='search form-control'
                  name='search'
                  placeholder='Search'
                  value={this.state.search}
                  onChange={e => {
                    this.setState({ search: e.target.value }, () => {
                      console.log(this.state.search);
                    });
                  }}
                />
              </div>
              <button>
                <i className='fa fa-search'></i>
              </button>
            </form>

            <div className='table-responsive orderHistoryTable'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th className='col-sm-5' onClick={() => this.sortOrder("OrderNoStr")}>
                      Invoice #.{" "}
                      {/* <i
                        className={
                          this.state.sortString == "OrderNoStr"
                            ? `${this.state.sortClass}`
                            : "fa fa-sort pull-right"
                        }
                      ></i> */}
                    </th>
                    <th className='col-sm-2' onClick={() => this.sortOrder("OrderDateStr")}>
                      Invoice Date{" "}
                      {/* <i
                        className={
                          this.state.sortString == "OrderDateStr"
                            ? `${this.state.sortClass}`
                            : "fa fa-sort pull-right"
                        }
                      ></i> */}
                    </th>
                    <th className='col-sm-1' onClick={() => this.sortOrder("NoOfItems")}>
                      Items{" "}
                      {/* <i
                        className={
                          this.state.sortString == "NoOfItems"
                            ? `${this.state.sortClass}`
                            : "fa fa-sort pull-right"
                        }
                      ></i> */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.orderData.Orders && filteredOrders}
                  {this.props.orderData?.Orders?.length == 0 ? (
                    <tr>
                      <td colspan='6'>
                        <p className='text-center'>No Data Available</p>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              { this.props.orderData?.Orders?.length > 5 ?
                <ReactPaginate
                previousLabel={"< "}
                nextLabel={" >"}
                pageCount={pages}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              /> : null
              }          
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setListOrders,
  setPage,
  setPages,
  fetchOrderSummary,
  setLoader,
};
const mapStateToProps = ({ Auth }) => {
  let { orderData, ordersArray, isLogin, listOrders, page, pages, perPage, orderSummary } = Auth;
  return {
    orderData,
    ordersArray,
    isLogin,
    listOrders,
    page,
    pages,
    perPage,
    orderSummary,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrders);
