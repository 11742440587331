import React, { Component } from "react";
import { Link } from "react-router-dom";

export class DetailFooter extends Component {
  static displayName = DetailFooter.name;

  render() {
    return (
      <div class="row">
      <div
        className="top_footer">
        <div className="wrapper clearfix">
          <div className="col-md-10 footer_menu">
            <ul>
              <li>
                <a>
                  <Link exact={true} activeClassName="active" to="/">
                    Home
                  </Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to={`/Pricing`} activeClassName="active">
                    Pricing
                  </Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to={`/CoverageArea`} activeClassName="active">
                    Coverage Area
                  </Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to={`/AboutUs`} activeClassName="active">
                    About Us
                  </Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to={`/Terms`} activeClassName="active">
                    Terms
                  </Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to={`/ContactUs`} activeClassName="active">
                    Contact Us
                  </Link>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <a
              href="https://twitter.com/?lang=en"
              target="_blank"
              className="ico_twitter"
            ></a>
            <a
              href="https://www.linkedin.com/uas/login"
              target="_blank"
              className="ico_linkedin"
            ></a>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              className="ico_facebook"
            ></a>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
