import React, { Component }  from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux'
import { fetchLoginDetails } from '../../../store/action/Auth';

export const orderPageEnum = {
    Items :  1,
    Signup : 2,
    Address : 3,
    Time :  4,
    Summary :  5,
 
}
export const Customer = {
    fname : '',
    lname : '',
    mobile : 0,
    email : '',
    password : '',
    cpassword : '',
}

export const updateState = (page, isLogin)=>{
    let nextState = "";
    if(page == orderPageEnum.Items){
        nextState = "Order"; //pageId=1
    }
    if(page == orderPageEnum.Address){
        nextState = "OrderAddress";   //pageId=3
    }
    if (page == orderPageEnum.Time) {
        console.log("Date");
        nextState = "TimeSlot";
        // var day = vm.timeSlot.PickupSlotId.toString();

        // vm.timeSlot.PickUpDay = parseInt(day.substring(0, day.length - 1), 10);
        // day = vm.timeSlot.DropSlotId.toString();
        // vm.timeSlot.DropDay = parseInt(day.substring(0, day.length - 1), 10);
    }

    if (page == orderPageEnum.Signup) {
        if (!isLogin) {
            nextState = "SignUp";
       } else 
        {
            nextState = "OrderAddress";
            // if (vm.viewModel.Order.AddressId == 0)
            // {
            //     nextPage = "orderAddress";
            // }
            // else if (vm.viewModel.Order.AddressId > 0)
            // {
            //     nextPage = "Address";
            // }
          
        } 
    }
    if (page === orderPageEnum.Summary) {
        //completeaddress(vm.viewModel);
        nextState= "OrderDetail";
    }

    //Save data to order service for later use.
   // order.orderCart.OrderVm = vm.viewModel;
   // order.orderCart.timeSlot = vm.timeSlot;
    //mark cart as filled.
    //order.orderCart.IsCartEmpty = false;
     
    //order.orderCart.OrderState = nextPage;

    if (nextState !== "") return nextState;
}

