import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  changePassword,
  setLoginStatus,
  setSignInPopUp,
  setLoader,
} from "../store/action/Auth";

const schema = Yup.object().shape({
//   currentPassword: Yup.string()
//     .required("Current Password is Required.")
//     .min(8, "Minimum 8 characters are required.")
//     .max(16, "Maximum 16 characters are allowed."),
  newPassword: Yup.string()
    .required("Must Enter New Password.")
    .min(8, "Minimum 8 characters are required.")
    .max(16, "Maximum 16 characters are allowed."),
  confirmPassword: Yup.string().when("newPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("newPassword")],
      "Both passwords needs to be the same!"
    ),
  }),
});

class ResetPassword extends Component {

    async componentDidMount(){

    }

  render() {
    return (
      <>
        <div className="row heading_bar">
          <div className="wrapper">
            <h2>Reset Password</h2>
          </div>
        </div>
        <div className="row">
          <div className="wrapper clearfix change_password">
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
                showPassword: false,
              }}
              validationSchema={schema}
              validateOnBlur
              onSubmit={async (values) => {
                this.props.setLoader(true);
                // await this.props.changePassword(
                //   {
                //     Password: values.newPassword,
                //     EntityType: "customer",
                //   },
                //   localStorage.getItem("Token")
                // );
                this.props.setLoader(false);
                values.currentPassword = "";
                values.newPassword = "";
                values.showPassword = "";

                console.log(
                  "change password response : ",
                  this.props.changePasswordResponse
                );

                if (
                  this.props.changePasswordResponse?.ResultMessages[0]
                    ?.MessageType === "success"
                ) {
                  toast.success(
                    this.props.changePasswordResponse?.ResultMessages[0]
                      ?.Message
                  );
                } else {
                  toast.error(
                    this.props.changePasswordResponse?.ResultMessages[0]
                      ?.Message
                  );
                }

                localStorage.removeItem("Id");
                localStorage.removeItem("Token");
                //console.log("onclick");
                this.props.setLoginStatus(false);
                //this.props.history.push("/");
                this.props.setSignInPopUp(!this.props.signinPopUp);
                toast.info("Please Sign In with new password.");
              }}
            >
              {(props) => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                } = props;

                return (
                  <form
                    name="ctrl_frm"
                    noValidate
                    role="form"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                   {/* {errors.currentPassword && touched.currentPassword && (
                      <div className="errorMessage">
                        {errors.currentPassword}
                      </div>
                    )}
                    <div class="col-sm-12 field">
                      {values.currentPassword == "" ? null : (
                        <label
                          htmlFor="currentPassword"
                          className="show-hide ng-hide"
                        >
                          Current Password
                        </label>
                      )}
                      <input
                        className="form-control"
                        type={values.showPassword ? "text" : "password"}
                        id="currentpassword"
                        name="currentPassword"
                        placeholder="Current Password"
                        value={values.currentPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        maxLength={25}
                      />
                      </div>*/}
                    {errors.newPassword && touched.newPassword && (
                      <div className="errorMessage">{errors.newPassword}</div>
                    )}

                    <div className="col-sm-12 field">
                      {values.newPassword == "" ? null : (
                        <label
                          htmlFor="newPassword"
                          className="show-hide ng-hide"
                        >
                          New Password
                        </label>
                      )}
                      <input
                        className="form-control"
                        type={values.showPassword ? "text" : "password"}
                        id="npassword"
                        name="newPassword"
                        placeholder="New Password"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        maxLength={25}
                      />
                    </div>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="errorMessage">
                        {errors.confirmPassword}
                      </div>
                    )}
                    <div className="col-sm-12 field">
                      {values.confirmPassword == "" ? null : (
                        <label
                          htmlFor="confirmPassword"
                          className="show-hide ng-hide"
                        >
                          Confirm New Password
                        </label>
                      )}
                      <input
                        className="form-control"
                        type={values.showPassword ? "text" : "password"}
                        id="conpassword"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        maxLength={25}
                      />
                    </div>

                    <div className="col-sm-12">
                      <div className="checkbox">
                        <label className="checkbox-inline"  >
                          <input
                            type="checkbox"
                            name="showPassword"
                            value={values.showPassword}
                            onChange={handleChange}
                            checked={values.showPassword}
                          />
                          <label htmlFor="checkbox" onClick={()=> setFieldValue("showPassword", !values.showPassword)}>Show password</label>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <br />
                      <div className="row">
                        {/*<NavLink
                          className="btn "
                          to={{
                            pathname: "/Profile",
                            state: this.props.location.state,
                          }}
                        >
                          Back
                        </NavLink>*/}
                        <button
                          type="submit"
                          name="submit"
                          className="btn btn-primary pull-right"
                          disabled={
                            errors.newPassword || values.newPassword === ""
                              ? true
                              : errors.confirmPassword ||
                                values.confirmPassword === ""
                              ? true
                              : false
                          }
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = {
  changePassword,
  setLoginStatus,
  setSignInPopUp,
  setLoader,
};

const mapStateToProps = ({ Auth }) => {
  let { loginData, customerDetails, changePasswordResponse, signinPopUp } =
    Auth;
  return { loginData, customerDetails, changePasswordResponse, signinPopUp };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
