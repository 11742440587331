import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPricingDetails, setLoader } from "../store/action/Auth";

const color = "#ffffff";

class Pricing extends Component {
  static displayName = Pricing.name;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      selectedCategory: 10,
    };
  }

  async componentDidMount() {
    this.props.setLoader(true);
    this.props.fetchPricingDetails();
    this.props.setLoader(false);
  }

  render() {
    return (
      <>
        <div className='row heading_banner'>
          <img src='faq_banner.jpg' alt='' />
        </div>
        <div className='row'>
          <div className='wrapper clearfix items_wrap faq_wrap'>
            <h3>Service list</h3>
            <div className='col-md-12 items_list'>
              {
                <ul className='nav nav-tabs'>
                  {this.props.data.Categories ? (
                    this.props.data.Categories.map((cat, index) => {
                      return (
                        <li key={index} className={cat.Id == this.state.selectedCategory ? "active" : ""}>
                          <a
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                selectedCategory: cat.Id,
                              });
                            }}
                          >
                            {cat["Description"]}
                          </a>
                        </li>
                      );
                    })
                  ) : (
                    <div
                      className='row'
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      {/* <PuffLoader color="#000000" loading={true} /> */}
                    </div>
                  )}
                </ul>
              }
              <div className='tab-content'>
                <div id='mens_tab' className='tab-pane fade in active'>
                  <div className='table-responsive'>
                    <table cellspacing='0' cellpadding='0'>
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          {/* <th>
                            {this.state.selectedCategory == 31
                              ? "Rates"
                              : "WASH AND IRON"}
                          </th>
                          <th>
                            {this.state.selectedCategory == 31 ||
                            this.state.selectedCategory == 30
                              ? ""
                              : "DRY CLEAN AND STEAM"}
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.props.data.Categories &&
                            this.props.data.Items.map((item, index) => {
                              return (
                                item.ItemCategory == this.state.selectedCategory && (
                                  <tr key={index}>
                                    <td>{item.Description}</td>
                                    <td>
                                      {/* {item.LaundryPrice === 0
                                      ? ""
                                      : item.LaundryPrice === -1
                                      ? ""
                                      : "Rs."}{" "}
                                    {item.LaundryPrice === 0
                                      ? "-"
                                      : item.LaundryPrice === -1
                                      ? "FREE"
                                      : item.LaundryPrice} */}
                                    </td>
                                    <td>
                                      {/* {item.DryCleanPrice === 0
                                      ? ""
                                      : item.ItemCategory == 31 ||
                                        item.ItemCategory == 30
                                      ? ""
                                      : "Rs."}{" "}
                                    {item.DryCleanPrice === 0
                                      ? item.ItemCategory == 31 ||
                                        item.ItemCategory == 30
                                        ? ""
                                        : "-"
                                      : item.ItemCategory == 31 ||
                                        item.ItemCategory == 30
                                      ? ""
                                      : item.DryCleanPrice} */}
                                    </td>
                                  </tr>
                                )
                              );
                            })
                          // : (
                          //   <>
                          //     {/* <PuffLoader color="#000000" loading={true} />{" "} */}
                          //   </>
                          // )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = { fetchPricingDetails, setLoader };

const mapStateToProps = ({ Auth }) => {
  let { data, isLoading } = Auth;
  return { data, isLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
