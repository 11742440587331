import React, { Component } from "react";
import WOW from "wowjs";
export class PickWashSection extends Component {
  static displayName = PickWashSection.name;


  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="row banner-pickwash">
        <img src={require("../../images/fot-banner.jpg")} alt="" />
        <div className="inner-banner-section">
          <div className="wrapper">
            <div className="pos-text">
              <h4 className="wow bounceIn" style={{ visibility: "visible" }}>
                Pick Wash & deliver
              </h4>
              <p className="wow bounceIn" style={{ visibility: "visible" }}>
                at your Door Step
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
