import { toast } from "react-toastify";
import { baseUrl } from "../../Api";

export const setPricingData = data => ({
  type: "SET_ITEMS_DATA",
  payload: data,
});

export const resetCustomerDetails = () => ({
  type: "RESET_CUSTOMER_DETAILS",
});

export const setrefertofriend = data => ({
  type: "SET_REFER_FRIEND_POPUP",
  payload: data,
});

export const setcheckservice = data => ({
  type: "SET_CHECK_SERVICE_POPUP",
  payload: data,
});
//new
export const listsearchedareas = data => ({
  type: "SET_LIST_SEARCHED_AREAS",
  payload: data,
});

export const listsearchedareaspopup = data => ({
  type: "SECOND_SEARCHED_AREA_POPUP",
  payload: data,
});

export const setAboutUsData = data => ({
  type: "SET_ABOUTUS_DATA",
  payload: data,
});

export const userSignout = () => ({
  type: "SET_USER_SIGNOUT",
});

export const setNavbar = data => ({
  type: "SET_NAVBAR",
  payload: data,
});
export const setLoginData = data => ({
  type: "SET_LOGIN_DATA",
  payload: data,
});

export const setLoginStatus = data => ({
  type: "SET_LOGIN_STATUS",
  payload: data,
});

export const setSignupModal = data => ({
  type: "SET_SIGNUP_MODAL",
  payload: data,
});

export const setDefaultAddressId = data => ({
  type: "SET_DEFAULT_ADDRESS_ID",
  payload: data,
});

export const setServiceAreaPopup = data => ({
  type: "SET_SERVICE_AREA_POPUP",
  payload: data,
});

export const setDefaultAddressUpdateResponse = data => ({
  type: "SET_DEFAULT_ADDRESS_UPDATE_RESPONSE",
  payload: data,
});

export const setDeleteAddressResponse = data => ({
  type: "SET_DELETE_ADDRESS_RESPONSE",
  payload: data,
});

export const setCustomerDetails = data => ({
  type: "SET_CUSTOMER_DETAILS",
  payload: data,
});

export const setOrderHistory = data => ({
  type: "SET_ORDER_HISTORY",
  payload: data,
});

export const setOrdersData = data => ({
  type: "SET_ORDERS_DATA",
  payload: data,
});

export const setLoader = data => ({
  type: "SET_LOADER",
  payload: data,
});

export const setListOrders = data => ({
  type: "SET_LIST_ORDERS",
  payload: data,
});
export const setPages = data => ({
  type: "SET_PAGES",
  payload: data,
});
export const setPage = data => ({
  type: "SET_PAGE",
  payload: data,
});

export const setChangePasswordResponse = data => ({
  type: "SET_CHANGEPASSWORD_RESPONSE",
  payload: data,
});

export const setSignInPopUp = data => ({
  type: "SET_SIGNIN_POPUP",
  payload: data,
});

export const setTermsConditionsPopUp = data => ({
  type: "SET_TERMS_CONDITIONS_POPUP",
  payload: data,
});

export const setCurrentPage = data => ({
  type: "SET_CURRENT_PAGE",
  payload: data,
});

export const setOrderObject = data => ({
  type: "SET_ORDER_OBJECT",
  payload: data,
});

export const setForgetPasswordPopUp = data => ({
  type: "SET_FORGETPASSWORD_POPUP",
  payload: data,
});

export const setEditCustomerProfileResponse = data => ({
  type: "SET_EDIT_CUSTOMER_PROFILE_RESPONSE",
  payload: data,
});

export const setOrdersArray = data => ({
  type: "SET_ORDERS_ARRAY",
  payload: data,
});

export const setForgetPasswordResponse = data => ({
  type: "SET_FORGETPASSWORD_RESPONSE",
  payload: data,
});

export const setPostForgetPasswordPopup = data => ({
  type: "SET_POST_FORGETPASSWORD_POPUP",
  payload: data,
});

export const setSelectedPickupDate_proto = data => ({
  type: "SET_SELECTED_PICKUP_DATE_PROTO",
  payload: data,
});

export const setSelectedPickupTimeSlotId_proto = data => ({
  type: "SET_SELECTED_PICKUP_TIME_SLOT_ID_PROTO",
  payload: data,
});

export const setAddCustomerAddressResponse = data => ({
  type: "SET_ADD_CUSTOMER_ADDRESS_RESPONSE",
  payload: data,
});

export const setEditCustomerAddressResponse = data => ({
  type: "SET_EDIT_CUSTOMER_ADDRESS_RESPONSE",
  payload: data,
});

export const setSelectedAddressInfo = data => ({
  type: "SET_SELECTED_ADDRESS_INFO",
  payload: data,
});
export const setOrderItems = data => ({
  type: "SET_ORDERS_ITEM",
  payload: data,
});

export const setSignUpData = data => ({
  type: "SET_SIGNUP_INFO",
  payload: data,
});

export const setPickupSchedule_proto = data => ({
  type: "SET_PICKUP_SCHEDULE_PROTO",
  payload: data,
});

export const setAddressInfo = data => ({
  type: "SET_ADDRESS_INFO",
  payload: data,
});

export const setPickUpSchedule = data => ({
  type: "SET_PICKUP_SCHEDULE",
  payload: data,
});

export const setDropSchedule = data => ({
  type: "SET_DROP_SCHEDULE",
  payload: data,
});

export const setDeliveryTimeId = timeId => ({
  type: "SET_DELIVERYTIME_ID",
  payload: timeId,
});

export const setPath= (path) => ({
  type: "SET_PATH",
  payload: path,
});

export const setSelectedPickUpSchedule = schedule => ({
  type: "SET_SELECTED_PICKUP_SCHEDULE",
  payload: schedule,
});

export const setOrderPageId = pageId => ({
  type: "SET_ORDER_PAGE_ID",
  payload: pageId,
});

export const setOrderSummary = summary => ({
  type: "SET_ORDER_SUMMARY",
  payload: summary,
});

export const setCompleteAddress = data => ({
  type: "SET_COMPLETE_ADDRESS",
  payload: data,
});

export const fetchPricingDetails = () => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/Item/GetAllItems`)
      .then(res => res.json())
      .then(
        result => {
          dispatch(setPricingData(result));
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const fetchAboutUsDetails = () => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/ContentManagement/GetPageContent?pageId=${1}`)
      .then(res => res.json())
      .then(
        result => {
          dispatch(setAboutUsData(result));
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const fetchOrderSummary = (id, Token) => {
  return async function (dispatch) {
    var url = `http://${baseUrl}/api/Order?orderId=${parseInt(id, 10)}`;
    var AuthToken = Token;
    console.log("token: ", Token);
    await fetch(url, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json",
        Authorization: AuthToken,
        "Content-Type": "application/json",
      }),
    })
      .then(resp => resp.json())
      .then(json => {
        console.log("___>> ", json);
        dispatch(setOrderSummary(json));
      })
      .catch(error => console.log(error));
  };
};

export const fetchOrderScheduleByAddressId = (slotType, startDate, id, Token) => {
  return async function (dispatch) {
    var url = `http://${baseUrl}/api/OrderScheduler/GetTimeSlotsAvailability1?timeSlotType=${slotType}&startDate=${startDate}&addressId=${parseInt(
      id,
      10
    )}`;
    var AuthToken = Token;
    await fetch(url, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json",
        Authorization: AuthToken,
        "Content-Type": "application/json",
      }),
    })
      .then(resp => resp.json())
      .then(json => {
        console.log("___orderSchedule>> ", json);
        dispatch(setPickUpSchedule(json));
      })
      .catch(error => console.log(error));
  };
};

export const fetchForgetPasswordDetails = email => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/ForgetPassword?email=${email}`, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          console.log("result **: ", result);
          dispatch(setForgetPasswordResponse(result));
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const setLoginDetails = (Id, Token) => {
  return async function (dispatch) {
    var url = `https://${baseUrl}/api/Customer/GetCustomerProfile?customerId=${Id}`;
    var AuthToken = Token;
    await fetch(url, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json",
        Authorization: AuthToken,
        //  'X-Custom-Header': 'header value',
        "Content-Type": "application/json",
      }),
    })
      .then(resp => resp.json())
      .then(json => {
        dispatch(setCustomerDetails(json));
        dispatch(setLoginStatus(true));
        dispatch(setSignInPopUp(false));
        json.Addresses.filter(addr => {
          if (addr.IsDefault) {
            dispatch(setDefaultAddressId(addr.Id));
          }
        });
      })
      .catch(error => console.log(error));
  };
};

export const setOrderDetails = Token => {
  return async function (dispatch) {
    var url = `https://${baseUrl}/api/OrderStatus/GetCustomerOrders`;
    var AuthToken = Token;
    console.log("token: ", Token);
    await fetch(url, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      cache: "no-cache",

      headers: new Headers({
        Accept: "*/*",
        Authorization: localStorage.getItem("Token"),
        "Content-Type": "application/json",
      }),
    })
      .then(resp => resp.json())
      .then(json => {
        console.log("___", json);
        dispatch(setOrdersData(json));
        dispatch(setOrdersArray(json.Orders.reverse()));
      })
      .catch(error => console.log(error));
  };
};

export const fetchAddressDetails = () => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/Order?orderId=0`, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      cache: "no-cache",
      headers: new Headers({
        Accept: "*/*",
        Authorization: localStorage.getItem("Token"),
        "Content-Type": "application/json",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          dispatch(setAddressInfo(result));
          dispatch(setOrderObject(result));
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const fetchSelectedAddress = (addressId, entityId) => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/Address?addressId=${addressId}&entityId=${entityId}&entityType=${10}`)
      .then(res => res.json())
      .then(
        result => {
          dispatch(setSelectedAddressInfo(result));
          console.log(result);
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const fetchLoginDetails = viewModel => {
  return async function (dispatch) {
    return await postData(`https://${baseUrl}/api/Login/PostCustomerLogin`, viewModel)
      .then(data => {
        if (data.Username !== "") {
          console.log("userName fetchLoginDetails** : ", data.Username);
          dispatch(setLoginData(data));
        }
      })
      .catch(error => console.log(error));
  };
};

//popup

export const saveCheckService = (viewModel, callbackFunc) => {
  return async function (dispatch) {
    return await postData( `https://${baseUrl}/api/Address/PostSearchAddress`, viewModel).then(result => {
      dispatch(setcheckservice(result));  
      console.log(result.searchedAreas.length > 0);
if(result.searchedAreas.length > 0){
  // dispatch(listsearchedareas(result.searchedAreas))
  callbackFunc(result.searchedAreas);
}
else {
  callbackFunc([]);
}
      
    }).catch(error => toast.error(error));
  };
};

export const saveReferFriend = viewModel => {
  return async function (dispatch) {
    return await postChangePassword(
      `https://${baseUrl}/api/Order/PostPromotionReferral`,
      viewModel,
      localStorage.getItem("Token")
    )
      .then(result => {
        dispatch(setrefertofriend(result));
        console.log(result);
        if (result.HasErrors == false) toast.success(`${result.ReferralPhoneNo} has been successfully referred`);
      })
      .catch(error => {
        if (error.HasErrors == true) {
          toast.error(`${error.ResultMessages[0].Message} `);
        }
        console.log(error);
      });
  };
};

export const saveSignUp = viewModel => {
  return async function (dispatch) {
    return await postData(`https://${baseUrl}/api/SignUp/PostSignUp`, viewModel)
      .then(result => {
        dispatch(setSignUpData(result));
      })
      .catch(error => console.log(error));
  };
};

export const fetchPickUpSchedule = (slotType, orderDate) => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/OrderScheduler?startDate=${orderDate}&timeSlotType=${slotType}`)
      .then(res => res.json())
      .then(
        result => {
          dispatch(setPickUpSchedule(result));
          dispatch(setPickupSchedule_proto(result));
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const fetchPickUpSchedule_proto = (day, slotType) => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/OrderScheduler?day=${day}&timeSlotType=${slotType}`, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      cache: "no-cache",

      headers: new Headers({
        Accept: "*/*",
        Authorization: localStorage.getItem("Token"),
        //  'X-Custom-Header': 'header value',
        "Content-Type": "application/json",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          console.log("result => ", result);
          dispatch(setPickupSchedule_proto(result));
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const fetchDropSchedule = (slotType, orderDate) => {
  return async function (dispatch) {
    return await fetch(`https://${baseUrl}/api/OrderScheduler?startDate=${orderDate}&timeSlotType=${slotType}`)
      .then(res => res.json())
      .then(
        result => {
          dispatch(setDropSchedule(result));
        },
        error => {}
      )
      .catch(error => console.log(error));
  };
};

export const setDefaultAddress = (viewModel, accessToken) => {
  return async function (dispatch) {
    return await postDefaultAddress(`https://${baseUrl}/api/Address/PostAddress`, viewModel, accessToken)
      .then(data => {
        console.log("new request default address", data);
        dispatch(setDefaultAddressUpdateResponse(data));
      })
      .catch(error => console.log(error));
  };
};

export const deleteAddress = (viewModel, accessToken) => {
  return async function (dispatch) {
    return await postDefaultAddress(`https://${baseUrl}/api/Address/PostAddress`, viewModel, accessToken)
      .then(data => {
        console.log("address has been deleted", data);
        dispatch(setDeleteAddressResponse(data));
      })
      .catch(error => console.log(error));
  };
};

export const changePassword = (viewModel, accessToken) => {
  return async function (dispatch) {
    return await postChangePassword(`https://${baseUrl}/api/ChangePassword/PostChangePassword`, viewModel, accessToken)
      .then(data => {
        console.log("Your password has been changed", data);
        dispatch(setChangePasswordResponse(data));
      })
      .catch(error => console.log(error));
  };
};

export const editCustomerProfile = (viewModel, accessToken) => {
  return async function (dispatch) {
    return await postEditCustomerProfile(`https://${baseUrl}/api/Customer/PostCustomerProfile`, viewModel, accessToken)
      .then(data => {
        console.log("Your profile has been updated. ", data);
        dispatch(setEditCustomerProfileResponse(data));
      })
      .catch(error => console.log(error));
  };
};

export const addCustomerAddress = (viewModel, accessToken) => {
  return async function (dispatch) {
    return await postCustomerAddress(`https://${baseUrl}/api/Address/PostAddress`, viewModel, accessToken)
      .then(data => {
        console.log("Your address has been updated. ", data);
        dispatch(setAddCustomerAddressResponse(data));
      })
      .catch(error => console.log(error));
  };
};

export const editCustomerAddress = (viewModel, accessToken) => {
  return async function (dispatch) {
    return await postEditCustomerAddress(`https://${baseUrl}/api/Address/PostAddress`, viewModel, accessToken)
      .then(data => {
        console.log("Your address has been edited. ", data);
        dispatch(setEditCustomerAddressResponse(data));
      })
      .catch(error => console.log(error));
  };
};

const postData = async (url = "", data = {}) => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",

      //'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

const postDefaultAddress = async (url = "", data = {}, token = "") => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
    headers: new Headers({
      Accept: "application/json, text/plain, */*",
      Authorization: token,
      //  'X-Custom-Header': 'header value',
      "Content-Type": "application/json;charset=UTF-8",
    }),
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({ address: data }),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

const postChangePassword = async (url = "", data = {}, token = "") => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *, omit
    headers: {
      Accept: "application/json",
      Authorization: token,

      "Content-Type": "application/json",
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

const postEditCustomerProfile = async (url = "", data = {}, token = "") => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *, omit
    headers: {
      Accept: "application/json",
      Authorization: token,

      "Content-Type": "application/json",
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

const postCustomerAddress = async (url = "", data = {}, token = "") => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *, omit
    headers: {
      Accept: "application/json",
      Authorization: token,

      "Content-Type": "application/json",
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

const postEditCustomerAddress = async (url = "", data = {}, token = "") => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *, omit
    headers: {
      Accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

const placeOrder = async (url = "", data = {}, token = "") => {
  try {
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "include", // include, *same-origin, omit
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
        Authorization: token,
        "Content-Type": "application/json;charset=UTF-8",
      }),
      body: JSON.stringify(data),
    });
    const result = response.json();
    return result; // parses JSON response into native JavaScript objects
  } catch (Exception) {
    console.log(Exception);
  }
};

export const postWebOrder = viewModel => {
  return async function (dispatch) {
    return await placeOrder(`https://${baseUrl}/api/Order/PostWebOrder`, viewModel, localStorage.getItem("Token"))
      .then(data => {
        console.log(data);
        if (data.ResultMessages.length > 0) {
          data.ResultMessages.forEach(res => {
            toast.success(res.Message);
          });
        }
      })
      .catch(error => console.log(error));
  };
};

export const postOTPRequest = (viewModel, callback) => {
  console.log({viewModel});
  return async function (dispatch) {
    return await otpRequest(`https://${baseUrl}/api/OTP/SendOTPToPhone`, viewModel)
      .then(data => {
        console.log({response: data});
        if (!data.HasErrors) callback(true);
        if (data.HasErrors) callback(false);
        if (data.ResultMessages.length > 0) {
          data.ResultMessages.forEach(res => {
            if(res.MessageType == "danger") {
              if(res.Message == "Phone # already registered"){
                toast.info(`Phone ${viewModel.PhoneNumber} already registered`);
                dispatch(setSignInPopUp(true));
                dispatch(setSignupModal(false));
              }
              else {
                toast.error(res.Message);
              }
          }
            else{
            toast.success(res.Message);
            }
          });
        }
      })
      .catch(error => callback(false));
  };
};

export const postVerifyOTP = (viewModel, callback) => {
  return async function (dispatch) {
    return await otpRequest(`https://${baseUrl}/api/OTP/VerifyPhoneOTP`, viewModel)
      .then(data => {
        console.log(data);
        if (!data.IsVerified) callback(false);
        if (data.IsVerified) callback(true);
        if (data.ResultMessages.length > 0) {
          data.ResultMessages.forEach(res => {
            toast.success(res.Message);
          });
        }
      })
      .catch(error => callback(false));
  };
};

const otpRequest = async (url = "", data = {}) => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *, omit
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

// /api/ForgetPassword/PostForgetPassword

export const postForgetPassword = (viewModel, callback) => {
  return async function (dispatch) {
    return await otpRequest(`https://${baseUrl}/api/ForgetPassword/PostForgetPassword`, viewModel)
      .then(data => {
        console.log(data);
        setForgetPasswordResponse(data);
        callback(data);
        if (data.ResultMessages.length > 0) {
          data.ResultMessages.forEach(res => {
            toast.success(res.Message);
          });
        }
      })
      .catch(error => callback(error));
  };
};

export const postResetPassword = (viewModel, callback) => {
  return async function (dispatch) {
    return await otpRequest(`https://${baseUrl}/api/ResetPassword/PostResetPassword`, viewModel)
      .then(data => {
        console.log(data);
        // setForgetPasswordResponse(data);
        callback(data);
        if (data.ResultMessages.length > 0) {
          data.ResultMessages.forEach(res => {
            toast.success(res.Message);
          });
        }
      })
      .catch(error => callback(error));
  };
};
