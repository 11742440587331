import React, { Component } from "react";
import { OrderProgressBar } from "./OrderProgressBar";
import { connect } from "react-redux";
import {
  postWebOrder,
  setOrderDetails,
  setLoader,
  setLoginDetails,
  setSelectedPickupDate_proto,
  setSelectedPickupTimeSlotId_proto,
  setTermsConditionsPopUp,
  setOrderItems
} from "../../store/action/Auth";
import TimeSlot from "./OrderTime";
import OrderItem from "./OrderItem";
import OrderAddress from "./OrderAddress";
import history from "../../history";
import TermsConditions from "../Modal/TermsConditions";
class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "OrderDetail",
      isChecked: false,
    };
  }

  back = () => {
    this.setState({ path: "Address" });
  };
  acceptTermsCondition = (isChecked) => {
    this.setState({ isChecked: isChecked });
  };
  placeOrder = async (e) => {
    e.preventDefault();
    //console.log("hello");
    // let viewModel = {
    //   Addresses: this.props.customerDetails.Addresses,
    //   Cities: this.props.address?.Cities,
    //   CityAreas: this.props.address?.CityAreas,
    //   CreatedBy: null,
    //   CreatedById: parseInt(localStorage.getItem("Id"), 10),
    //   DeliveryTime: [],
    //   HasErrors: false,
    //   Id: 0,
    //   InsertDate: new Date().toISOString(),
    //   IsActive: true,
    //   LastUpdatedBy: null,
    //   LastUpdatedById: null,
    //   ModifiedDate: null,
    //   Order: {
    //     Address: this.props.completeAddress,
    //     AddressId: this.props.completeAddress.Id,
    //     Crease: false,
    //     CreatedBy: null,
    //     CreatedById: parseInt(localStorage.getItem("Id"), 10),
    //     Customer: this.props.customerDetails.Customer,
    //     CustomerId: parseInt(localStorage.getItem("Id"), 10),
    //     DeliveryDateTime: new Date(),
    //     DeliveryPreference: null,
    //     DeliveryTimeId: this.props.DeliveryTimeId,
    //     DropSelectedTime: null,
    //     DropTimeSlotId: 5,
    //     FranchiseId: 1,
    //     HasErrors: false,
    //     Id: 0,
    //     InsertDate: new Date().toISOString(),
    //     Instructions: this.props.viewModel.OrderItem.specialInstruction,
    //     InvoiceTypeId: 1,
    //     Invoices: [],
    //     IsActive: true,
    //     IsCancelled: false,
    //     IsTermCondition: true,
    //     LastUpdatedBy: null,
    //     LastUpdatedById: null,
    //     ModifiedDate: null,
    //     NoOfItems: this.props.viewModel.OrderItem.NoOfItems,
    //     OrderDate: new Date().toISOString(),
    //     OrderDateStr: new Intl.DateTimeFormat("en-US", {
    //       month: "2-digit",
    //       day: "2-digit",
    //       year: "numeric",
    //     }).format(new Date()),
    //     OrderNo: 0,
    //     OrderNoStr: null,
    //     OrderStatusId: 10,
    //     OrderStatusName: "Open",
    //     OrderTypeId: 10,
    //     PickedByDriverId: null,
    //     PickupDateTime:
    //       this.props.selectedPickUpSchedule.PickupSelectedDate.toString(),
    //     PickupSelectedTime:
    //       this.props.selectedPickUpSchedule.PickupSelectedTime.toString(),
    //     PickupTimeSlotId:
    //       this.props.selectedPickUpSchedule.PickupSelectedSlotId,
    //     ResultMessages: [],
    //     ShowFeedBackNotification: false,
    //     Starch: false,
    //     Year: new Date().getFullYear(),
    //     addressId: this.props.completeAddress.Id,
    //     DropSchedule: this.props.dropSchedule,
    //     PickupSchedule: this.props.pickUpSchedule,
    //   },
    //   ResultMessages: [],
    // };
    this.props.orderObject.Addresses = this.props.customerDetails?.Addresses;
    this.props.orderObject.CreatedById = parseInt(
      localStorage.getItem("Id"),
      10
    );
    this.props.orderObject.Order.Address = this.props.completeAddress;
    this.props.orderObject.Order.AddressId = this.props.completeAddress?.Id;
    this.props.orderObject.Order.CreatedById = parseInt(
      localStorage.getItem("Id"),
      10
    );
    this.props.orderObject.Order.Customer = this.props.customerDetails?.Customer;
    this.props.orderObject.Order.CustomerId = parseInt(
      localStorage.getItem("Id"),
      10
    );
    this.props.orderObject.Order.DeliveryTimeId = this.props.DeliveryTimeId;
    this.props.orderObject.Order.Instructions =
      this.props.viewModel.OrderItem.specialInstruction;
    this.props.orderObject.Order.IsTermCondition = true;
    this.props.orderObject.Order.NoOfItems =
      this.props.viewModel.OrderItem.NoOfItems;
    this.props.orderObject.Order.addressId = this.props.completeAddress.Id;
    // this.props.orderObject.Order.PickupSchedule = this.props.pickupScheduleProto;
    //setting pickup schedule
    this.props.orderObject.Order.PickupSelectedTime = this.props.selectedPickUpSchedule?.PickupSelectedTime;
    this.props.orderObject.Order.PickupSchedule = this.props.pickUpSchedule;
    console.log("props.pickUpSchedule", this.props.pickUpSchedule);

    //new changes start 
    this.props.orderObject.Order.DeliveryDateTime = this.props.pickUpSchedule?.WeekSchedules[1].Date;
    this.props.orderObject.Order.DropTimeSlotId = this.props.pickUpSchedule?.WeekSchedules[1].TimeSlots[0]?.SlotId;
    this.props.orderObject.Order.DeliveryTimeId = this.props.pickUpSchedule?.WeekSchedules[1].TimeSlots[0]?.SlotId;

    this.props.orderObject.Order.PickupDateTime = this.props.pickUpSchedule?.WeekSchedules[0]?.Date;
    this.props.orderObject.Order.PickupDateTime = this.props.pickUpSchedule?.WeekSchedules[0]?.TimeSlots[0]?.SlotId;
    //new changes ends

      console.log("selected:", this.props.selectedPickUpSchedule);

    if (this.props.selectedPickUpSchedule?.PickupSelectedSlotId !== 0) {
      this.props.orderObject.Order.PickupTimeSlotId = this.props.selectedPickUpSchedule?.PickupSelectedSlotId;
      // this.props.setSelectedPickupTimeSlotId_proto(0);
    }
    if (this.props.selectedPickUpSchedule?.PickupSelectedDate !== "") {
      this.props.orderObject.Order.PickupDateTime = this.props.selectedPickUpSchedule?.PickupSelectedDate;
      // this.props.setSelectedPickupDate_proto("");
    }

    // switch (this.props.DeliveryTimeId) {
    //   case 10:
    //     // this.props.orderObject.Order.DeliveryDateTime =  moment.utc(`${this.props.orderObject.Order.DeliveryDateTime}`).subtract(2,'days')._i.toString();
    //     this.props.orderObject.Order.DeliveryTimeId = "10";
    //     break;
    //   case 20:
    //     // this.props.orderObject.Order.DeliveryDateTime =  moment.utc(`${this.props.orderObject.Order.DeliveryDateTime}`).subtract(2,'days')._i.toString();
    //     this.props.orderObject.Order.DeliveryTimeId = "20";
    //     break;
    //   case 30:
    //     // this.props.orderObject.Order.DeliveryDateTime =  moment.utc(`${this.props.orderObject.Order.DeliveryDateTime}`)._i.toString();
    //     this.props.orderObject.Order.DeliveryTimeId = "30";
    //     break;
    //   default:
    //     break;
    // }

    this.props.orderObject.Order.DeliveryTimeId =
      this.props.DeliveryTimeId.toString();

    console.log("complete order object preview :", this.props.orderObject);
    this.props.setLoader(true);
    await this.props.postWebOrder(this.props.orderObject);
    await this.props.setLoginDetails(
      localStorage.getItem("Id"),
      localStorage.getItem("Token")
    );
    await this.props.setOrderDetails(localStorage.getItem("Token"));
    this.props.setLoader(false);
    this.props.setSelectedPickupDate_proto("");
    this.props.setSelectedPickupTimeSlotId_proto(2);
    this.props.setOrderItems(null);
    history.push("/CustomerOrders");
  };
  render() {
    console.log(this.props.viewModel);
    return (
      <>
        {this.state.path === "OrderDetail" ? (
          <>
            <div className="row heading_bar">
                    {/* <div className="wrapper progress_container"> */}
                        {/* <OrderProgressBar pageId={5} /> */}
                        <div className="wrapper">
                <h2>Order Summary</h2>
              </div>
                    {/* </div> */}
                </div>
            <div className="row wrapper progress_container">
              {/* <div className="wrapper">
                <h2>Order Summary</h2>
              </div> */}
              <OrderProgressBar pageId={5} /> 
            </div>

            <div className="row order_summary">
              <form
                name="ordersummary"
                novalidate
                angular-validator
                autocomplete="off"
              >
                <div className="wrapper">
                  <div className="col-lg-4 col-sm-6 order_s_colum">
                    <div className="order_colum">
                      <h4>
                        <i className="ico_pickup"></i> Pick-up{" "}
                        <span >
                          {" "}
                          {
                            new Date(this.props?.selectedPickUpSchedule?.PickupSelectedDate).toDateString()
                          }{" "}
                          <br />{" "}
                          <small className="selectedTime">
                            {
                              this.props?.selectedPickUpSchedule?.PickupSelectedTime
                            }
                          </small>
                        </span>
                      </h4>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 order_s_colum">
                    <div className="order_colum">
                      <h4>
                        {" "}
                        <i className="ico_items"></i> Clothes{" "}
                        <span style={{paddingLeft: '36px'}}>{this.props?.viewModel?.OrderItem?.NoOfItems}</span>
                      </h4>
                      
                      
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 order_s_colum">
                    <div className="order_colum">
                      <h4>
                        {" "}
                        <i className="ico_items"></i> Delivery Time{" "}
                        <span style={{paddingLeft: '36px'}}>
                        {new Date(this.props.pickUpSchedule?.WeekSchedules[1].Date).toDateString()}
                        <br />
                          <small className="selectedTime">
                            {
                              this.props?.selectedPickUpSchedule?.PickupSelectedTime
                            }
                          </small>
                        {/* {this.props?.DeliveryTimeId === 10
                          ? "Same Day"
                          : this.props?.DeliveryTimeId == 20
                          ? "Next Day"
                          : "Regular"} */}
                      </span>
                      </h4>
                      
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="order_colum">
                        <h4>
                          {" "}
                          <i className="ico_instruc"></i>Special Instructions{" "}
                        
                        {this.props?.viewModel?.OrderItem?.specialInstruction ? (
                          <p style={{paddingLeft: '40px'}}>
                            {this.props?.viewModel?.OrderItem?.specialInstruction}
                          </p>
                        ) : (
                          <span style={{paddingLeft: '40px'}}>No Special Instructions</span>
                        )}
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="order_colum">
                        <h4>
                          {" "}
                          <i className="ico_address"></i>Pickup & Delivery
                          Address
                        </h4>
                        <h6 style={{paddingLeft: '29px'}}>{this.props?.completeAddress?.CompleteAddress}</h6>
                      </div>
                    </div>
                  </div>

                  <div className="confirmation_box">
                    <input
                      type="checkbox"
                      onClick={() =>
                        this.acceptTermsCondition(!this.state.isChecked)
                      }
                      required
                    />
                    <label >
                      I accept <a onClick={()=> this.props.setTermsConditionsPopUp(true)} style={{cursor:'pointer'}}>Terms & Condition.</a>
                    </label>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.back()}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary pull-right"
                        onClick={(e) => this.placeOrder(e)}
                        disabled={!this.state.isChecked}
                      >
                        Place Order
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
         <TermsConditions/>
          </>
        ) : this.state.path == "Address" ? (
          <OrderAddress />
        ) : this.state.path == "Order" ? (
          <OrderItem />
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  postWebOrder,
  setOrderDetails,
  setLoader,
  setLoginDetails,
  setSelectedPickupDate_proto,
  setSelectedPickupTimeSlotId_proto,
  setTermsConditionsPopUp,
  setOrderItems
};
const mapStateToProps = ({ Auth }) => {
  let {
    customer,
    address,
    dropSchedule,
    pickUpSchedule,
    viewModel,
    DeliveryTimeId,
    selectedPickUpSchedule,
    completeAddress,
    customerDetails,
    orderObject,
    pickupScheduleProto,
    selectedPickupDateProto,
    selectedPickupTimeSlotIdProto,
    termsConditionsPopup
  } = Auth;

  return {
    customer,
    address,
    dropSchedule,
    pickUpSchedule,
    viewModel,
    DeliveryTimeId,
    selectedPickUpSchedule,
    completeAddress,
    customerDetails,
    orderObject,
    pickupScheduleProto,
    selectedPickupDateProto,
    selectedPickupTimeSlotIdProto,
    termsConditionsPopup
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
