import React, { Component } from "react";
import Modal from "react-modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchLoginDetails,
  setSignInPopUp,
  setLoginDetails,
  setForgetPasswordPopUp,
  setOrderDetails,
  setLoader,
} from "../../store/action/Auth";

const schema = Yup.object().shape({
  number: Yup.string()
    .required("Phone No is required.")
    .min(11, "Minimum 11 characters are required.")
    .matches(/^[0-9]{1,11}$/, "Only numbers allowed.")
    .max(11, "Maximum 11 characters are allowed."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Minimum 8 character are required.")
    .max(15, "Maximum 16 characrter are allowed."),
});

class SignIn extends Component {
  constructor(props) {
    super(props);
  }

  toggleSigninModal = () => {
    this.props.setSignInPopUp(!this.props.signinPopUp);
  };
  toggleForgetPasswordModal = () => {
    this.props.setForgetPasswordPopUp(!this.props.forgetPasswordPopUp);
    this.props.setSignInPopUp(!this.props.signinPopUp);
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.signinPopUp}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          id='ngdialog12'
          style={{}}
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document'>
            <div className='modal-header'>
              <h4 className='modal-title popup-title'>Sign In Into Your Account</h4>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={{
                  number: "",
                  password: "",
                }}
                validationSchema={schema}
                validateOnBlur
                onSubmit={async values => {
                  console.log("values**", values);

                  const viewModel = {
                    UserName: "",
                    Password: values.password,
                    Email: "",
                    Token: "",
                    MobilePhone:  values.number.substring(1),
                  };
                  this.props.setLoader(true);
                  await this.props.fetchLoginDetails(viewModel);
                  if (this.props.loginData.Id) {
                    localStorage.setItem("Id", this.props.loginData.Id);
                    localStorage.setItem("Token", this.props.loginData.Token);
                    await this.props.setLoginDetails(localStorage.getItem("Id"), localStorage.getItem("Token"));
                  } else toast.error(`Phone number or password is Incorrect`);

                  if (this.props.isLogin) {
                    toast.success(`  Welcome  ${this.props.customerDetails.Customer.FirstName}`);
                    this.props.setOrderDetails(localStorage.getItem("Token"));
                    this.props.history.push("/Order");
                  }

                  this.props.setLoader(false);
                }}
              >
                {props => {
                  const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

                  return (
                    <form noValidate onSubmit={handleSubmit} className='example' name='Login_form'>
                      
                      <div className='form-group field'>
                        <label className='show-hide'>
                          Mobile No.
                          <code style={{ backgroundColor: "#ffffff" }}>
                            <b>*</b>
                          </code>
                        </label>
                        <input
                          type='text'
                          className='form-control dhobi_input'
                          name='number'
                          value={values.number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength='11'
                        />
                        {errors.number && touched.number && <div className='errorMessage'>{errors.number}</div>}
                      </div>
                      <br />
                      <br />
                      <div className='row'>
                        <div className='form-group field  col-lg-12'>
                          
                          <label style={{}}>
                            Password
                            <code style={{ backgroundColor: "#ffffff" }}>
                              <b>*</b>
                            </code>
                          </label>
                          <input
                            type='password'
                            className='form-control'
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={16}
                          />
                          {errors.password && touched.password && <div className='errorMessage'>{errors.password}</div>}
                        </div>
                      </div>

                      <a onClick={this.toggleForgetPasswordModal} className='forgetlink'>
                        Forgot your password?
                      </a>
                      <button
                        type='submit'
                        name='signin'
                        className='btn btn-green btn-block'
                        disabled={
                          errors.number || values.number === "" ? true : errors.password || values.password === "" ? true : false
                        }
                      >
                        Sign In
                      </button>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className='ngdialog-close' onClick={this.toggleSigninModal}></div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchLoginDetails,
  setSignInPopUp,
  setLoginDetails,
  setForgetPasswordPopUp,
  setOrderDetails,
  setLoader,
};

const mapStateToProps = ({ Auth }) => {
  let { loginData, isLogin, signinPopUp, forgetPasswordPopUp, customerDetails, isLoading } = Auth;
  return {
    loginData,
    isLogin,
    signinPopUp,
    forgetPasswordPopUp,
    customerDetails,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
