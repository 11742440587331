import React, { Component } from "react";
import Modal from "react-modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { signup } from "../../models/signupModel";
// import { addressModel } from "../../models/address";
import {
  fetchLoginDetails,
  setSignInPopUp,
  setLoginDetails,
  setForgetPasswordPopUp,
  setOrderDetails,
  setLoader,
  setSignupModal,
  postOTPRequest,
  postVerifyOTP,
  saveSignUp,
} from "../../store/action/Auth";

const schema = Yup.object().shape({
  number: Yup.string()
    .required("Phone No is required.")
    .matches(/^[0-9]{1,11}$/, "Only numbers allowed.")
    .min(11, "11 characters are required.")
    .max(11, "11 characters are allowed."),
  otp: Yup.string()
    .required("OTP is required.")
    .min(4, "Minimum 4 character are required.")
    .max(4, "Maximum 4 characrter are allowed."),
  fname: Yup.string()
    .required("First Name is required.")
    .min(2, "Minimum 2 character are required")
    .max(30, "Maximum 30 charactered are allowed"),
  lname: Yup.string()
    .required("Last Name is required.")
    .min(2, "Minimum 2 character are required")
    .max(30, "Maximum 30 charactered are allowed"),
  email: Yup.string().email("Invalid Email").min(0, "").max(100, ""),
});

const validate = values => {
  let errors = {};
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be at least 8 characters long";
  } else if (values.password.length > 16) {
    errors.password = "Password not be greater than to 16 characters";
  } else if (!values.password.match(/[A-Z]/)) {
    errors.password = "Password require at least one capital letter";
  } else if (!values.password.match(/[0-9]/)) {
    errors.password = "Password must have at least one number";
  }
  if (values.cpassword) {
    if (values.cpassword !== values.password) {
      errors.cpassword = "Password do not match!";
    }
  }
  return errors;
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOtpSent: false,
      verifiedOtp: false,
      submitting: false,
      isRegistered: false,
    };
  }

  validateNumber = number => {
    if (number.length == 11) {
      return /^[0-9]+$/.test(number);
    } else return false;
  };

  sendOTP = async values => {
    let model = {
      VerificationCode: "string",
      PhoneNumber: "+92" + values.number.substring(1),
      IsVerified: true,
      TenantId: 0,
      FriendlyMessage: "string",
      HasErrors: true,
      HasWarnings: true,
      InternalMessage: "string",
      InternalMessages: ["string"],
      FriendlyMessages: ["string"],
      LoggedUserId: 0,
      Id: 0,
      CreatedById: 0,
      CreatedDate: "2022-10-28T12:06:00.037Z",
      ModifiedById: 0,
      ModifiedDate: "2022-10-28T12:06:00.037Z",
      IsActive: true,
    };
    this.setState({ submitting: true });
    this.props.setLoader(true);
    await this.props.postOTPRequest(model, data => this.setState({ isOtpSent: data }));
    this.props.setLoader(false);
    this.setState({ submitting: false });
  };

  //  verifyOTP = (values) => {
  //   let model = {
  //     VerificationCode: `${values.otp}`,
  //     PhoneNumber: "+92"+values.number,
  //     IsVerified: true,
  //     HasErrors: true,
  //     ResultMessages: [
  //       {
  //         MessageType: "string",
  //         Message: "string"
  //       }
  //     ],
  //     Id: 0,
  //     CreatedById: 0,
  //     CreatedBy: "string",
  //     LastUpdatedById: 0,
  //     LastUpdatedBy: "string",
  //     InsertDate: "2022-10-28T12:06:00.043Z",
  //     ModifiedDate: "2022-10-28T12:06:00.043Z",
  //     IsActive: true
  //   }
  //   this.props.setLoader(true);
  //   this.props.postVerifyOTP(model, data => this.setState({verifiedOtp: data}));
  //   this.props.setLoader(false);
  //  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.signupModal}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          id='ngdialog15'
          style={{}}
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document' style={{ width: "80%" }}>
            <div className='modal-header'>
              <h4 className='modal-title popup-title'>Sign Up To Create An Account</h4>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={{
                  fname: "",
                  lname: "",
                  number: "",
                  email: "",
                  password: "",
                  cpassword: "",
                }}
                validationSchema={schema}
                validate={validate}
                validateOnBlur
                onSubmit={async values => {
                  let viewModel = {
                    defaultaddressid: 0,
                    defaultcardid: 0,
                    signup: {
                      cardconnectprofileid: 0,
                      email: `${values.email}`,
                      FirstName: `${values.fname}`,
                      ImagePath: "",
                      LastName: `${values.lname}`,
                      Password: `${values.password}`,
                      Phone: `${values.number.substring(1)}`,
                      SendEmail: false,
                      SendSMS: false,
                      TermandCondition: false,
                      Unsubscribe: false,
                      VerificationCode: `${values.otp}`,
                      IsActive: true,
                      HasErrors: false,
                      Id: 0,
                      TokenExpired: false,
                    },
                    DeviceType: "1",
                    FCMToken:
                      "cGx7vlpuSDmfFLh54KrtDY:APA91bEQsF0YGpk-7MSVsV3IAkD2aG4aizOPLCUYosq9aCjN-4DWAsGLDE1OX7cv5koIK6rX0mI7vYEPW4fGgQPDplJYja6rZ8l1SXdi5if38lJzkZn92qLWN3WkXL6ibnZb0ic9xzg2",
                    IsActive: true,
                    HasErrors: false,
                    Id: 0,
                    TokenExpired: false,
                  };
                  // here you have the access to the form data
                  // values.first_name, values_last_name, values_email, values_password

                  console.log("I'm here inside post request");
                  // let customer = new signup(
                  //   values.fname,
                  //   values.lname,
                  //   false,
                  //   false,
                  //   values.password,
                  //   false,
                  //   "",
                  //   values.email,
                  //   '+92'+values.number,
                  //   false
                  // );

                  // customer.CustomerOTPId = 0;
                  // customer.VerificationCode = `${values.otp}`;
                  // let address = new addressModel();
                  // let viewModel = {
                  //   SignUp: customer,
                  //   Address: address,
                  // };
                  await this.props.saveSignUp(viewModel);
                  if (this.props.customer.ResultMessages.length > 0) {
                    this.props.customer.ResultMessages.forEach(response => {
                      if (response.MessageType === "success") {
                        // this.state.isRegistered = true;
                        this.setState({ isRegistered: true });
                      } else if (response.MessageType === "danger") {
                        // this.state.isRegistered = false;
                        this.setState({ isRegistered: false });
                        toast.warning(response.Message);
                        //return;
                      }
                    });
                  }
                  if (this.state.isRegistered) {
                    // if (
                    //   this.props.customer.Password &&
                    //   this.props.customer.MobilePhone
                    // ) {
                    const signInModel = {
                      UserName: "",
                      Password: `${values.password}`,
                      Email: "",
                      Token: "",
                      MobilePhone: `${values.number.substring(1)}`,
                    };
                    await this.props.fetchLoginDetails(signInModel);
                    if (this.props.loginData.Id) {
                      localStorage.setItem("Id", this.props.loginData.Id);
                      localStorage.setItem("Token", this.props.loginData.Token);
                      await this.props.setLoginDetails(localStorage.getItem("Id"), localStorage.getItem("Token"));
                    } else {
                      toast.error(`Phone number or password is Incorrect`);
                      return;
                    }

                    if (this.props.isLogin) {
                      toast.success(`You have been successfully logged in ${this.props.customerDetails.Customer.FirstName}`);
                      // var updatedPath = updateState(
                      //   this.orderPageEnum.Address,
                      //   this.props.isLogin
                      // );
                      // updatedPath == "Order"
                      //   ? this.setState({ path: updatedPath })
                      //   : updatedPath == "OrderAddress"
                      //   ? this.setState({ path: updatedPath })
                      //   : updatedPath == "TimeSlot"
                      //   ? this.setState({ path: updatedPath })
                      //   : updatedPath == "SignUp"
                      //   ? this.setState({ path: updatedPath })
                      //   : updatedPath == "OrderDetail"
                      //   ? this.setState({ path: updatedPath })
                      //   : this.setState({ path: "" });
                      // }
                    }
                  }
                  console.log("back");
                  this.props.setSignupModal(false);
                  // postData('https://dcpapi.ssasoft.com/api/SignUp/PostSignUp', viewModel)
                  //     .then(data => {
                  //         console.log(data); // JSON data parsed by `data.json()` call
                  //         if(data.ResultMessages.length > 0)
                  //         data.ResultMessages.forEach(response => {
                  //             alert(response.Message);
                  //             return;
                  //         });

                  //     });
                }}
              >
                {props => {
                  const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

                  return (
                    <form noValidate onSubmit={handleSubmit} className='example' name='Login_form'>
                      <div className='row'>
                        {/* {!this.state.verifiedOtp && */}
                        <div className='form-group field col-sm-6'>
                          <input
                            type='text'
                            className='form-control dhobi_input'
                            name='number'
                            placeholder='Mobile No'
                            value={values.number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength='11'
                          />
                          {errors.number && touched.number && <div className='errorMessage'>{errors.number}</div>}
                        </div>
                        {/* } */}
                        {/* {!this.state.verifiedOtp && */}
                        <div className='col-sm-3'>
                          <button
                            disabled={!this.validateNumber(values?.number)}
                            style={{ cursor: `${this.state.submitting ? "progress" : "default"}` }}
                            type='button'
                            className='btn btn-green'
                            onClick={async () => {
                              await this.sendOTP(values);
                            }}
                          >
                            Send OTP
                          </button>
                        </div>
                        {/* } */}
                        {/* {this.state.isOtpSent && */}
                        <div className='form-group field col-sm-3'>
                          <input
                            type='text'
                            className='form-control'
                            name='otp'
                            maxLength={4}
                            placeholder='OTP Code'
                            value={values.otp}
                            onChange={e => {
                              handleChange(e);
                              // if(e.currentTarget.value.length == 4){
                              //   // this.setState({verifiedOtp : true})
                              //   this.verifyOTP({number: values.number, otp : e.currentTarget.value});

                              // }
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.otp && touched.otp && <div className='errorMessage'>{errors.otp}</div>}
                        </div>
                        {/* } */}
                      </div>
                      {/* {this.state.verifiedOtp && */}
                      <>
                        <div className='row'>
                          <div className='form-group field col-sm-6'>
                            {/* <label id='name' htmlfor='fname' className='show-hide'>
                              First Name
                            </label> */}
                            <input
                              type='text'
                              name='fname'
                              value={values.fname}
                              className='form-control'
                              placeholder='First Name'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={20}
                              required
                            />
                            {errors.fname && touched.fname && <div className='errorMessage'>{errors.fname}</div>}
                          </div>
                          <div className='form-group field col-sm-6'>
                            {/* <label htmlfor='lname' className='show-hide'>
                              Last Name
                            </label> */}
                            <input
                              type='text'
                              name='lname'
                              value={values.lname}
                              placeholder='Last Name'
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={20}
                              required
                            />
                            {errors.lname && touched.lname && <div className='errorMessage'>{errors.lname}</div>}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group field col-sm-6'>
                            {/* <label htmlfor='email' className='show-hide'>
                              Email
                            </label> */}
                            <input
                              type='email'
                              name='email'
                              placeholder='Email'
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={100}
                              // required
                            />
                            {/* {errors.email && touched.email && <div className='errorMessage'>{errors.email}</div>} */}
                          </div>
                          <div className='form-group field col-sm-6'>
                            {/* <label htmlfor='password' className='show-hide'>
                              Password
                            </label> */}
                            <input
                              type='password'
                              name='password'
                              placeholder='Password'
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={16}
                              required
                            />
                            {errors.password && touched.password && <div className='errorMessage'>{errors.password}</div>}
                          </div>
                          <div className='form-group field col-sm-6'>
                            {/* <label ng-show='order.confirmpassword' htmlfor='cpassword' className='show-hide'>
                              Confirm Password
                            </label> */}
                            <input
                              type='password'
                              name='cpassword'
                              placeholder='Confirm Password'
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={16}
                              required
                            />
                            {errors.cpassword && touched.cpassword && <div className='errorMessage'>{errors.cpassword}</div>}
                          </div>
                        </div>
                        <button
                          type='submit'
                          name='signup'
                          className='btn btn-green btn-block'
                          disabled={
                            errors.fname || values.fname === ""
                              ? true
                              : errors.lname || values.lname === ""
                              ? true
                              : errors.mobile || values.mobile === ""
                              ? true
                              // : errors.email || values.email === ""
                              // ? true
                              : errors.password || values.password === ""
                              ? true
                              : errors.cpassword || values.cpassword === ""
                              ? true
                              : errors.otp || values.otp === ""
                              ? true
                              : false
                          }
                        >
                          Sign Up
                        </button>
                      </>
                      {/* } */}
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className='ngdialog-close' onClick={() => this.props.setSignupModal(false)}></div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchLoginDetails,
  setSignInPopUp,
  setLoginDetails,
  setForgetPasswordPopUp,
  setOrderDetails,
  setLoader,
  setSignupModal,
  postOTPRequest,
  postVerifyOTP,
  saveSignUp,
};

const mapStateToProps = ({ Auth }) => {
  let { loginData, isLogin, signinPopUp, forgetPasswordPopUp, customerDetails, isLoading, signupModal, customer } = Auth;
  return {
    loginData,
    isLogin,
    signinPopUp,
    forgetPasswordPopUp,
    customerDetails,
    isLoading,
    signupModal,
    customer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
