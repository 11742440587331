import React, { Component } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";

export class OrderSection extends Component {
  static displayName = OrderSection.name;

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="row order_section">
        <div className="wrapper wow bounceIn">
          <h4>Order online for on-demand collection and delivery</h4>
          <a className="btn_order">
            <Link
              to={`/Order`}
              activeClassName="active"
              style={{ color: "white" }}
            >
              Place Order
            </Link>
          </a>
        </div>
      </div>
    );
  }
}
