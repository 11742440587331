import React, { Component } from "react";
import Modal from "react-modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";

// import { CardModel } from '../models/CardModels';
// import { CardTypeModel } from '../models/CardTypeModels';
// import { AddressModel } from '../models/AddressModels';
import { connect } from "react-redux";
import { setrefertofriend, saveReferFriend } from "../../store/action/Auth";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is Required."),
  number: Yup.string()
    .required("Phone No is required.")
    .min(11, "Invalid Phone No.")
    .max(11, "Maximum 11 characters are allowed."),
});

export class SetReferFriend extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log("in forget password");
    return (
      <>
        <Modal
          isOpen={this.props.refertofriendPopup}
          contentLabel="Example Modal"
          className="ngdialog ngdialog-theme-default model-login ng-scope"
          id="ngdialog15"
        >
          <div className="ngdialog-overlay"></div>
          <div className="ngdialog-content" role="document">
            <div className="modal-header">
              <h4 className="modal-title" id="ngdialog15-aria-labelledby">
                Refer friend and get 10% off on next order
              </h4>
            </div>
            <div className="modal-body" style={{ overflow: "auto" }}>
              <Formik
                initialValues={{
                  name: "",
                  number: "",
                }}
                validationSchema={schema}
                validateOnBlur
                onSubmit={async (values) => {
                  console.log(values);
                  const viewModel = {
                    PromotionInfoUniqIDFK: 0,
                    ReferrerUniqIDFK: 0,
                    ReferralName: values.name,
                    ReferralPhoneNo: `${values.number.substring(1)}`,
                    GUID: "",
                    ReferralStatusUniqIDFK: 0,
                    isPromotionComplete: true,
                    isPromotionAvailableToRedeem: true,
                    TenantId: 0,
                    FriendlyMessage: "",
                    HasErrors: false,
                    HasWarnings: false,
                    InternalMessage: "",
                    InternalMessages: ["string"],
                    FriendlyMessages: ["string"],
                    LoggedUserId: 0,
                    Id: 0,
                    CreatedById: 0,
                    CreatedDate: "2022-10-19T11:08:31.843Z",
                    ModifiedById: 0,
                    ModifiedDate: "2022-10-19T11:08:31.843Z",
                    IsActive: true,
                  };
                  //  await this.props.fetchForgetPasswordDetails(values.email);
                  //   console.log("forgetPasswordResponse : ",this.props.forgetPasswordResponse);
                  await this.props.saveReferFriend(viewModel);
                  this.props.setrefertofriend(false);
                }}
              >
                {(props) => {
                  const {
                    touched,
                    errors,
                    handleSubmit,
                    values,
                    handleChange,
                    handleBlur,
                  } = props;

                  return (
                    <form
                      name="Forgot_form"
                      noValidate
                      autoComplete="off"
                      className="ng-pristine ng-valid-email ng-invalid ng-invalid-required ng-valid-pattern"
                      onSubmit={handleSubmit}
                    >
                      
                      <div className="form-group field">
                        <label className="show-hide">
                          Name
                          <code style={{ backgroundColor: "#ffffff" }}>
                            <b>*</b>
                          </code>
                        </label>
                        <input
                          type="string"
                          className="form-control custom-form-control"
                          name="name"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name && (
                        <div className="errorMessage">{errors.name}</div>
                      )}
                      </div>
                      
                      <div className="form-group field">
                        <label className="show-hide">
                          Mobile No.
                          <code style={{ backgroundColor: "#ffffff" }}>
                            <b>*</b>
                          </code>
                        </label>
                        <input
                          type="text"
                          className="form-control dhobi_input"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength="11"
                        />
                        {errors.number && touched.number && (
                        <div className="errorMessage">{errors.number}</div>
                      )}
                      </div>
                      <div className="form-group">
                        <div className="col-sm-12">
                          {/* <button
                            type="submit"
                            className="btn btn-green btn-block"
                            disabled={
                              errors.name || values.name === "" ? true : false
                            }
                          >
                            Send
                          </button> */}
                          <a
                          className="btn btn-green btn-block"
                          style={{color:"white"}}
                          href= {errors.name || values.name === "" ? true : errors.number || values.number === "" ? null : `https://api.whatsapp.com/send?phone=+92${values.number.substring(1)}&text=Place20an20order20and20get205020off20Android20link20:20https://play.google.com/store/apps/details?id=com.ssasoft.lavanderia20IOS20App20link20:20https://apps.apple.com/us/app/lavanderia-pk/id163345697020Web20App20link20:20https://www.lavanderia.pk/`}
                          // href="https://api.whatsapp.com/send?phone=+923084311169&text=Place20an20order20and20get205020off20Android20link20:20https://play.google.com/store/apps/details?id=com.ssasoft.lavanderia20IOS20App20link20:20https://apps.apple.com/us/app/lavanderia-pk/id163345697020Web20App20link20:20https://www.lavanderia.pk/"
                            // state: this.props.location.state,
                            disabled= { errors.name || values.name === "" ? true : errors.number || values.number === "" ? true :false}
                        >
                          Send
                        </a>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div
              className="ngdialog-close"
              onClick={() => {
                this.props.setrefertofriend(false);
              }}
            ></div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = { setrefertofriend, saveReferFriend };

const mapStateToProps = ({ Auth }) => {
  let { forgetPasswordResponse, refertofriendPopup, saveReferFriend } = Auth;
  return { forgetPasswordResponse, refertofriendPopup, saveReferFriend };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetReferFriend);
