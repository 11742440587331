import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  editCustomerProfile,
  setLoginDetails,
  setLoader,
} from "../../store/action/Auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Minimum 2 character are required.")
    .max(50, "Maximum 50 character are allowed")
    .required("First Name is required."),
  lastName: Yup.string()
    .min(2, "Minimum 2 character are required.")
    .max(50, "Maximum 50 character are allowed")
    .required("Last Name is required."),
});

class EditProfile extends Component {
  render() {
    return (
      <>
        <div className="row heading_bar">
          <div className="wrapper">
            <h2>Edit Profile</h2>
          </div>
        </div>
        <div className="row">
          <div className="wrapper item_insertion edit_profile  clearfix container">
            <Formik
              initialValues={{
                email: this.props?.location?.state?.Customer?.Email,
                firstName: this.props?.location?.state?.Customer?.FirstName,
                lastName: this.props?.location?.state?.Customer?.LastName,
                phone: this.props?.location?.state?.Customer?.Phone,
              }}
              validationSchema={schema}
              validateOnBlur
              onSubmit={async (values) => {
                console.log("*****");
                this.props.customerDetails.DefaultAddressId =
                  this.props.isDefaultAddressId;
                this.props.customerDetails.Customer.FirstName =
                  values.firstName;
                this.props.customerDetails.Customer.LastName = values.lastName;

                console.log(
                  "before default address: ",
                  this.props.isDefaultAddressId
                );
                console.log(
                  "Default Address : ",
                  this.props.customerDetails.DefaultAddressId
                );
                this.props.setLoader(true);
                await this.props.editCustomerProfile(
                  this.props.customerDetails,
                  localStorage.getItem("Token")
                );
                this.props.setLoader(false);
                values.firstName = "";
                values.lastName = "";

                console.log(
                  "Edit profile response : ",
                  this.props.editCustomerProfileResponse
                );

                if (
                  this.props.editCustomerProfileResponse?.ResultMessages[0]
                    ?.MessageType === "success"
                ) {
                  toast.success(
                    this.props.editCustomerProfileResponse?.ResultMessages[0]
                      ?.Message
                  );
                } else {
                  toast.error(
                    this.props.editCustomerProfileResponse?.ResultMessages[0]
                      ?.Message
                  );
                }

                await this.props.setLoginDetails(
                  localStorage.getItem("Id"),
                  localStorage.getItem("Token")
                );
                this.props.history.push("/Profile");
              }}
            >
              {(props) => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                } = props;

                return (
                  <form
                    name="editProfile_form"
                    noValidate
                    role="form"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    {errors.firstName && touched.firstName && (
                      <div className="errorMessage">{errors.firstName}</div>
                    )}
                    <div className="col-sm-6 paddin_right field">
                      {values.firstName == "" ? null : (
                        <label
                          htmlFor="firstName"
                          className="show-hide ng-hide"
                        >
                          First Name
                        </label>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoFocus
                        maxLength={25}
                        style={{ opacity: "1", position: "relative", cursor: "auto" }}
                      />
                    </div>
                    {errors.lastName && touched.lastName && (
                      <div className="errorMessage">{errors.lastName}</div>
                    )}
                    <div className="col-sm-6 paddin_right field">
                      {values.lastName == "" ? null : (
                        <label htmlFor="lastName" className="show-hide ng-hide">
                          Last Name
                        </label>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={25}
                        style={{ opacity: "1", position: "relative", cursor: "auto"  }}
                      />
                    </div>

                    <div class="col-sm-6 paddin_right field">
                      <label htmlFor="phone" className="show-hide">
                        Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder="Mobile Phone"
                        value={values.phone}
                        disabled
                        style={{ opacity: "1", position: "relative" }}
                      />
                    </div>

                    <div class="col-sm-6 paddin_right field">
                      <label htmlFor="email" className="show-hide">
                        Email
                      </label>
                      <input
                        // type="tel"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={values.email}
                        disabled
                        style={{ opacity: "1", position: "relative" }}
                      />
                    </div>

                    <div className="row">
                    <div className="col-sm-6">
                      {/* <div className="form-group"> */}
                        {/* <button
                          className="btn btn-primary"
                          type="submit"
                        >
                          Update Profile
                        </button> */}
                        <NavLink
                          className="btn"
                          style={{float:"left"}}
                          to={{
                            pathname: "/Profile",
                            // state: this.props.location.state,
                          }}
                        >
                          Back
                        </NavLink>
                      {/* </div> */}
                    </div>

                    <div className="col-sm-6">
                      {/* <div className="row"> */}
                        {/* <button type="submit" className="btn" onClick={browserHistory.goBack}>Back</button> */}
                        {/* <NavLink
                          className="btn"
                          style={{float:"right"}}
                          to={{
                            pathname: "/Profile",
                            // state: this.props.location.state,
                          }}
                        >
                          Back
                        </NavLink> */}
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{float: "right"}}
                        >
                          Update Profile
                        </button>
                      </div>
                      </div>
                    {/* </div> */}
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = { editCustomerProfile, setLoginDetails, setLoader };

const mapStateToProps = ({ Auth }) => {
  let { customerDetails, isDefaultAddressId, editCustomerProfileResponse } =
    Auth;
  return {
    customerDetails,
    isDefaultAddressId,
    editCustomerProfileResponse,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
