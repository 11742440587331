import React, { Component } from "react";
import { CoverageAreas } from "./static-data/coverage-area";

export class CoverageArea extends Component {
  static displayName = CoverageArea.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="row heading_banner">
          <img src="faq_banner.jpg" alt="" />
        </div>

        <div className="row">
          <div className="wrapper clearfix items_wrap faq_wrap">
            <h3 className="">Coverage Area</h3>
            <div className="col-md-12" style={{ fontSize: "medium" }}>
              <div className="maincontent-wrrapper ng-pristine ng-untouched ng-valid ta-bind">
                <p>Lavanderia.com has an easy system for tackling the dirty work you would rather avoid. 
                  Your clothes are carefully picked up, laundered, altered or dry cleaned and then returned to you. 
                  <br />
                  <br />
                  We provide coverage whether you working or living in these following areas:
                </p>
                <ul
                  style={{
                    fontSize: "medium",
                    color: "#666666",
                    marginLeft: "40px",
                    lineHeight: 1.7,
                  }}
                >
                  {/* <li style={{ marginBottom: "5px", letterSpacing: "2px" }}>
                    E-7 to E-11
                  </li>
                  <li style={{ marginBottom: "5px", letterSpacing: "2px" }}>
                    F-5 to F-11
                  </li>
                  <li style={{ marginBottom: "5px", letterSpacing: "2px" }}>
                    G-5 to G-13
                  </li>
                  <li style={{ marginBottom: "5px", letterSpacing: "2px" }}>
                    H-8 to H-13
                  </li>
                  <li style={{ marginBottom: "5px", letterSpacing: "2px" }}>
                    I-8 to I-11
                  </li> */}
                  {CoverageAreas.map((area,index)=>{
                    return(
                      <li style={{ marginBottom: "5px", letterSpacing: "2px" }} key={index}>
                    {area}
                  </li> 
                    )
                  })}
                </ul>
                <p>
                  Call or whatsapp us at 0311-4199500 or +92-42-35957999 &nbsp;for more details
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
