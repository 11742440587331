import React, { Component } from "react";

export class Footer extends Component {
  static displayName = Footer.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="footer" style={{ zIndex: 5 }}>
        <div className="wrapper">
          <p>Copyright ©2022 All rights reserved. Lavanderia </p>
        </div>
      </div>
    );
  }
}
