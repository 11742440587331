import React, { Component } from "react";
import { Link } from "react-router-dom";

import { AboutSection } from "./AboutSection";
import { OrderProcess } from "./OrderProcess";
import { CustomerSection } from "./CustomerSection";
import { Testimonial } from "./Testimonial";
import { PickWashSection } from "./PickWashSection";
import { OrderSection } from "./OrderSection";
import { DetailFooter } from "./DetailFooter";

import WOW from "wowjs";

export class Home extends Component {
  static displayName = Home.name;

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="banner_full img_width">
            <img src="hm-baner.jpg" alt="" />
            <div className="banner_layer wow slideInRight">
              <h2 style={{color: '#fff'}}>
                Pick Wash & deliver <span style={{color:'#fff'}}>at your Door Step</span>
              </h2>
              <a className="btn_order">
                <Link
                  to={`/Order`}
                  activeClassName="active"
                  style={{ color: "white" }}
                >
                  Place Order
                </Link>
              </a>
            </div>
          </div>
        </div>
        <AboutSection />
        <OrderProcess />
        <CustomerSection />
        {/* <Testimonial /> */}
        <PickWashSection />
        <OrderSection />
        <DetailFooter />
      </>
    );
  }
}
