import React, { Component } from "react";
import MainHeader from "./MainHeader";
import { Footer } from "./Footer";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "../css/fontAwesome.css";
import { setLoader } from "../store/action/Auth";
import { connect } from "react-redux";
import { toast, ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return ( <BlockUi tag = "div"blocking = { this.props.isLoading }message = "Please wait"keepInView = "true" >
            <div className = "home_sticky" >
                <div className = "container-fluid" >
             
                <div className="row">
                    <div className = "headder_sticky manage-header " >
                        <MainHeader history = { this.props.history }/> 
                    </div>
                    </div> { this.props.children } 
                </div> 
                <ToastContainer position = "bottom-right" limit = { 1 } transition = { Flip }/> 
            <Footer />
            </div>

            </BlockUi>

        );
    }
}

const mapDispatchToProps = {
    setLoader,
};

const mapStateToProps = ({ Auth }) => {
    let { isLoading } = Auth;

    return {
        isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);