import React, { Component } from "react";
import "../libraries/font-awesome-4.7.0/css/font-awesome.css";
import { Link, NavLink } from "react-router-dom";
import Modal from "react-modal";
import SignIn from "./Modal/SignIn";
import SetReferFriend from "./Modal/SetReferFriend";
import CheckService from "./Modal/CheckService";
import SearchedAreas from "./Modal/SearchedAreas";
import Signup from "./Modal/Signup";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { NavMenu } from "./NavMenu";
import {
  setSignInPopUp,
  setLoginDetails,
  setcheckservice,
  setrefertofriend,
  setForgetPasswordPopUp,
  fetchForgetPasswordDetails,
  setLoginStatus,
  setLoader,
  setNavbar,
  userSignout,
  resetCustomerDetails,
  setSignupModal,
  postForgetPassword,
  setPostForgetPasswordPopup,
  setForgetPasswordResponse,
  postResetPassword,
} from "../store/action/Auth";

const schema = Yup.object().shape({
  number: Yup.string()
    .required("Phone No is required.")
    .matches(/^[0-9]{1,11}$/, "Only numbers allowed.")
    .min(11, "11 characters are required.")
    .max(11, "11 characters are allowed."),
});

const resetPasswordSchema = Yup.object().shape({
  // number: Yup.string()
  // .required("Phone No is required.")
  // .matches(/^[0-9]{1,10}$/, "Only numbers allowed.")
  // .min(10, "10 characters are required.")
  // .max(10, "10 characters are allowed."),
  otp: Yup.string()
    .required("OTP is required.")
    .min(4, "Minimum 4 character are required.")
    .max(4, "Maximum 4 characrter are allowed."),
});

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoogedIn: false,
      userName: "",
      error: null,
      isLoaded: false,
      items: [],
      wholeObject: {},
      categories: [],
      FAQ: {},
      aboutUs: {},
      isModalOpen: false,
      isForgetPasswordModalOpen: false,
      signInResponse: {},
      isOtpSent: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    // this.setState({hideNav: window.innerWidth <= 760});
    if (window.innerWidth <= 784) this.props.setNavbar(true);
    if (window.innerWidth > 784) this.props.setNavbar(false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  setSignInResponse = res => {
    this.setState({ signInResponse: res }, () => {
      console.log("signInRESPONSE**: ", res);
    });
  };

  toggleSigninModal = () => {
    this.props.setSignInPopUp(!this.props.signinPopUp);
  };

  afterOpenModal = () => {
    console.log("modal is open");
  };

  closeModal = () => {
    this.setState({ ...this.state, isModalOpen: false });
  };

  toggle2 = () => {
    this.setState({
      isForgetPasswordModalOpen: !this.state.isForgetPasswordModalOpen,
    });
  };
  toggleReferFriendModal = () => {
    this.props.setrefertofriend(!this.props.refertofriendPopup);
  };
  toggleCheckServiceModal = () => {
    this.props.setcheckservice(!this.props.checkservicePopup);
  };

  validate = values => {
    let errors = {};
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (values.password.length > 16) {
      errors.password = "Password not be greater than to 16 characters";
    } else if (!values.password.match(/[A-Z]/)) {
      errors.password = "Password require at least one capital letter";
    } else if (!values.password.match(/[0-9]/)) {
      errors.password = "Password must have at least one number";
    }
    if (values.cpassword) {
      if (values.cpassword !== values.password) {
        errors.cpassword = "Password do not match!";
      }
    }
    return errors;
  };

  render() {
    return (
      <>
        <div className='wrapper'>
          <div className='main_header clearfix'>
            <a
              href='https://www.lavanderia.pk/'
              // onClick={()=> this.props.history.push("/Order")}
              className='dlogo'
              style={{ cursor: "pointer" }}
            >
              <img src={require("../images/highres-logo2.png")} width='189px' alt='logo' height='120px' />
            </a>

            <div className='order_login_div dropdown'>
              {this.props.isLogin ? (
                <div className='loginspan' style={{ cursor: "pointer" }}>
                  Hello
                  <a className='dropdown-toggle' data-toggle='dropdown'>
                    {" "}
                    {this.props.customerDetails.Customer ? this.props.customerDetails.Customer.FirstName : ""}{" "}
                    {this.props.customerDetails.Customer ? this.props.customerDetails.Customer.LastName : ""}
                    <i className='fa fa-caret-down' aria-hidden='true'></i>
                  </a>
                  <ul className='dropdown-menu'>
                    <li ui-sref-active='active'>
                      {/* <a ui-sref='customerprofile'>
                        {" "}
                        <i className='fa fa-user' aria-hidden='true'></i>{" "} */}
                        <NavLink
                          to={{
                            pathname: "/Profile",
                            state: this.state.signInResponse,
                          }}
                        ><i className='fa fa-user' aria-hidden='true'></i>{" "}
                          Profile
                        </NavLink>{" "}
                      {/* </a> */}
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          localStorage.removeItem("Id");
                          localStorage.removeItem("Token");
                          console.log("onclick");
                          this.props.userSignout();
                          this.props.setLoginStatus(false);
                          this.props.resetCustomerDetails();
                          this.props.history.push("/");
                          toast.info("You have been logged out.");
                        }}
                      >
                        <i className='fa fa-power-off' aria-hidden='true'></i>
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className='loginspan'>
                  <p>
                    Already have an account?{" "}
                    <a style={{ curor: "pointer" }} onClick={this.toggleSigninModal}>
                      Sign in
                    </a>{" "}
                    or <a onClick={() => this.props.setSignupModal(true)}>Sign up</a> here
                  </p>
                </div>
              )}
              <a onClick={this.toggleCheckServiceModal} className='btn btn-primary-s'>
                <Link activeClassName='active' style={{ color: "white", padding: "10px", marginRight: "10px" }} exact>
                  Check Service Availability
                </Link>
              </a>

              <a className='btn btn-primary-s green-btn'>
                <Link to={`/Order`} activeClassName='active' style={{ color: "white", gap: "5px" }} exact>
                  Order Now
                </Link>
              </a>
            </div>

            <div className='menu_wrapper'>
              {/* <a
                // className='navigation_button_'
                onClick={() => {
                  this.props.setNavbar(!this.props.navBar);
                  console.log("vertical");
                }}
                className='navigation_button_'
                type='button'
                data-toggle='collapse'
                data-target='#navbarToggleExternalContent'
                aria-controls='navbarToggleExternalContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              ></a> */}
              {
                <div className='main_nav'>
                  <ul>
                    {/* <li>
                      {" "}
                      <NavLink
                        className='navbar-item'
                        activeClassName='active'
                        exact={true}
                        to='/'
                        onlyActiveOnIndex
                        aria-current='true'
                        activeStyle={{
                          background: "#0080ff",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                      >
                        Home
                      </NavLink>{" "}
                    </li>
                    <li>
                      {" "}
                      <NavLink
                        className='navbar-item'
                        activeClassName='active'
                        exact
                        to={{
                          pathname: "/Pricing",
                          state: this.state.wholeObject,
                        }}
                        activeStyle={{
                          background: "#0080ff",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                      >
                        Service
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink
                        className='navbar-item'
                        activeClassName='active'
                        exact
                        to='/CoverageArea'
                        activeStyle={{
                          background: "#0080ff",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                      >
                        Coverage Area
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink
                        className='navbar-item'
                        activeClassName='active'
                        to={{
                          pathname: "/AboutUs",
                          state: this.state.aboutUs,
                        }}
                        aria-current='false'
                        exact
                        activeStyle={{
                          background: "#0080ff",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink
                        className='navbar-item'
                        activeClassName='active'
                        to={{
                          pathname: "/Terms",
                          state: this.state.FAQ,
                        }}
                        exact
                        activeStyle={{
                          background: "#0080ff",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                      >
                        Terms
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink
                        className='navbar-item'
                        activeClassName='active'
                        to='/ContactUs'
                        exact
                        activeStyle={{
                          background: "#0080ff",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                      >
                        Contact Us
                      </NavLink>
                    </li> */}

                    {this.props.isLogin && (
                      <li className='dropdown' style={{ cursor: "pointer" }}>
                        <a className='dropdown-toggle' id='menu' data-toggle='dropdown'>
                          My Dryclean <i className='fa fa-caret-down' aria-hidden='true'></i>
                        </a>
                        <ul className='dropdown-menu bar'>
                          <li>
                            <a
                              ui-sref='orders'
                              onClick={() => {
                                this.props.history.push("/CustomerOrders");
                              }}
                            >
                              <i className='fa fa-list' aria-hidden='true'></i>
                              Order History
                            </a>
                            <a onClick={this.toggleReferFriendModal} className='forgetlink'>
                              <i i className='fa fa-list' aria-hidden='true'></i>
                              Refer to Friend
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              }
              {
                // <div class='collapse _mini_nav' id='navbarToggleExternalContent' style={{ marginTop: "118px" }}>
                //   <div class='bg-dark p-4'>
                //     {/* <h4 class="text-white">Collapsed content</h4> */}
                //     <ul style={{ backgroundColor: "#3dc050", marginBottom: -8, fontSize: 14, fontWeight: 600 }}>
                //       {/* <li>
                //         {" "}
                //         <NavLink
                //           style={{ textDecoration: "none" }}
                //           className='navbar-item'
                //           activeClassName='active'
                //           exact={true}
                //           to='/'
                //           onlyActiveOnIndex
                //           aria-current='true'
                //           activeStyle={{
                //             background: "#0080ff",
                //             color: "#ffffff",
                //             borderRadius: "3px",
                //           }}
                //         >
                //           Home
                //         </NavLink>{" "}
                //       </li>
                //       <li>
                //         {" "}
                //         <NavLink
                //           style={{ textDecoration: "none" }}
                //           className='navbar-item'
                //           activeClassName='active'
                //           exact
                //           to={{
                //             pathname: "/Pricing",
                //             state: this.state.wholeObject,
                //           }}
                //           activeStyle={{
                //             background: "#0080ff",
                //             color: "#ffffff",
                //             borderRadius: "3px",
                //           }}
                //         >
                //           Service
                //         </NavLink>
                //       </li>
                //       <li>
                //         {" "}
                //         <NavLink
                //           style={{ textDecoration: "none" }}
                //           className='navbar-item'
                //           activeClassName='active'
                //           exact
                //           to='/CoverageArea'
                //           activeStyle={{
                //             background: "#0080ff",
                //             color: "#ffffff",
                //             borderRadius: "3px",
                //           }}
                //         >
                //           Coverage Area
                //         </NavLink>
                //       </li>
                //       <li>
                //         {" "}
                //         <NavLink
                //           style={{ textDecoration: "none" }}
                //           className='navbar-item'
                //           activeClassName='active'
                //           to={{
                //             pathname: "/AboutUs",
                //             state: this.state.aboutUs,
                //           }}
                //           aria-current='false'
                //           exact
                //           activeStyle={{
                //             background: "#0080ff",
                //             color: "#ffffff",
                //             borderRadius: "3px",
                //           }}
                //         >
                //           About Us
                //         </NavLink>
                //       </li>
                //       <li>
                //         {" "}
                //         <NavLink
                //           style={{ textDecoration: "none" }}
                //           className='navbar-item'
                //           activeClassName='active'
                //           to={{
                //             pathname: "/Terms",
                //             state: this.state.FAQ,
                //           }}
                //           exact
                //           activeStyle={{
                //             background: "#0080ff",
                //             color: "#ffffff",
                //             borderRadius: "3px",
                //           }}
                //         >
                //           Terms
                //         </NavLink>
                //       </li>
                //       <li>
                //         {" "}
                //         <NavLink
                //           style={{ textDecoration: "none" }}
                //           className='navbar-item'
                //           activeClassName='active'
                //           to='/ContactUs'
                //           exact
                //           activeStyle={{
                //             background: "#0080ff",
                //             color: "#ffffff",
                //             borderRadius: "3px",
                //           }}
                //         >
                //           Contact Us
                //         </NavLink>
                //       </li> */}
                //       {this.props.isLogin && (
                //         <li className='dropdown' style={{cursor:"pointer"}}>
                //           <a className='dropdown-toggle' data-toggle='dropdown'>
                //             My Dryclean <i className='fa fa-caret-down' aria-hidden='true'></i>
                //           </a>
                //           <ul className=''>
                //             <li>
                //               <a
                //                 ui-sref='orders'
                //                 onClick={() => {
                //                   this.props.history.push("/CustomerOrders");
                //                 }}
                //               >
                //                 <i className='fa fa-list' aria-hidden='true'></i>
                //                 Order History
                //               </a>
                //             </li>
                //           </ul>
                //         </li>
                //       )}
                //     </ul>
                //   </div>
                // </div>
                /* {this.props.navBar && (
                <div className="">
                  {" "}
                  <NavLink
                    className=""
                    activeClassName="active"
                    exact={true}
                    to="/"
                    onlyActiveOnIndex
                    aria-current="true"
                    activeStyle={{
                      background: "#0080ff",
                      color: "#ffffff",
                      borderRadius: "3px",
                    }}
                  >
                    Home
                  </NavLink>{" "}
                  <NavLink
                    className=""
                    activeClassName="active"
                    exact
                    to={{
                      pathname: "/Pricing",
                      state: this.state.wholeObject,
                    }}
                    activeStyle={{
                      background: "#0080ff",
                      color: "#ffffff",
                      borderRadius: "3px",
                    }}
                  >
                    Pricing
                  </NavLink>{" "}
                  <NavLink
                    className=""
                    activeClassName="active"
                    exact
                    to="/CoverageArea"
                    activeStyle={{
                      background: "#0080ff",
                      color: "#ffffff",
                      borderRadius: "3px",
                    }}
                  >
                    Coverage Area
                  </NavLink>{" "}
                  <NavLink
                    className=""
                    activeClassName="active"
                    to={{
                      pathname: "/AboutUs",
                      state: this.state.aboutUs,
                    }}
                    aria-current="false"
                    exact
                    activeStyle={{
                      background: "#0080ff",
                      color: "#ffffff",
                      borderRadius: "3px",
                    }}
                  >
                    About Us
                  </NavLink>{" "}
                  <NavLink
                    className=""
                    activeClassName="active"
                    to={{
                      pathname: "/Terms",
                      state: this.state.FAQ,
                    }}
                    exact
                    activeStyle={{
                      background: "#0080ff",
                      color: "#ffffff",
                      borderRadius: "3px",
                    }}
                  >
                    Terms
                  </NavLink>{" "}
                  <NavLink
                    className=""
                    activeClassName="active"
                    to="/ContactUs"
                    exact
                    activeStyle={{
                      background: "#0080ff",
                      color: "#ffffff",
                      borderRadius: "3px",
                    }}
                  >
                    Contact Us
                  </NavLink>
                  {this.props.isLogin && (
                    <li className="dropdown" style={{ listStyle: "none" }}>
                      <a className="dropdown-toggle" data-toggle="dropdown">
                        My Dryclean{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                      <ul className="">
                        <li>
                          <a
                            ui-sref="orders"
                            onClick={() => {
                              this.props.history.push("/CustomerOrders");
                            }}
                          >
                            <i className="fa fa-list" aria-hidden="true"></i>
                            Order History
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                </div>
                          )} */
              }
            </div>
          </div>
        </div>

        <SignIn history={this.props.history} />
        <SetReferFriend history={this.props.history} />
        <CheckService history={this.props.history} />
        <Signup />
        <Modal
          isOpen={this.props.forgetPasswordPopUp}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          id='ngdialog15'
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document'>
            <div className='modal-header'>
              <h4 className='modal-title' id='ngdialog15-aria-labelledby'>
                Provide your mobile number
              </h4>
            </div>

            <div className='modal-body' style={{ overflow: "auto" }}>
              <Formik
                initialValues={{
                  number: "",
                }}
                validationSchema={schema}
                validateOnBlur
                onSubmit={async values => {
                  let viewModel = {
                    Phone: `+92${values.number.substring(1)}`,
                    HasErrors: false,
                    ResultMessages: [
                      {
                        MessageType: "string",
                        Message: "string",
                      },
                    ],
                    Id: 0,
                    CreatedById: 0,
                    CreatedBy: "string",
                    LastUpdatedById: 0,
                    LastUpdatedBy: "string",
                    InsertDate: "2022-10-31T07:26:23.560Z",
                    ModifiedDate: "2022-10-31T07:26:23.560Z",
                    IsActive: true,
                  };
                  this.props.setLoader(true);
                  await this.props.postForgetPassword(viewModel, data => {
                    console.log({ data });
                    this.props.setForgetPasswordResponse(data);
                    this.props.setLoader(false);
                  });
                  // await this.props.fetchForgetPasswordDetails(values.email);
                  // console.log("forgetPasswordResponse : ", this.props.forgetPasswordResponse);
                  // if (this.props.forgetPasswordResponse.HasErrors === false) {
                  if (this.props?.forgetPasswordResponse?.ResultMessages[0]?.MessageType === "success") {
                    toast.success(this.props?.forgetPasswordResponse?.ResultMessages[0]?.Message);
                    this.setState({ isOtpSent: true });
                    this.props.setForgetPasswordPopUp(false);
                    this.props.setPostForgetPasswordPopup(true);
                  }
                  // }
                  // if (this.props.forgetPasswordResponse.HasErrors === true) {
                  if (this.props?.forgetPasswordResponse?.ResultMessages[0]?.MessageType === "danger") {
                    toast.error(this.props?.forgetPasswordResponse?.ResultMessages[0]?.Message);
                    this.setState({ isOtpSent: false });
                  }

                  // }
                }}
              >
                {props => {
                  const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

                  return (
                    <form
                      name='Forgot_form'
                      noValidate
                      autoComplete='off'
                      className='ng-pristine ng-valid-email ng-invalid ng-invalid-required ng-valid-pattern'
                      onSubmit={handleSubmit}
                    >
                      <input
                        type='text'
                        className='form-control dhobi_input'
                        name='number'
                        placeholder='Mobile No'
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength='11'
                      />
                      {errors.number && touched.number && <div className='errorMessage'>{errors.number}</div>}
                      <div className='form-group'>
                        <div className='col-sm-12'>
                          <button
                            type='submit'
                            className='btn btn-green btn-block'
                            disabled={errors.number || values.number === "" ? true : false}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div
              className='ngdialog-close'
              onClick={() => {
                this.props.setForgetPasswordPopUp(false);
                // this.props.setSignInPopUp(!this.props.signinPopUp);
              }}
            ></div>
          </div>
        </Modal>

        <Modal
          isOpen={this.props.postForgetPasswordModal}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          id='ngdialog15'
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document'>
            <div className='modal-header'>
              <h4 className='modal-title' id='ngdialog15-aria-labelledby'>
                Reset Password
              </h4>
            </div>

            <div className='modal-body' style={{ overflow: "auto" }}>
              <Formik
                initialValues={{
                  otp: "",
                  password: "",
                  cpassword: "",
                }}
                validationSchema={resetPasswordSchema}
                validateOnBlur
                validate={this.validate}
                onSubmit={async values => {
                  console.log(values);
                  let viewModel = {
                    Isvalid: true,
                    Password: `${values.password}`,
                    Forgotkey: `${values.otp}`,
                    HasErrors: true,
                    ResultMessages: [
                      {
                        MessageType: "string",
                        Message: "string",
                      },
                    ],
                    Id: 0,
                    CreatedById: 0,
                    CreatedBy: "string",
                    LastUpdatedById: 0,
                    LastUpdatedBy: "string",
                    InsertDate: "2022-11-01T11:26:22.779Z",
                    ModifiedDate: "2022-11-01T11:26:22.779Z",
                    IsActive: true,
                  };
                  this.props.setLoader(true);
                  await this.props.postResetPassword(viewModel, data => {
                    console.log({ data });
                  });
                  this.props.setPostForgetPasswordPopup(false);
                  this.props.setLoader(false);
                  // await this.props.fetchForgetPasswordDetails(values.email);
                  // console.log("forgetPasswordResponse : ", this.props.forgetPasswordResponse);
                  // if (this.props.forgetPasswordResponse.HasErrors === false) {
                  //   if (this.props.forgetPasswordResponse.ResultMessages[0].MessageType === "success")
                  //     toast.success(this.props.forgetPasswordResponse.ResultMessages[0].Message);
                  //   this.setState({isOtpSent: true});
                  //   this.props.setForgetPasswordPopUp(false);
                  //   this.props.setSignInPopUp(true);
                  // }
                  // if (this.props.forgetPasswordResponse.HasErrors === true) {
                  //   if (this.props.forgetPasswordResponse.ResultMessages[0].MessageType === "danger")
                  //     toast.error(this.props.forgetPasswordResponse.ResultMessages[0].Message);
                  //     this.setState({isOtpSent: false});
                  // }
                }}
              >
                {props => {
                  const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

                  return (
                    <form
                      name='Forgot_form'
                      noValidate
                      autoComplete='off'
                      className='ng-pristine ng-valid-email ng-invalid ng-invalid-required ng-valid-pattern'
                      onSubmit={handleSubmit}
                    >
                      <input
                        type='text'
                        className='form-control dhobi_input'
                        name='otp'
                        placeholder='OTP Code'
                        value={values.otp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength='4'
                      />
                      {errors.otp && touched.otp && <div className='errorMessage'>{errors.otp}</div>}
                      <input
                        type='password'
                        name='password'
                        placeholder='Password'
                        className='form-control'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        required
                      />
                      {errors.password && touched.password && <div className='errorMessage'>{errors.password}</div>}
                      <input
                        type='password'
                        name='cpassword'
                        placeholder='Confirm Password'
                        className='form-control'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        required
                      />
                      {errors.cpassword && touched.cpassword && <div className='errorMessage'>{errors.cpassword}</div>}
                      <div className='form-group'>
                        <div className='col-sm-12'>
                          <button
                            type='submit'
                            className='btn btn-green btn-block'
                            disabled={errors.otp || values.otp === "" ? true : errors.password || values.password === "" ? true : errors.cpassword || values.cpassword === "" ? true : false}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div
              className='ngdialog-close'
              onClick={() => {
                this.props.setPostForgetPasswordPopup(false);
                // this.props.setSignInPopUp(!this.props.signinPopUp);
              }}
            ></div>
          </div>
        </Modal>

        <Modal
          isOpen={this.props.otpPopup}
          contentLabel='Example Modal'
          className='ngdialog ngdialog-theme-default model-login ng-scope'
          id='ngdialog15'
          ariaHideApp={false}
        >
          <div className='ngdialog-overlay'></div>
          <div className='ngdialog-content' role='document'>
            <div className='modal-header'>
              <h4 className='modal-title' id='ngdialog15-aria-labelledby'>
                Provide your mobile number
              </h4>
            </div>

            <div className='modal-body' style={{ overflow: "auto" }}>
              <Formik
                initialValues={{
                  number: "",
                }}
                validationSchema={schema}
                validateOnBlur
                onSubmit={async values => {
                  //   console.log(values.email);
                  //   let viewModel = {
                  //     Phone: `+92${values.number}`,
                  //     HasErrors: false,
                  //     ResultMessages: [
                  //       {
                  //         MessageType: "string",
                  //         Message: "string",
                  //       },
                  //     ],
                  //     Id: 0,
                  //     CreatedById: 0,
                  //     CreatedBy: "string",
                  //     LastUpdatedById: 0,
                  //     LastUpdatedBy: "string",
                  //     InsertDate: "2022-10-31T07:26:23.560Z",
                  //     ModifiedDate: "2022-10-31T07:26:23.560Z",
                  //     IsActive: true,
                  //   };
                  //   await this.props.postForgetPassword(viewModel, (data)=> {
                  //     console.log({data})
                  //   })
                  //   // await this.props.fetchForgetPasswordDetails(values.email);
                  //   // console.log("forgetPasswordResponse : ", this.props.forgetPasswordResponse);
                  //   if (this.props.forgetPasswordResponse.HasErrors === false) {
                  //     if (this.props.forgetPasswordResponse.ResultMessages[0].MessageType === "success")
                  //       toast.success(this.props.forgetPasswordResponse.ResultMessages[0].Message);
                  //     this.setState({isOtpSent: true});
                  //     this.props.setForgetPasswordPopUp(false);
                  //     this.props.setSignInPopUp(true);
                  //   }
                  //   if (this.props.forgetPasswordResponse.HasErrors === true) {
                  //     if (this.props.forgetPasswordResponse.ResultMessages[0].MessageType === "danger")
                  //       toast.error(this.props.forgetPasswordResponse.ResultMessages[0].Message);
                  //       this.setState({isOtpSent: false});
                  //   }
                  //
                }}
              >
                {props => {
                  const { touched, errors, handleSubmit, values, handleChange, handleBlur } = props;

                  return (
                    <form
                      name='Forgot_form'
                      noValidate
                      autoComplete='off'
                      className='ng-pristine ng-valid-email ng-invalid ng-invalid-required ng-valid-pattern'
                      onSubmit={handleSubmit}
                    >
                      <input
                        type='text'
                        className='form-control dhobi_input'
                        name='number'
                        placeholder='Mobile No'
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength='11'
                      />
                      {errors.number && touched.number && <div className='errorMessage'>{errors.number}</div>}
                      <div className='form-group'>
                        <div className='col-sm-12'>
                          <button
                            type='submit'
                            className='btn btn-green btn-block'
                            disabled={errors.number || values.number === "" ? true : false}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div
              className='ngdialog-close'
              onClick={() => {
                this.props.setForgetPasswordPopUp(!this.props.forgetPasswordPopUp);
                this.props.setSignInPopUp(!this.props.signinPopUp);
              }}
            ></div>
          </div>
        </Modal>
        <SearchedAreas/>
      </>
    );
  }
}

const mapDispatchToProps = {
  setSignInPopUp,
  setLoginDetails,
  setForgetPasswordPopUp,
  setrefertofriend,
  setcheckservice,
  fetchForgetPasswordDetails,
  setLoginStatus,
  setLoader,
  setNavbar,
  userSignout,
  resetCustomerDetails,
  setSignupModal,
  postForgetPassword,
  setPostForgetPasswordPopup,
  setForgetPasswordResponse,
  postResetPassword,
};

const mapStateToProps = ({ Auth }) => {
  let {
    data,
    signinPopUp,
    isLogin,
    loginData,
    customerDetails,
    postForgetPasswordModal,
    forgetPasswordPopUp,
    forgetPasswordResponse,
    navBar,
    refertofriendPopup,
    checkservicePopup,
  } = Auth;
  //let  timer   = Auth.timer;
  return {
    data,
    refertofriendPopup,
    checkservicePopup,
    signinPopUp,
    isLogin,
    loginData,
    customerDetails,
    forgetPasswordPopUp,
    forgetPasswordResponse,
    navBar,
    postForgetPasswordModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
