import React, { Component } from "react";
import Modal from "react-modal";
import { Formik } from "formik";
import * as Yup from "yup";
// import { CardModel } from '../models/CardModels';
// import { CardTypeModel } from '../models/CardTypeModels';
// import { AddressModel } from '../models/AddressModels';
import { connect } from "react-redux";
import { setcheckservice, saveCheckService, listsearchedareaspopup, setServiceAreaPopup } from "../../store/action/Auth";
import SearchedAreas from "./SearchedAreas";
import { toast } from "react-toastify";

const schema = Yup.object().shape({
  society: Yup.string().required("Society is Required."),
  number: Yup.string()
    .required("Phone No is required.")
    .min(11, "Invalid Phone No.")
    .matches(/^[0-9]{1,11}$/, "Only numbers allowed.")
    .max(11, "Maximum 11 characters are allowed."),
});

export class CheckService extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log("in check service popup");
    return (
      <>
        <Modal
          isOpen={this.props.checkservicePopup}
          contentLabel="Example Modal"
          className="ngdialog ngdialog-theme-default model-login ng-scope"
          id="ngdialog15"
        >
          <div className="ngdialog-overlay"></div>
          <div className="ngdialog-content" role="document">
            <div className="modal-header">
              <h4 className="modal-title" id="ngdialog15-aria-labelledby">
                Check for Service
              </h4>
            </div>
            <div className="modal-body" style={{ overflow: "auto" }}>
              <Formik
                initialValues={{
                  society: "",
                  number: "",
                }}
                validationSchema={schema}
                validateOnBlur
                onSubmit={async (values) => {
                  console.log(values);
                  //  await this.props.fetchForgetPasswordDetails(values.email);
                  //   console.log("forgetPasswordResponse : ",this.props.forgetPasswordResponse);
                  //   const viewModel = {
                  //       TextSearch: values.society,
                  //       PhoneNumber: values.number,
                  //     };
                  const viewModel = {
                    societySearch: {
                      TextSearch: values.society,
                      PhoneNumber: values.number,
                    },
                    searchedAreas: [
                      {
                        CityId: 0,
                        Name: "string",
                        Abbreviation: "string",
                        ZipCode: "string",
                        Longitude: "string",
                        Latitude: "string",
                        Altitude: "string",
                        IsDefault: true,
                        HasErrors: true,
                        ResultMessages: [
                          {
                            MessageType: "string",
                            Message: "string",
                          },
                        ],
                        Id: 0,
                        CreatedById: 0,
                        CreatedBy: "string",
                        LastUpdatedById: 0,
                        LastUpdatedBy: "string",
                        InsertDate: "2022-10-19T11:08:31.093Z",
                        ModifiedDate: "2022-10-19T11:08:31.093Z",
                        IsActive: true,
                      },
                    ],
                    HasErrors: true,
                    ResultMessages: [
                      {
                        MessageType: "string",
                        Message: "string",
                      },
                    ],
                    Id: 0,
                    CreatedById: 0,
                    CreatedBy: "string",
                    LastUpdatedById: 0,
                    LastUpdatedBy: "string",
                    InsertDate: "2022-10-19T11:08:31.093Z",
                    ModifiedDate: "2022-10-19T11:08:31.093Z",
                    IsActive: true,
                  };
                  await this.props.saveCheckService(viewModel, data => {
                    if(data.length == 0) toast.info("Service is not currently available in your area we are working on it kindly try again later");
                    if(data.length > 0){
                        let alertString = "Available searched areas are\n▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬\n\n";
                        data.map((item, index) => {
                            alertString = alertString + item.Name +'\n'
                        });
                        // alert(alertString);()
                        this.props.setServiceAreaPopup({status: true, data: data});
                    } 
                  });
                }}
              >
                {(props) => {
                  const {
                    touched,
                    errors,
                    handleSubmit,
                    values,
                    handleChange,
                    handleBlur,
                  } = props;

                  return (
                    <form
                      name="Forgot_form"
                      noValidate
                      autoComplete="off"
                      className="ng-pristine ng-valid-email ng-invalid ng-invalid-required ng-valid-pattern"
                      onSubmit={handleSubmit}
                    >
                      
                      <div className="form-group field">
                        <input
                          type='text'
                          className='form-control dhobi_input'
                          name='number'
                          placeholder='Mobile No '
                          value={values.number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength='11'
                        />
                        {errors.number && touched.number && (
                        <div className="errorMessage">{errors.number}</div>
                      )}
                      </div>
                      
                      
                      <input
                        type="string"
                        className="form-control custom-form-control"
                        name="society"
                        placeholder="Area/Society"
                        value={values.society}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.society && touched.society && (
                        <div className="errorMessage">{errors.society}</div>
                      )}
                      <div className="form-group">
                        <div className="col-sm-12">
                          <button
                            // onClick={() => {
                            //   this.props.setcheckservice(false);
                            //   this.props.listsearchedareaspopup(true);
                            // }}
                            type="submit"
                            className="btn btn-green btn-block"
                            disabled={
                              errors.society || values.society === ""
                                ? true
                                : false
                            }
                          >
                            Check
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div
              className="ngdialog-close"
              onClick={() => {
                this.props.setcheckservice(false);
              }}
            ></div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = { setcheckservice, saveCheckService, setServiceAreaPopup };

const mapStateToProps = ({ Auth }) => {
  let { forgetPasswordResponse, checkservicePopup, saveCheckService } = Auth;
  return { forgetPasswordResponse, checkservicePopup, saveCheckService };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckService);
